var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('stats',{attrs:{"projects":_vm.projects,"status":_vm.status,"user-deliverables":_vm.userDeliverables}}),(_vm.projects && _vm.projects.length)?[_c('div',{staticClass:"header"},[_c('div',{staticClass:"title"},[_vm._v(" Project History "),_c('span',[_vm._v("("+_vm._s(_vm.projects.length)+")")])]),_c('div',{staticClass:"subtitle"},[_vm._v(" Projects Completed: "),_c('span',[_vm._v(_vm._s(_vm.completedProjects.length))])])]),_c('base-table',{staticClass:"px-12 pb-10",attrs:{"columns":[
                {
                    name: 'name',
                    label: 'Project Name',
                    type: 'link',                        
                    value: function (element) {
                        return ((element.name) + " - " + (element.brand))
                    },
                    options: {
                        cellClasses: 'max-w-screen-sm truncate',
                        cellStyles: 'max-width: 500px;',
                    },
                },
                {
                    name: 'start',
                    label: 'Start Date',
                    type: 'date',                                    
                    value:  function (element) {
                        return element.starts_at;
                    }
                },
                {
                    name: 'earned',
                    label: 'Earned',
                    type: 'price',
                    hidden: !_vm.isAdminOrHigher && !_vm.isProjectManager,
                    value: function (element) {
                        return element.project_users.reduce(function (a, c) { return a += (this$1.userDeliverables.filter(function (ut) {return ut.project_user_id === c.id}).reduce(function (t, curr) { return t += curr.budget; }, 0)); }, 0);
                    }                
                },
                {
                    name: 'status',
                    label: 'Status',
                    type: 'string',
                    style: 'pill',
                    disabled: true,
                    searchBy: _vm.statusSearchBy,
                    value:  function (element) {
                        return element.status && element.status[0] !== undefined ? element.status[0].label : '-';
                    },
                    options: {
                        stopPropagation: true,
                        cellStyles: 'max-width: 224px; width: 224px; padding-left: 12px; padding-right: 0px;',
                        thStyles: 'padding-left: 12px; padding-left: 12px;'
                    }
                }
            ],"data":_vm.projects,"onRowClick":function (element) { return false && !_vm.isGuest && !_vm.isClient ? _vm.$router.push(("/projects/" + (element.slug))) : null; },"loading":_vm.loading,"hideDeliverableActions":"","disableProjectRedirect":"","disableDeliverableRedirect":""}})]:_c('div',{staticClass:"px-12 py-10"},[_c('section-empty-state',{attrs:{"icon":"folder","message":"No projects assigned"}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <base-modal 
        :title="computedTitle" 
        :visible="visible"
        @close="$emit('close')"
        no-padding
        styles="overflow-y: auto; max-height: 80vh; max-width: 80vw; width: 80vw;"
        headerStyles="position: sticky; z-index: 1; top: 0; left: 0; right: 0; background-color: #f8f7f1;"
    >
        <!-- empty state in case we dont have the user or the target network -->
        <div class="container" v-if="!user || !targetNetwork || !handle">
            <div class="content">
                <div v-if="!user">Unable to get creator</div>
                <div v-else-if="!targetNetwork">Network not set for current creator, make sure to select a network for this creator on this report.</div>
                <div v-else-if="!handle">Unable to get handle for this creator.</div>
                <div v-else>Unknown error.</div>
            </div>
            <div class="footer">
                <base-button label="Close" @action="$emit('close')" type="secondary" bold size="md" />
            </div>
        </div>
        <!-- normal content -->
        <div v-else class="container">
            <div class="content">
                <!-- show target network and handle -->
                <div class="profile-wrapper">
                    <div class="profile" @click="viewOnPlatform()">
                        <base-icon :name="targetNetworkName" />
                        <div class="handle">{{handle}}</div>
                    </div>
                </div>
                <div class="post-list">
                    <!-- loading state -->
                    <base-icon 
                        v-if="loading"
                        name="loader" 
                    />
                    <template v-else>
                        <!-- empty state -->
                        <div class="empty-state" v-if="!featuredPosts.length">There are no featured posts for {{user.first_name}} {{user.last_name}}</div>
                        <div class="post-item" v-for="(post, index) in featuredPosts" :key="index">
                            <!-- button to remove -->
                            <div class="w-full flex justify-center mb-4">
                                <base-button 
                                    label="Remove featured post"
                                    type="main"
                                    size="xl"
                                    style="background-color: black; color: white;"
                                    bold
                                    @action="removeFeaturedPostIntent(post)"
                                />
                            </div>
                            <!-- post preview -->
                            <report-post-card 
                                is-preview 
                                class="post-card mb-4"
                                :post="post"
                            />
                        </div>
                    </template>
                </div>
            </div>
            
            <div class="footer">
                <base-button 
                    label="Close"  
                    @action="$emit('close')"
                    type="secondary" 
                    bold
                    size="md" 
                />
                <base-button
                    label="Add featured post" 
                    bold
                    @action="addFeaturedPostModalVisible = true"  
                    size="lg" 
                />
            </div>

            <add-featured-post-modal
                :visible="addFeaturedPostModalVisible"
                :user="user"
                :handle="handle"
                @done="$emit('reload'); $emit('close');"
                @close="addFeaturedPostModalVisible = false"
            />
           
        </div>
    </base-modal>
</template>

<script>
    import ReportPostCard from '../../components/ReportPostCard';
    import { getNetwork } from '../../lib/network';
    import { isValidArray, notifyCatchError } from '../../common';
    import AddFeaturedPostModal from './AddFeaturedPostModal.vue';
    export default {
        name: 'FeaturedPostsModal',
        props: ['visible', 'user'],
        components: {
            ReportPostCard,
            AddFeaturedPostModal
        },
        data() {
            return {
                loading: false,
                addFeaturedPostModalVisible: false
            }
        },
        methods: {
            removeFeaturedPostIntent(post){
                this.$swal.fire({
                    title: 'Are you sure you want to remove this featured post ?',
                    icon: 'warning',
                    iconColor: '#0E092C',
                    showCancelButton: true,
                    confirmButtonText: 'Remove',
                    reverseButtons: true,
                }).then((result) => {
                    if(result.isConfirmed){
                        this.removeFeaturedPost(post)
                    }
                })
            },
            removeFeaturedPost(post){
                if(post && post.report_user_post_id){
                    this.loading = true;
                    this.$http.delete(`/report-user-post/${post.report_user_post_id}`).then(({ data }) => {
                        if(data) {
                            this.$notify({ title: 'Success', text: 'Featured post removed successfully', type: 'success' });
                            this.$emit('close');    
                            this.$emit('reload');
                        }
                    }).catch(err => {
                        notifyCatchError(err, this.$notify);
                    }).finally(() => {
                        this.loading = false;
                    });
                }
            },
            getProfileItem(network_id){
                if(this.user.audience_data){
                    const audienceDataIndex = this.user.audience_data.findIndex(element => element.network_id == network_id);
                    if(audienceDataIndex !== -1){
                        if(this.user.audience_data[audienceDataIndex].payload && this.user.audience_data[audienceDataIndex].payload.user_profile){
                            return this.user.audience_data[audienceDataIndex].payload.user_profile;
                        }
                    }
                } 
                return null;
            },
            viewOnPlatform(){
                if(this.targetNetwork == 2){
                    window.open(`https://instagram.com/${this.handle}`, '_blank')
                }
                if(this.targetNetwork == 14){
                    window.open(`https://tiktok.com/${this.handle}`, '_blank')
                }
            }
        },
        computed: {

            featuredPosts(){
                if(this.user && this.user.report_user && this.user.report_user.featured_posts){
                    return this.user.report_user.featured_posts.map(post => {
                        let is_video = post.type == 'video';
                        let video_duration = '00:00:00';                        
                        return {
                            post_identifier: post.post_id,
                            link: post.link,
                            date: post.created,
                            network: getNetwork(post.network_id),
                            report_user_post_id: post.report_user_post_id,
                            content_type_id: 1,
                            type: { id: 1, name: 'post' },
                            is_video: is_video,
                            url: is_video ? post.video : post.image,
                            caption: post.text,
                            description: post.text,
                            display_url: post.thumbnail,
                            likes: post.stat.likes,
                            dislikes: post.stat.dislikes,
                            comments: post.stat.comments,
                            views: post.stat.views,
                            shares: post.stat.shares,
                            media: [
                                { mimetype: is_video ? 'video/mp4' : 'image/png', video_duration, url: is_video ? post.video : post.image, poster: post.thumbnail }
                            ],
                            profile_item: this.getProfileItem(post.network_id)
                        }
                    });
                }
                return [];
            },

            computedTitle(){
                return this.user ? `${this.user.first_name} ${this.user.last_name}'s featured posts` : ``;
            },

            handle(){
                if(this.user && this.targetNetwork){
                    const found = this.user.social_accounts.find(element => {
                        return element.network_id == this.targetNetwork
                    });
                    if(found){
                        return found.handle; 
                    }
                }
                return null;
            },

            targetNetworkName(){
                if(this.targetNetwork){
                    return getNetwork(this.targetNetwork, 'name');
                }
                return '';
            },

            // gets the first network for this report_user
            targetNetwork(){
                return this.user.report_user && isValidArray(this.user.report_user.networks) ? this.user.report_user.networks[0] : null;
            }
        }
    }
</script>
<style lang="scss" scoped>
    .container{
        @apply flex flex-col justify-between;
        min-height: 260px;

        .content{
            @apply py-6 px-14;
            .profile-wrapper{
                @apply w-full pb-2 mb-2 border-b border-gray-300;  
                @apply flex;
                .profile{
                    @apply px-4 py-2 rounded-lg;
                    @apply flex gap-x-2 items-center;
                    @apply cursor-pointer transition-colors duration-200;
                    &:hover{
                        @apply bg-gray-300;
                    }
                    .handle{
                        @apply font-bold;
                    }   
                }
            }
            .post-list{
                @apply w-full;
                @apply flex justify-center flex-wrap gap-12;
                .post-item{
                    //
                    .replaced-post-button-container{
                        @apply w-full bg-black text-white flex justify-center items-center py-2;
                    }
                }
            }
        }

        > .footer{
            @apply sticky bottom-0 left-0 right-0 bg-tan-m py-6 px-14 flex justify-between;
            z-index: 80;
        }
    }
</style>

<template>
    <div class="header-container">

        <div class="top">
            
            <!-- skeleton -->
            <template v-if="loading">
                <div class="left">
                    <skeleton type="circle" :width="32"/>
                    <div class="info">
                        <skeleton :height="8" :width="36"/>
                        <skeleton :height="4" :width="48"/>
                        <skeleton :height="8" :width="28" :fullRounded="true"/>
                    </div>
                </div>
                <skeleton :width="8" type="circle"/>
            </template>

            <template v-else>

                <template v-if="!projectUser.user || !projectUser.user.id">
                    <div class="left">
                        <div class="info">
                            <div class="title">User not found</div>
                            <base-button
                                @action="$router.push(`/creator/${$route.params.userId}/edit`);"
                                label="View more details"
                                class="mt-4"
                                size="lg"
                                bold
                            />
                        </div>
                    </div>
                </template>
                
                <template v-else>
                    
                    <!-- left -->
                    <div class="left">
                        <avatar  
                            v-if="projectUser.user && projectUser.user.id"
                            :user="projectUser.user"
                            size="xl2"
                        />
                        <div class="info">
                            <div class="title">{{displayName}}</div>
                            <div class="subtitle">{{displayEmail ? displayEmail : ""}}{{ displayEmail && userLocation ? " - " : "" }}{{userLocation ? userLocation : ""}}</div>
                            <star-rating
                                v-if="user.rating" 
                                v-model="user.rating" 
                                :read-only="true" 
                                :increment="0.5"
                                :star-size="18"
                                :show-rating="false"
                                class="cursor-pointer"
                                v-tooltip="{ content: `${user.rating}/5`, classes: 'bg-black text-white rounded py-1 px-1 text-xs', delay: { show: 100, hide: 50 } }"
                            />
                            <tags class="mt-2" theme="dark" :tags="projectUser.user.user_tags" :limit="-1" />                    
                        </div>
                    </div>

                    <!-- right -->
                    <options-dropdown
                        v-if="isProjectManager || isAdminOrHigher"
                        theme="dark"                 
                        :options="!noProject ? [
                            {label: 'Edit', icon: 'pencil-alt', event:'edit'},
                            {label: 'Remove from project', icon: 'trash', event:'removeFromProject'},
                            {label: 'Boosted posts', icon: 'trash', event:'showBoostedPostsModal'},                       
                        ]:
                        [
                            {label: 'Edit', icon: 'pencil-alt', event:'edit'}, 
                            {label: 'Boosted posts', icon: 'trash', event:'showBoostedPostsModal'},                       
                        ]"
                        @edit="$router.push(`${$route.path}/edit`)"                    
                        @removeFromProject="removeFromProject"
                        @showBoostedPostsModal="$emit('showBoostedPostsModal')"
                    />

                </template>

            </template>
                 
        </div>               
        <!-- tabs -->
        <div class="tabs-wrapper">
            <tabs 
                theme="dark" 
                :tabs="loading || !projectUser.user.id ? [] :
                !project.id ? ['Deliverable', 'Audience', 'Info', 'Ratings'] :
                !isAdminOrHigher ? ['Deliverable', 'Info', 'Notes', 'Ratings'] : 
                ['Deliverable', 'Documents', 'Notes', 'Audience', 'Info', 'Ratings']" 
                :value="value"
                @input="val => {
                    $emit('input', val); 
                    $forceUpdate();
                }"
            />
        </div>
        <networks
            v-if="accounts && accounts != {}"
            :wrapperClass="'flex gap-4 justify-end absolute right-0 bottom-0 pr-12 pb-4'" 
            :accounts="accounts"
            :user="user"
        />
    </div>   
</template>

<script>
import { mapGetters } from 'vuex';
import Tags from '../../components/Tags.vue';
import Networks from '../../components/Networks.vue';
import StarRating from 'vue-star-rating';
export default {
    props: ['loading', 'projectUser', 'user', 'noProject', 'project', 'value'],
    components: {
        Tags,
        Networks,
        StarRating
    },
    data(){
        return {
            accounts: []
        }
    },
    watch: {
        user(){
            this.getSocialStatus()
        }
    },
    computed: {
        ...mapGetters(['isProjectManager', 'isAdminOrHigher']),
        displayName() {
            let useCompanyName = this.user.user_companies && this.user.user_companies[0] && (this.user.user_companies[0].first_name && this.user.user_companies[0].last_name)
            return useCompanyName ? `${this.user.user_companies[0].first_name} ${this.user.user_companies[0].last_name}` : `${this.user.first_name} ${this.user.last_name}`
        },
                
        displayEmail() {
            return this.user.email && this.user.email.includes("markerly-generated") ? '' : this.user.email;
        },

        userLocation() {
            let geo = null;
            if (this.projectUser.user && this.projectUser.user.audience_data && this.projectUser.user.audience_data.length && this.projectUser.user.audience_data[0].location) {
                geo = this.projectUser.user.audience_data[0].location;
            } else {
                return null;
            }

            let out = '';            
            
            if (geo.country) {
                out += `${geo.country.name}`;
            }
            if (geo.state) {
                out = `${geo.state.name}, ${out}`;
            }
            if (geo.city) {
                out = `${geo.city.name}, ${out}`;
            }

            return out === '' ? null : out;
        },
    },
    methods: {
        getSocialStatus(){
            this.$http.get(`/facebook/social-status/${this.user.id}`).then(({data}) => {
                if(data){
                    this.accounts = data
                }
            }).catch(err => {
                console.log('getSocialStatus error', err)
            })
        },
        async removeFromProject() {
            this.$swal.fire({
                title: `Are you sure you want to remove this influencer?`,
                text: '',
                icon: 'warning',
                iconColor: '#0E092C',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                reverseButtons: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.processRemove();
                }
            });
        },
        async processRemove() {
            try {
                let route = `/api/projects/${this.project.id}`;
                let params = { userId: this.user.id };

                const {data} = await this.$http.post(`${route}/remove-user`, params);
                if (data) {
                    this.$store.dispatch('restorer/set', {
                        data: params,
                        message: 'Creator removed from project successfully',
                        success: 'Creator restored successfully',
                        failed: 'Creator cannot be restored',
                        route: `${route}/restore-user`,
                        action: data.show_undo,
                        forceFn: true,
                        fn: (time = 6100) => {
                            setTimeout(() => {
                                this.$router.push(`/projects/${this.project.slug}`);
                            }, time);
                        }
                    });
                }
            } catch(e) {
                this.$notify({ title: 'Error', text: 'Something went wrong', type: 'error' });
                console.log(e);
            }
        },
    }
}
</script>
<style lang="scss" scoped>
    .header-container{
        @apply px-12 py-10 pb-0 bg-gradient-to-b from-gradient-m-main-start to-gradient-m-main-end relative;
        .top{
            @apply flex justify-between items-start py-10;
            .left{
                @apply flex gap-8 items-center;
                .info{
                    @apply flex flex-col items-start justify-start gap-2;
                    .title{
                        @apply text-h2 text-white font-bold;
                    }
                    .subtitle{
                        @apply text-ps text-purple-m-subtitle;
                    }
                }
            }
        }
        .tabs-wrapper{
            @apply flex justify-between items-center;
        }
    }
</style>
<template>
    <div class="user-importable-status">
        
        <div class="wrapper">

            <base-icon
                v-if="!isImportable"
                name="exclamation-2"
                class="cursor-pointer text-yellow-300"
                v-tooltip="{ content: 'This user requires your attention, click to view details', classes: 'v-tooltip-black ml-60', delay: { show: 100, hide: 50 } }"
                @action="hasActions ? modalVisible = true : null"
            />
            <template v-else>
                
                <base-icon
                    name="check-circle"
                    class="cursor-pointer text-green-500"
                    v-tooltip="{ 
                        content: `The user is importable${hasActions ? `, but there are details that require your attention, click to view` : ``}`, 
                        classes: `v-tooltip-black${hasActions ? ` ml-72` : `` }`, 
                        delay: { show: 100, hide: 50 } 
                    }"
                    @action="hasActions ? modalVisible = true : null"
                />
                
                <div class="actions-container" v-if="hasActions">
                    <base-icon
                        name="exclamation-circle"
                        class="text-yellow-300"
                        :size="4"
                    />
                </div>

            </template>
        
        </div>

        <base-table-column-user-importable-status-modal
            :visible="modalVisible"
            :value="value"
            :creator="creator"
            @update:user="user => $emit('update:user', user)"
            @close="modalVisible = false"
        />

    </div>
</template>
<script>
import { isValidArray } from '../common';
import BaseTableColumnUserImportableStatusModal from './BaseTableColumnUserImportableStatusModal.vue';
export default {
  components: { BaseTableColumnUserImportableStatusModal },
    props: {
        value: {
            type: Object,
            required: true
        },
        creator: {
            type: Object
        }
    },
    data(){
        return {
            modalVisible: false
        }
    },
    computed: {
        isImportable(){
            return this.value.is_importable
        },
        hasActions(){
            return this.actions.length > 0; 
        },
        actions(){
            if(this.value && isValidArray(this.value.actions)){
                return this.value.actions;
            }
            return [];
        }
    }
}
</script>
<style lang="scss" scoped>
    .user-importable-status{
        @apply flex;
        > .wrapper{
            @apply relative;
            > .actions-container{
                @apply absolute;
                @apply flex justify-center items-center;
                top: -10px;
                right: -10px;
            }
        }
    }
</style>

<template>
    <base-modal
        title="Featured posts"
        :visible="visible"
        styles="max-height: 90vh; max-width: 1200px; width: 1200px; overflow-y: auto;"
        no-padding
        @close="$emit('close')"
    >
        <div class="container">
            
            <div class="content">
                
                <!-- profile section -->
                <div class="profile-section">
                    <base-icon name="loader" v-if="loadingProfile" />
                    <template v-else>
                        <div class="profile" v-if="isValidObject(profile)">
                            <img class="profile-picture" v-if="profile.profile_picture_url || profile.picture" :src="profile.profile_picture_url || profile.picture" />
                            <div class="names">
                                <div class="username" v-if="profile.username">{{profile.username}}</div>
                                <div class="name" v-if="profile.name">{{profile.name}}</div> 
                                <div class="followers-count" v-if="profile.followers_count || profile.followers">Followers: {{(profile.followers_count || profile.followers) | numeral('0.0a')}}</div>
                            </div>
                        </div>
                    </template>
                </div>
                
                <!-- These are the already selected elements, can be deleted from this list -->
                <div class="selected-posts">
                    <!-- empty state -->
                    <div class="title" v-if="!isValidArray(val)">
                        There are no selected posts yet
                    </div>
                    <template v-else>
                        <div class="title">Selected Posts</div>                        
                        <div class="list">
                            <base-table-column-featured-posts-modal-item 
                                v-for="(post, index) in val" 
                                :value="post"
                                :profile="profile"
                                :readonly="readonly"
                                :loading="loadingSelectedPosts"
                                :key="`selected-post-${index}`"
                                :is-selected="isSelected(post)"
                                @toggle-post="togglePost(post)"
                            />
                        </div>
                    </template>
                </div>
                
                <!-- this functionality is for instagram & tiktok right now -->
                <template v-if="!readonly && (network.name == 'instagram' || network.name == 'tiktok')">
                    
                    <!-- tabs -->
                    <div class="tabs">
                        <div 
                            v-for="(tab, index) in tabOptions" 
                            :class="['tab-item', { 'selected': tab.id == selectedTab.id }]"
                            :key="index"
                            @click="selectedTab = tab"
                        >
                            {{ tab.label }}
                        </div>
                    </div>
                    
                    <!-- search post by url -->
                    <div class="search" v-if="selectedTab.id == 1">
                        <div class="title">Search {{this.handle}}'s {{network.display_name}} posts by URL</div>
                        <div class="searchbar-container">
                            <FormulateInput
                                v-model="searchTerm"
                                type="text"
                                :placeholder="network.name == 'instagram' ? 'https://www.instagram.com/p/C9h4zVuvLRT/' : network.name == 'tiktok' ? 'https://www.tiktok.com/@username/video/728405678997337586' : ''"
                                class="flex-grow flex-shrink"
                                :element-class="['flex items-center gap-x-1']"
                            />
                            <base-button 
                                label="Search" 
                                class="flex-grow-0 flex-shrink-0"
                                :disabled="!searchTermValid"
                                icon="search"
                                @action="getSinglePost()"
                            />
                        </div>
                        <base-icon 
                            v-if="searching" 
                            name="loader"
                        />
                        <template v-else>
                            <div class="empty-state" v-if="!isValidObject(searchResult)">
                                No posts found
                            </div>
                            <div v-else class="list">
                                <base-table-column-featured-posts-modal-item
                                    :value="searchResult"
                                    :profile="profile"
                                    :is-selected="isSelected(searchResult)"
                                    @toggle-post="togglePost(searchResult)"
                                />
                            </div>
                        </template>
                    </div>
                    
                    <!-- show creators top posts -->
                    <div class="top-posts" v-if="selectedTab.id == 2">
                        <div class="title">Select featured posts from {{this.handle}}'s top posts.</div>
                        <base-button
                            v-if="!isValidArray(topPosts)"
                            :label="`Load ${this.handle}'s top posts`"
                            class="w-max-content"
                            bold
                            @action="getTopPosts()"
                        />
                        <base-icon 
                            v-if="loadingTopPosts" 
                            name="loader"
                        />
                        <template v-else>
                            <div class="empty-state" v-if="!isValidArray(topPosts)">
                                No posts found
                            </div>
                            <div v-else class="list">
                                <base-table-column-featured-posts-modal-item 
                                    v-for="(post, index) in topPosts" 
                                    :value="post"
                                    :profile="profile"
                                    :key="`top-post-${index}`"
                                    :is-selected="isSelected(post)"
                                    @toggle-post="togglePost(post)"
                                />
                            </div>
                        </template>
                    </div>
                </template>

            </div>

            <div class="footer">
                <base-button
                    label="Close"
                    type="secondary"
                    @action="$emit('close')"
                />
                <base-button
                    label="Done"
                    bold
                    @action="$emit('done');$emit('close')"
                />
            </div>

        </div>
    </base-modal>
</template>

<script>
import { isValidArray, isValidObject, isValidString, notifyCatchError } from '../common';
import { getNetworkFromSocialPostUrl, getParamsFromSocialPostUrl, removeUnnecessaryQueryParams } from '../lib/network';
import { validateUrl, validString } from '../lib/strings';
import BaseTableColumnFeaturedPostsModalItem from './BaseTableColumnFeaturedPostsModalItem.vue';
export default {
    props: {
        visible: {
            type: Boolean
        },
        value: {
            type: Array
        },
        handle: {
            type: String
        },
        network: {
            type: Object
        },
        readonly: {
            type: Boolean
        }
    },
    components: {
        BaseTableColumnFeaturedPostsModalItem
    },
    data(){
        return {
            val: this.value,
            selectedTab: { id: 1, label: 'Search', value: 1 },

            // profile, to calculate engagements
            profile: null,
            loadingProfile: false,

            // selected featured posts
            loadingSelectedPosts: false,

            // search
            searchTerm: '',
            searching: false,
            searchResult: {},

            // top posts
            loadingTopPosts: false,
            topPosts: [],

            // imported
            isValidArray,
            isValidObject
        }
    },
    watch: {
        visible(val){
            if(val == true){
                if(isValidString(this.handle) && isValidObject(this.network) && this.profile == null){
                    this.getProfile();
                }
                if(isValidArray(this.val)){
                    const postsFound = this.val.filter(element => element.found);
                    if(!postsFound.length){
                        this.getSelectedPosts();
                    }
                }
            }
        }
    },
    computed: {
        searchTermValid(){
            return validString(this.searchTerm) && validateUrl(this.searchTerm) && isValidObject(getParamsFromSocialPostUrl(this.searchTerm))
        },
        tabOptions(){
            return [
                { id: 1, label: 'Search', value: 1 },
                ...(this.network.name == 'instagram' ? [{ id: 2, label: 'Top posts', value: 2 }] : [])
            ];
        }
    },
    methods: {
        commit(){
            this.$emit('update:value', this.val);
        },
        getProfile(){
            this.loadingProfile = true;
            this.$http.get(`/api/social-api-service/user-lookup?handle=${this.handle}&platform=${this.network.name}`).then(({ data }) => {
                if(data && data.results){
                    this.profile = data.results;
                }
            }).catch(err => {
                notifyCatchError(err);
            }).finally(() => {
                this.loadingProfile = false;
            });
        },
        getSelectedPosts(){
            if(isValidArray(this.value)){
                if(this.network.name == 'instagram'){
                    this.loadingSelectedPosts = true;
                    this.$http.post('/social-api-service/get/posts/preview', {
                        handle: this.handle,
                        urls: this.value.map(element => element.url)
                    }).then(({ data }) => {
                        for (const post of data) {
                            if(post.found && post.preview){
                                const found = this.val.find(element => element.url == post.url);
                                if(found){
                                    const index = this.val.findIndex(element => element.url == post.url);
                                    if(index !== -1){
                                        this.val[index].found = true;
                                        this.val[index].preview = post.preview;
                                    }
                                }
                            }
                        }
                        this.commit();
                    }).catch(err => {
                        notifyCatchError(err, this.$notify);
                    }).finally(() => {
                        this.loadingSelectedPosts = false;
                    });
                }
                if(this.network.name == 'tiktok'){
                    this.val = this.val.map(element => {
                        const params = getParamsFromSocialPostUrl(element.url);
                        if(params.postId){
                            element.found = true;
                            element.preview = {
                                id: params.postId,
                                use_embed: true,
                                url: element.url
                            }
                        }
                        return element;
                    });
                    this.commit();
                }
            }
        },
        getSinglePost(){
            this.searching = true;
            let term = this.searchTerm;
            term = removeUnnecessaryQueryParams(term);
            if(this.network.name == 'instagram'){
                this.$http.post('/social-api-service/get/posts/preview', {
                    handle: this.handle,
                    urls: [term]
                }).then(({ data }) => {
                    if(isValidArray(data) && isValidObject(data[0])){
                        const post = data[0];
                        const network = getNetworkFromSocialPostUrl(post.url);
                        if(isValidObject(network)){ 
                            // any change in this object should also be changed on getTopPosts method and on Step4CreatorList.vue file
                            this.searchResult = { 
                                url: removeUnnecessaryQueryParams(post.url), 
                                network_id: network.id, 
                                network_name: network.name, 
                                network_display_name: network.display_name,
                                found: true,
                                preview: post.preview,
                            };
                        }
                    }
                }).catch(err => {
                    notifyCatchError(err, this.$notify);
                }).finally(() => {
                    this.searching = false;
                });
            }
            if(this.network.name == 'tiktok'){
                const params = getParamsFromSocialPostUrl(term);
                const network = getNetworkFromSocialPostUrl(term);
                if(params && params.postId && isValidObject(network)){ 
                    // any change in this object should also be changed on getTopPosts method and on Step4CreatorList.vue file
                    this.searchResult = { 
                        url: removeUnnecessaryQueryParams(term), 
                        network_id: network.id, 
                        network_name: network.name, 
                        network_display_name: network.display_name,
                        found: true,
                        preview: {
                            id: params.postId,
                            use_embed: true,
                            url: removeUnnecessaryQueryParams(term)
                        }
                    };
                }
                this.searching = false;
            }
        },
        getTopPosts(){
            this.loadingTopPosts = true;
            this.$http.post('/social-api-service/get/top-posts', {
                handle: this.handle,
                network_name: this.network.name
            }).then(({ data }) => {
                this.topPosts = data.map(post => {
                    // any change in this object should also be changed in getSinglePost method and on Step4CreatorList.vue file
                    return {
                        url: removeUnnecessaryQueryParams(post.permalink), 
                        network_id: this.network.id, 
                        network_name: this.network.name, 
                        network_display_name: this.network.display_name,
                        found: true,
                        preview: post,
                    };
                });
            }).catch(err => {
                notifyCatchError(err, this.$notify);
            }).finally(() => {
                this.loadingTopPosts = false;
            });
        },
        isSelected(post){
            const found = this.val.find(element => element.url == post.url);
            if(found){
                return true;
            }else{
                return false;
            }
        },
        togglePost(post){
            if(this.isSelected(post)){
                this.val = this.val.filter(element => element.url !== post.url);
            }else{
                this.val.push(post);
            }
            this.commit();
        }
    }
}
</script>
<style lang="scss" scoped>
    .container{
        @apply relative;
        .content{
            @apply px-8 pb-20;
            > .profile-section{
                .profile{
                    @apply flex gap-x-4 py-2;
                    .profile-picture{
                        @apply rounded-full border border-gray-500 w-14 h-14;
                    }
                    .names{
                        @apply flex flex-col justify-center gap-y-0.5;
                        .username{
                            @apply font-bold text-h6;
                        }
                        .name{
                            @apply text-gray-500 text-h6;
                        }
                        .followers-count{
                            @apply text-gray-500 text-h6;
                        }
                    }
                }
            }
            > .selected-posts{
                @apply flex flex-col gap-y-4 py-4 border-b border-gray-200;
                > .title{
                    @apply text-h5 font-bold;
                }
                > .list{
                    @apply flex gap-4;
                }
            }
            .tabs{
                @apply py-6 flex gap-x-4;
                > .tab-item{
                    @apply cursor-pointer rounded-lg px-4 py-2 bg-gray-200 text-h5;
                    @apply transition-colors duration-200;
                    &:hover{
                        @apply bg-gray-300;
                    }
                    &.selected{
                        @apply bg-purple-m-main text-white;
                    }
                }
            }
            .search{
                @apply flex flex-col gap-y-3;
                .title{
                    @apply text-h5 font-bold;
                }
                .searchbar-container{
                    @apply flex gap-x-4;
                }
                .empty-state{
                    @apply text-h5 font-bold;
                }
                > .list{
                    @apply flex gap-4;
                }
            }
            .top-posts{
                @apply flex flex-col gap-y-3;
                .title{
                    @apply text-h5 font-bold;
                }
                > .list{
                    @apply flex flex-wrap justify-center gap-4;
                }
                > .empty-state{
                    @apply text-h5 font-bold;
                }
            }   
        }

        .footer{
            @apply sticky bottom-0 border-t border-gray-200 bg-tan-m px-8 py-4 flex justify-between;
        }
    }
</style>

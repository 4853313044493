<template>
    <div 
        class="general-info-container"
        :class="{ 'bio-is-expanded': bioIsExpanded }"
    >
        <div class="field-wrapper">
            <div class="field">
                <div class="label">Bio</div>
                <div 
                    class="bio" 
                    ref="bio"
                    :class="[!bioIsExpanded ? 'not-expanded' : (!bioIsExpanded ? 'not-expanded' : '')]"
                >
                    {{ user.bio }}
                </div>
                <div 
                    class="show-more"
                    v-if="bioIsExpandable"
                    @click="bioIsExpanded = !bioIsExpanded"
                >
                    <span>{{bioIsExpanded ? 'Collapse' : 'Show more' }}</span>
                </div>
            </div>
        </div>

        <div class="field-wrapper">
            <div class="field">  
                <div class="label">Birthdate</div>
                <div class="flex items-center gap-x-4">
                    <base-icon name="calendar" :size="5"/>
                    <div>{{formattedBirthdate}}</div>
                </div>
            </div>
        </div>
        
        <div class="field-wrapper">
            <div class="field">
                <div class="label">Gender</div>
                <div v-if="!gender">Not specified</div>
                <div class="flex items-center gap-x-2" v-else>
                    <base-icon size="4" :name="gender.icon" />
                    {{ gender.name }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { genderOptions } from '@/lib/genders';
import moment from 'moment';
export default {
    props: ['user'],
    data(){
        return {
            bioIsExpandable: false,
            bioIsExpanded: false
        }
    },
    mounted(){
        this.checkBioIsExpandable();
    },
    computed: {
        formattedBirthdate(){
            return moment(this.user.birthdate).format('MMMM Do YYYY');
        },
        gender(){
            const found = genderOptions.find(element => element.id == this.user.gender)
            return found || null
        }
    },
    methods: {
        checkBioIsExpandable(){
            const { bio } = this.$refs;
            const bioHeight = bio && bio.offsetHeight ? bio.offsetHeight : null;
            if (bioHeight && bioHeight >= 48) {
                this.bioIsExpandable = true;
            }else{
                this.bioIsExpandable = false;
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .general-info-container{
        @apply w-full bg-tan-m px-12 py-6;
        @apply flex flex-wrap justify-between items-center;
        &.bio-is-expanded{
            @apply items-start;
        }
        > .field-wrapper{
            @apply w-1/3 flex;
            > .field{
                width: 300px;
                > .label{
                    @apply font-bold;
                }
                > .bio{
                    @apply overflow-x-hidden;
                    text-overflow: ellipsis;
                    // initial state
                    &.not-expanded {
                        @apply overflow-y-hidden;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        transition: max-height 3s cubic-bezier(0, 1, 0, 1);
                    }
                    &.not-expanded {
                        @apply max-h-20;
                    }
                }
                > .show-more{
                    @apply w-full flex justify-end cursor-pointer transition-colors duration-300; 
                    &:hover{
                        @apply opacity-80;
                    }
                    > span{
                        @apply text-sm font-bold;
                    }
                }
            }
        }
    }
</style>
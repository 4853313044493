<template>
    <div id="user-report-wrapper" class="user-report-wrapper">
        
        <!-- creators sidebar -->
        <div 
            class="creators-sidebar" 
            v-if="!printView && computedUsers.length"
        >
            <div class="container">
                <div class="header">
                    <div class="count">{{ computedUsers.length | numeral('0.0a') }}</div>
                    <div class="label">Creator{{ computedUsers.length !== 1 ? 's' : '' }}</div>
                </div>
                <div class="content">
                    <div class="list">
                        <div 
                            class="item" 
                            :class="{ 'active': selectedCreator == user.id }"
                            v-for="(user, u_key) in computedUsers" 
                            :key="u_key"
                            @click="focusOnCreator(user.id)"
                        >

                            <div class="profile-picture">
                                <img-selector :images="userPicture(user, u_key)" cover prop-class="rounded-full h-15 w-15"/>
                            </div>
                            <div class="name">
                                <p>{{ userName(user) }}</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- content -->
        <div class="content">

            <!-- loader -->
            <advanced-loader :visible="refreshing" :index="100" label="We are refreshing the report" :spinner="true"/>
            
            <!-- header -->
            <div class="header-container">
                <report-top :report="reportData" :user="user" @refresh="refresh" @export-button-handler="exportButtonHandler"/>
            </div>

            <div class="user-list">

                <div 
                    class="loader-container" 
                    v-if="!loadedUsers.find(e => e.id == selectedCreator)"
                >
                    <p>Loading creator...</p>
                    <base-icon name="loader"/>
                </div>

                <div
                    :id="`user-${user.id}`" 
                    class="user-item" 
                    :class="{'mt-20': printView}" 
                    v-for="(user, i) in loadedUsers"
                    :key="i"
                >
                    <template v-if="selectedCreator == user.id">
                        <ReportUserCard
                            :user="user"
                            :position="i"
                            :audience="user.payload"
                            :reportUser="user.report_user"
                            :feedback="user.feedback"
                            :allowFeedback="reportData.allow_feedback"
                            :settings="reportData.settings"
                            :updated-at="reportData.updated_at"
                            :printView="printView"
                            :is-last="i === users.length - 1"
                            :allow-replace="allowReplace"
                            :is-admin="isAdmin"
                            force-wait
                            :run-loader="loadingIndex == i"
                            :replaced-post="getReplacedPostByUser(user.id)"
                            @videos-loaded="loadingIndex++"
                            @feedback="toggleFeedback"
                            @reload-user="reloadUser(user)"
                            @edit-post="(p) => $emit('edit-post', p, true)"
                            @replace-post="replacePost"
                        />
                    </template>

                </div>

                <!-- loader -->
                <!-- we used to use this when we had the scrolling view for these reports -->
                <!-- 
                <div 
                    class="loader-container" 
                    v-if="['scroll', 'cascade'].includes(loadingType) && (limit < computedUsers.length)">
                    <p>Loading creators...</p>
                    <base-icon name="loader"/>
                </div>
                 -->

                <!-- empty state -->
                <p class="empty-state" v-if="!computedUsers.length">This report has no creators yet.</p>
            </div>

            <!-- feedback sidebar -->
            <feedback-sidebar
                :firestore="firestore"
                :feedback="feedback"
                :feedback-user="feedbackUser"
                :computed-feedback="computedFeedback"
                :user-picture="feedbackUser ? userPicture(feedbackUser) : null"
                :user-name="feedbackUser ? userName(feedbackUser) : null"
                :user-city="feedbackUser ? userCity(feedbackUser) : null"
                :force-feedback-updated-notification="forceFeedbackUpdatedNotification"
            />
            
            <!-- realtime people viewing -->
            <div class="people-viewing" v-if="!printView && activeConnections.length > 0">
                <div class="title">Other People Viewing</div>
                <div class="list">
                    <template v-for="(connection, i) in activeConnections">
                        <!-- if its more than 3 we dont show them -->
                        <div
                            v-if="i <= 2"
                            :key="`connection-${i}`" 
                            class="connection"
                        >
                            <img :src="connection.img" />
                        </div>
                    </template>
                    <div class="additional" v-if="activeConnections.length > 3">
                        <div class="title">+ {{activeConnections.length - 3}} users</div>
                    </div>
                </div>
            </div>
            
        </div>
        
        <!-- replace content modal -->
        <base-modal
            :visible="replacing"
            @close="cancelReplacing"
            title="Replace content"
            no-padding
        >
            <div class="replace-content-modal">
                <div class="content">
                    <FormulateInput
                        type="text"
                        label="Url to replace"
                        :input-class="['not-uppercased-placeholder']"
                        validation="required"
                        placeholder="https://"
                        v-model="newPostLink"
                    />
                    <!-- bookmarklet: add prop :disabled="currentPost.disabled" -->
                    <FormulateInput
                        type="text"
                        label="Username"
                        validation="required"
                        placeholder="username"
                        v-model="currentPost.handle"
                    />
                </div>
                <div class="footer">

                    <base-button 
                        @action="manualReplaceModalVisible = true" 
                        :disabled="currentPost.handle === '' || saving || newPostLink === ''" 
                        label="Manual content" 
                        bold
                        type="secondary"
                    />

                    <base-button 
                        @action="saveReplace" 
                        :disabled="currentPost.handle === '' || saving || newPostLink === ''" 
                        :label="saving ? 'Saving...' : 'Submit'" 
                        bold 
                        :secondary-icon="!saving ? 'arrow-right' : null"
                    />

                </div>
            </div>
        </base-modal>

        <!-- manual replace modal -->
        <manual-replace-modal 
            :visible="manualReplaceModalVisible"
            :current-post="currentPost"
            :new-post-link="newPostLink"
            :report-id="reportData.id"
            @close="manualReplaceModalVisible = false"
            @done="cancelReplacing"
        />
        
        <!-- copyrighted content modal -->
        <base-modal :hide-header="true" persistent :visible="missingMediaModalVisible">
            <div class="copyrighted-content-modal">
                <base-icon class="m-auto mt-6" size="12" name="exclamation-circle"/>
                <p class="title">We weren't able to get the media for this post.</p>
                <p class="description">FB won't return media if the post contains copyrighted material or has been flagged for a copyright violation. Examples of copyrighted material can include audio on reels.</p>
                <p class="action">Would you still like to add the post ? The post thumbnail will be shown and you can upload the media manually.</p>
                
                <div class="footer">
                    <base-button @action="missingMediaModalVisible = false;replacing = true;" type="secondary" size="md" bold label="Cancel"/>
                    <base-button @action="saveReplace(true)" size="md" label="Add" bold/>
                </div>
            </div>
        </base-modal>

        <!-- replacing loader -->
        <loader :visible="saving" label="Replacing" :index="100"/>

        <!-- bookmarklet -->
        <!-- bookmarklet: uncomment -->
        <!-- <tiktok-bookmarklet :open="adviseTiktok" @close="adviseTiktok = false" @confirm="acceptReplace"/> -->
    </div>
</template>
<script>
import Dropdown from '../../components/Dropdown.vue';
import ReportUserCard from '../../components/ReportUserCard';
import firestore from '../../lib/firestore';
import defaultProfile from '../../assets/images/default-profile.jpg';
import ReportTop from '@/views/ReportView/ReportTop';
import ImgSelector from '../../components/ImgSelector.vue';
import { computedIndexStr } from '../../lib/strings';
import { 
    avatar1, avatar2, avatar3, avatar4, avatar5, avatar6, avatar7,
    avatar8, avatar9, avatar10, avatar11, avatar12, avatar13, avatar14
} from '../../assets/images/avatars/index.js';
import ManualReplaceModal from './ManualReplaceModal.vue';
import FeedbackSidebar from './FeedbackSidebar.vue';

// bookmarklet: uncomment
// import TiktokBookmarklet from '@/views/ReportCreate/TiktokBookmarklet';
// import { scrapperFn } from '../../lib/scrapperDom';

export default {
    components: {
        Dropdown,
        ReportUserCard,
        ReportTop,
        ImgSelector,
        ManualReplaceModal,
        FeedbackSidebar
        // bookmarklet: uncomment
        // TiktokBookmarklet
    },

    props: {
        reportData: {}, 
        printView: {},
        user: {},
        mediaValues: {},
        showPreview: {
            type: Boolean,
            default: false 
        }
    },

    data() {
        return {
            users: [],
            loadingIndex: 0,
            limit: 3,
            selectedCreator: 0,

            feedback: {
                show: false,
                bg_show: false,
                complete: false,
                saved_changes: false,
                user_id: null,
                ratings: [],

                comments: '',
            },
            forceFeedbackUpdatedNotification: false,

            connections: {},

            firestore,

            refreshing: false,
            downloadOptions: [
                { label: 'PDF', value: 'pdf' },
                { label: 'CSV', value: 'csv' }
            ],

            defaultProfile: defaultProfile,

            replacing: false,
            manualReplaceModalVisible: false,
            saving: false,
            currentPost: {},
            newPostLink: '',
            missingMediaModalVisible: false,
            avatars: [
                avatar1, avatar2, avatar3, avatar4, avatar5, avatar6, avatar7,
                avatar8, avatar9, avatar10, avatar11, avatar12, avatar13, avatar14
            ],
            // bookmarklet: uncomment
            // adviseTiktok: false,
        }
    },

    computed: {
        loadingType() {
            /* let settings = this.reportData.settings;
            if (settings && settings.loading_type && settings.loading_type.id) {
                return settings.loading_type.id;
            } */

            return 'cascade';
        },

        computedUsers() {
            if (this.showPreview) {
                return this.users;
            }
            return this.users.filter(u => u.audience_data.length);
        },

        loadedUsers() {
            let users = this.computedUsers;
            let total = users.length;
            if (['scroll', 'cascade'].includes(this.loadingType) && this.limit < total) {
                return users.slice(0, this.limit);
            }

            return users;
        },

        allowReplace() {
            return this.user ? (this.isAdmin || this.isCollaborator ) && this.isInCompany : false;
        },

        isAdmin() {
            return this.user ? (this.user.roles.includes('admin') || this.user.roles.includes('superAdmin')) : false;
        },

        isCollaborator() {
            return this.user ? ( this.user.roles.includes('admin') || this.user.roles.includes('superAdmin') || this.user.roles.includes('collaborator') || this.user.roles.includes('projectManager')) : false;
        },

        isInCompany() {
            return this.reportData ? this.user.companies.includes(this.reportData.company_id) : false;
        },

        computedFeedback() {
            return this.feedback.ratings.map(f => {
                f.type = f.type ? f.type : 1;
                return f;
            }).filter(f => {
                return f.active || f.active === undefined;
            }).sort((a, b) => {
                return (a.type === b.type ? 0 : (a.type > b.type) ? 1 : -1);
            });
        },

        /*totalFeedbackRating() {
            return this.feedback.ratings.reduce((prev, curr) => curr += prev, 0)
        },*/

        feedbackUser () {
            return this.users.find(user => user.id === this.feedback.user_id);
        },

        connectionImages() {
            return [{
                img: require('../../assets/images/avatars/avatar-1.svg'),
                color: 'bg-red-700',
            },
            {
                img: require('../../assets/images/avatars/avatar-2.svg'),
                color: 'bg-green-700',
            },
            {
                img: require('../../assets/images/avatars/avatar-3.svg'),
                color: 'bg-pink-700',
            },
            {
                img: require('../../assets/images/avatars/avatar-4.svg'),
                color: 'bg-purple-700',
            },
            {
                img: require('../../assets/images/avatars/avatar-5.svg'),
                color: 'bg-orange-700',
            },
            {
                img: require('../../assets/images/avatars/avatar-6.svg'),
                color: 'bg-red-700',
            },
            {
                img: require('../../assets/images/avatars/avatar-7.svg'),
                color: 'bg-green-700',
            },
            {
                img: require('../../assets/images/avatars/avatar-8.svg'),
                color: 'bg-pink-700',
            },
            {
                img: require('../../assets/images/avatars/avatar-9.svg'),
                color: 'bg-purple-700',
            },
            {
                img: require('../../assets/images/avatars/avatar-10.svg'),
                color: 'bg-orange-700',
            },
            {
                img: require('../../assets/images/avatars/avatar-11.svg'),
                color: 'bg-red-700',
            },
            {
                img: require('../../assets/images/avatars/avatar-12.svg'),
                color: 'bg-green-700',
            },
            {
                img: require('../../assets/images/avatars/avatar-13.svg'),
                color: 'bg-pink-700',
            },
            {
                img: require('../../assets/images/avatars/avatar-14.svg'),
                color: 'bg-purple-700',
            }];
        },

        defaultRatings() {
            return this.reportData.settings.feedback.map(f => {
                return {
                    display: f.label,
                    active: f.active,
                    type: f.type,
                    value: f.type === 1 ? 0 : '',
                }
            });
        },

        activeConnections() {
            const output = [];
            const connections = Object.values(this.connections);
            for (let i = 0; i < connections.length; i++) {
                let item = this.connectionImages[i];
                if (item !== undefined && i > 0) {
                    const {img, color} = item;
                    output.push({
                        img,
                        color
                    });
                }
            }

            return output;
        },

        carouselPerPage(){
            let { width } = this.$screen
            return (width > 0 && width <= 400) ? 1 : 
                    (width > 400 && width < 768) ? 2 : 
                      (width > 768 && width < 900) ? 3 : 4
        }
    },

    mounted() {
        this.$parent.isLoggedIn = false;
        this.feedback.ratings = this.defaultRatings;
        this.setData();
        // this.triggerScroll();
        this.startListeners();
    },

    beforeUnmount() {
        window.removeEventListener('scroll', this.loadCreators);
    },
    
    methods: {
        focusOnCreator(id) {
            this.selectedCreator = id;
            // let el = document.getElementById(`user-${id}`);
            // if (el) {
            //     this.$scrollTo(`#user-${id}`, 500, {offset: -180});
            // } else {
            //     window.scrollTo(0, document.body.scrollHeight);
            // }
        },
        loadCreators() {
            let slack = 150;
            let { scrollTop, clientHeight, scrollHeight } = document.documentElement;
            if (scrollTop + clientHeight + slack >= scrollHeight) {
                if (this.limit < this.computedUsers.length) {
                    this.limit = this.limit + 3;
                }
            }
        },
        startListeners() {
            if (this.loadingType === 'scroll') {
                window.addEventListener('scroll', this.loadCreators);
            } else if (this.loadingType === 'cascade') {
                let increment = setInterval(() => {
                    this.limit = this.limit + 3;
                    if (this.limit >= this.computedUsers.length) {
                        clearInterval(increment);
                    }
                }, 5000);
            }
        },
        reloadUser(user) {
            this.$emit('reload-user', user);
        },
        triggerScroll() {
            setTimeout(() => {
                const el = document.querySelector('.users-topbar');
                const observer = new IntersectionObserver( 
                    ([e]) => e.target.classList.toggle('rounded-t-none', e.intersectionRatio < 1),
                    { threshold: [1] });
                observer.observe(el);
            }, 250);
        },

        exportButtonHandler(option) {
            switch (option.value) {
                case 'pdf':
                    this.$emit('download-pdf');
                    break;
                case 'csv':
                    this.$emit('download-csv', 'influencers');
                    break;
            }
        },

        userName(user) {
            // use company name if it exists
            let payload = this.userPayload(user);
            let useCompanyName = user.user_companies && user.user_companies[0] && (user.user_companies[0].first_name && user.user_companies[0].last_name)
            if(useCompanyName){
                return `${user.user_companies[0].first_name} ${user.user_companies[0].last_name}`
            }else{
                if (user.first_name) {
                    return `${user.first_name} ${user.last_name}`;
                }
                return payload.user_profile.fullname;
            }
        },

        userPicture(user, index = 0) {
            let images = [];

            let payload = this.userPayload(user);
            if (payload.user_profile && payload.user_profile.picture) {
                let src = payload.user_profile.picture;
                let isTiktok = src.includes('tiktokcdn');
                
                if (!(this.printView && isTiktok)) {
                    images.push(src);
                }
            }

            if (user && user.avatar) {
                images.push(user.avatar);
            }
            images.push(this.avatars[computedIndexStr(index || 0)]);
            return images;
        },

        userCity(user) {
            let payload = this.userPayload(user);
            let profile = payload.user_profile;

            return profile && profile.geo && profile.geo.city ? profile.geo.city.name : null;
        },

        userPayload(user) {
            let igAccount = user.audience_data.find(audience => {
                return audience.network_id === 2;
            });

            return igAccount ? igAccount.payload : (user.audience_data.length ? user.audience_data[0].payload : {});
        },

        async refresh() {
            this.refreshing = true;
            this.$http.get(`/api/reports/users/${this.$route.params.id}/refresh`).then(() => {
                this.$emit('refresh');
                this.refreshing = false;
            }).catch(() => {
                this.refreshing = false;
            });
        },

        async setData() {
            const data = this.reportData;
            
            this.users = data.influencers.map(aud => {
                aud.feedback = this.feedback.ratings;
                return aud;
            });

            this.selectedCreator = this.users[0].id;

            this.setFirebaseListeners();

        },
        
        setFirebaseListeners() {
            firestore.ref(this.$route.params.id).on('value', (snapshot) => {
                const results = snapshot.val();
                console.log("updated results", results);
                for (let i in results) {
                    if (i !== 'connections') {
                        this.updateFeedbackForUser(i, results[i]);
                    }
                    else {
                        this.connections = results[i];
                    }
                }
            });

            const activeConnectedRef = firestore.ref(`${this.$route.params.id}/connections`);
            const connectedRef = firestore.ref('.info/connected');
            connectedRef.on('value', function(snap) {
                if (snap.val() === true) {
                    // We're connected (or reconnected)! Do anything here that should happen only if online (or on reconnect)
                    const con = activeConnectedRef.push();

                    // When I disconnect, remove this device
                    con.onDisconnect().remove();

                    // Add this device to my connections list
                    // this value could contain info about the device or a timestamp too
                    con.set(true);

                    // When I disconnect, update the last time I was seen online
                    // lastOnlineRef.onDisconnect().set(firebase.database.ServerValue.TIMESTAMP);
                }
            });
        },

        updateFeedbackForUser(userId, feedback) {
            const userIndex = this.users.findIndex(u => parseInt(u.id) === parseInt(userId));

            if (this.users[userIndex]) {
                this.users[userIndex].feedback = feedback;
                
                if (parseInt(this.feedback.user_id) === parseInt(userId)) {
                    this.feedback.ratings = feedback.ratings;
                    this.feedback.comments = feedback.comments || '';
                    // By changing this var, the "<feedback-sidebar/>" component will watch the change and show a notification
                    this.forceFeedbackUpdatedNotification =  !this.forceFeedbackUpdatedNotification;
                }
            }
            else {
                console.log("didn't find userId", userId);
            }
        },

        toggleFeedback({ id }) {
            this.feedback.user_id = id;
            const user = this.users.find(u => 
                parseInt(u.id) === parseInt(id)
            );

            this.feedback.ratings = user.feedback.ratings || this.defaultRatings;
            this.feedback.comments = user.feedback.comments || '';
            this.feedback.show = true;
        },

        getReplacedPostByUser(id) {
            if (this.reportData && this.reportData.replaced_posts) {
                return this.reportData.replaced_posts.filter(p => p.user_id === id);
            }
            return [];
        },

        replacePost(user, post) {
            this.replacing = true;
            this.newPostLink = '';
            let parsedPost = JSON.parse(JSON.stringify(post));
            parsedPost.user = JSON.parse(JSON.stringify(user));
            parsedPost.handle = parsedPost.profile_item.username;
            // bookmarklet uncomment
            // parsedPost.disabled = parsedPost.profile_item.network_id == 14 || parsedPost.original_url.includes('tiktok');
            this.currentPost = parsedPost;
            if (post.is_replaced) {
                this.newPostLink = parsedPost.original_url;
            }
        },

        manualReplacePost(){
            this.replacing = false;
            this.manualReplaceModalVisible = true
        },
        
        // bookmarklet: remove this method
        saveReplace(force = false) {
            if (this.newPostLink !== '') {
                
                this.missingMediaModalVisible = false;
                let body = {
                    report_id: this.reportData.id,
                    user_id: this.currentPost.user.id,
                    original_url: this.currentPost.original_url || this.currentPost.link,
                    link: this.newPostLink,
                    username: this.currentPost.handle,
                    force
                };

                this.saving = true;
                this.$http.post(`/api/reports/change-post`, body).then(({data}) => {
                    this.replacing = false;
                    if (data.pending && !force) {
                        this.missingMediaModalVisible = true;
                    } else {
                        this.currentPost = {};
                        this.newPostLink = '';
                        this.$emit('refresh');
                        this.$notify({ title: 'Success', text: 'The content has been modified', type: 'success' })
                    }
                }).catch(() => {
                    this.$notify({ title: 'Error', text: `There was a problem. Try again later`, type: 'warn' });
                    this.$swal.fire({
                        title: `We were unable to automatically get this post data`,
                        text: "You can add the post manually, or try again later.",
                        icon: 'warning',
                        iconColor: '#0E092C',
                        showCancelButton: true,
                        confirmButtonText: 'Add manually',
                        reverseButtons: true,
                    }).then((result) => {
                        if (result.isConfirmed) {
                           this.manualReplacePost()
                        }
                    });
                }).finally(() => {
                    this.saving = false;
                });
            }
        },

        // bookmarklet: uncomment
        // saveReplace(force = false) {
        //     if (this.newPostLink !== '') {
        //         this.missingMediaModalVisible = false;
        //         this.adviseTiktok = false;
        //         if (this.newPostLink.includes('tiktok')) {
        //             this.adviseTiktok = true;
        //         } else {
        //             this.processPost({force});
        //         }
        //     }
        // },

        // acceptReplace() {
        //     this.adviseTiktok = false;
        //     scrapperFn({instance: this, link: this.newPostLink, callback: this.processPost});
        // },

        // processPost({force = false, scrapperId = null}) {
        //     let body = {
        //         report_id: this.reportData.id,
        //         user_id: this.currentPost.user.id,
        //         original_url: this.currentPost.original_url || this.currentPost.link,
        //         link: this.newPostLink,
        //         username: this.currentPost.handle,
        //         force
        //     };

        //     if (scrapperId !== null) {
        //         body.scrapperPostId = scrapperId;
        //     }

        //     this.saving = true;
        //     this.$http.post(`/api/reports/change-post`, body).then(({data}) => {
        //         this.replacing = false;
        //         if (data.pending && !force) {
        //             this.missingMediaModalVisible = true;
        //         } else {
        //             this.currentPost = {};
        //             this.newPostLink = '';
        //             this.$emit('refresh');
        //             this.$notify({ title: 'Success', text: 'The content has been modified', type: 'success' })
        //         }
        //     }).catch(() => {
        //         this.$notify({ title: 'Error', text: `There was a problem. Try again later`, type: 'warn' });
        //     }).finally(() => {
        //         this.saving = false;
        //     });
        // },

        cancelReplacing() {
            this.replacing = false;
            this.newPostLink = '';
            this.currentPost = {};
        }
        
    }
}
</script>

<style lang="scss">
.user-report-wrapper{
    @apply flex mx-auto;

    > .creators-sidebar{
        @apply pointer-events-none;
        @apply flex-grow-0 flex-shrink-0;
        background-color: #fff;
        width: 300px;
        height: 100vh;
        @apply overflow-y-auto;
        @apply sticky top-0 bottom-0 left-0;

        > .container{
            @apply w-full flex flex-col;
            @apply pointer-events-auto;

            > .header{
                @apply w-full px-6 py-3 border-b border-gray-300;
                > .count{
                    @apply text-2xl font-bold;
                }
                > .label{
                    @apply text-sm font-light text-gray-300;
                }
            }
            > .content{
                @apply mt-6;
                @apply pointer-events-auto;
                > .list{
                    @apply flex flex-col gap-1 pb-36;
                    > .item{
                        @apply flex px-6 py-2 w-full cursor-pointer transition-opacity transition-colors duration-300;
                        &:hover{
                            @apply opacity-80;
                        }
                        &.active{
                            @apply font-bold text-white;
                            background-image: linear-gradient(to bottom, var(--gradient-color-stops));
                            --gradient-from-color: #42339f;
                            --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(66, 51, 159, 0));
                            --gradient-to-color: #191245;
                        }
                        > .profile-picture{
                            @apply flex-shrink-0 flex-grow-0;
                        }
                        > .name{
                            @apply pl-3 flex flex-wrap content-center flex-shrink flex-grow;
                            & p{
                                @apply w-full;
                            }
                        }
                    }
                }
            }
        }
    }
    > .content{
        @apply mx-auto py-10 px-16 text-center;
        @apply flex-grow flex-shrink;
        
        > .header-container{
            @apply mx-auto text-center mb-6;
            @media (min-width: 768px) {
                @apply text-left;   
            }
        }
        > .user-list{
            @apply w-full mt-12 mb-6;
            > .user-item{
                @apply mx-auto mb-4;
            }
            > .loader-container{
                @apply flex flex-col justify-center items-center gap-2 text-xl my-16 text-center;
            }
            > .empty-state{
                @apply text-center mt-36;
            }
        }
        > .people-viewing{
            @apply fixed bottom-4 left-4 z-10 px-2 py-2 bg-black bg-opacity-50;
            > .title{
                @apply text-white font-bold mb-2;
            }
            > .list{
                @apply flex items-center justify-center gap-x-2;
                > .connection{
                    @apply w-12 h-12 rounded-full border border-white;
                }
                > .additional{
                    @apply flex flex-col;
                    > .title{
                        @apply text-white font-bold;
                    }
                }
            }
        }
    }
}

// replace content modal
.replace-content-modal{
    @apply flex flex-col justify-between;
    > .content{
        @apply flex flex-col py-5 px-14 gap-y-2;
    }
    > .footer{
        @apply flex justify-end gap-3 rounded-b-2xl py-3 px-14;
    }
}

// copyrighted content modal
.copyrighted-content-modal{
    @apply flex flex-col gap-4;
    > .title{
        @apply text-h4 text-center mt-4 font-bold;
    }
    > .description{
        @apply text-center mt-2;
    }
    > .action{
        @apply text-center mt-2 font-bold;
    }
    > .footer{
        @apply flex justify-center w-full gap-16 mt-6;
    }
}

.VueCarousel-pagination{
    display: none;
}

.users-topbar.rounded-t-none {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}
</style>

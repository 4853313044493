<template>
    <main class="project-settings flex-1 relative overflow-y-hidden focus:outline-none">
        <base-table-new
            :columns="projectTableColumns(project)"
            v-if="localRowData.length > 0"
            :data="localRowData"
            :onRowClick="(element) => openCreator(element, project)"
            :tableKey="project.id"
            :loading="loading"
            :search="false"
            :tableClass="'master-table min-w-full overflow-x-auto'"
            :addColumnEnabled="true"
            :dynamic-components="getDynamicComponents()"
            :collapsed="project.collapsed"
            @toggle-nested="toggleShowUserDeliverables"
            :container-styles="`overflow-y: auto; margin-right: 5px; min-height: ${isStandAlone ? '500px' : '100px'}; max-height: calc(100vh - ${!isStandAlone ? '580px' : '250px'}`"
            overflow
            :border="border"
            sticky-header
            sticky-row
            handle-scroll
        >
            <!-- will finish this at a later time -->
            <!-- :customComponents="customComponents" -->
            <template v-slot:table-header>
                <div class="flex items-center px-6" :class="isStandAlone ? 'justify-end' : 'justify-between'">
                    <base-button
                        class="col-span-4"
                        v-if="!isStandAlone"
                        type="label"
                        justify="start"
                        @action="(!isGuest && !isClient) ? $router.push(`/projects/${project.slug}`) : null">
                        <div class="font-bold text-purple-m-secondary">{{`${ project.name } - ${ project.brand }`}}</div>
                    </base-button>
                    <div class="flex items-center gap-12 justify-end">
                        <div v-if="!isStandAlone" class="flex gap-2">
                            <base-icon name="user"/>
                            <span>{{ localRowData.length }}</span>
                            <span>Creator{{ localRowData.length !== 1 ? 's' : '' }}</span>
                        </div>
                        <div class="flex gap-2">
                            <base-icon name="deliverable-alt"/>
                            <span>{{ project.total_deliverables }}</span>
                            <span>Deliverables</span>
                        </div>

                        <template v-for="(column, index) of tableOptions">
                            <div :key="index">
                                <template v-if="column.type == 'header-button'">
                                    <base-button v-if="column.options && column.options(column)" @action="column.onAddColumnClick(column)" type="label" :icon-size="4" :icon="column.options(column).button.icon"/>
                                </template>

                                <template v-if="column.type == 'header-button-dropdown'">
                                    <options-dropdown
                                        v-show="!project.collapsed"
                                        v-if="column.options(column) && column.options(column).dropdown"
                                        theme="cancel"
                                        :custom-open-z-index="column.options(column).openIndex"
                                        :options="column.options(column).dropdown.options"
                                        :actions="column.options(column).dropdown.actions"
                                        @click.native="stopPropagation"
                                        >
                                        <template #icon>
                                            <base-icon name="plus" :size="5" />
                                        </template>
                                    </options-dropdown>
                                </template>

                                <!-- <template v-if="column.type == 'expand-all-sub-tables'">
                                    <button @click="column.onClick(column)" class="focus:outline-none">{{(expanded) ? column['expandText'][1] : column['expandText'][0] }}</button>
                                </template>

                                <template v-if="column.type == 'edit-columns'">
                                    <button @click="column.onClick(column)" icon="column-edit" class="focus:outline-none">{{column.label}}</button>
                                </template> -->
                            </div>

                        </template>

                        <base-icon :size="4"
                            v-if="!project.loading && !isStandAlone"
                            @action="collapseToggle(project)"
                            :name="!project.collapsed ? 'chevron-down' : 'chevron-right'"
                            class="cursor-pointer"/>
                        <base-icon name="loader" v-if="project.loading"/>
                    </div>
                </div>
            </template>

            <template #nested-table="slotProps">
                <base-table-new
                    sticky-header
                    :tableClass="'nested-table min-w-full overflow-x-auto;'"
                    :columns="deliverableTableColumns(slotProps.data)"
                    :data="slotProps.data.user_deliverables.rows"
                    is-nested
                    :onRowClick="(element) => openDeliverable(element)"
                    :search="false"
                    container-styles="margin-top:30px"
                    :border="false"
                >
                </base-table-new>
            </template>

        </base-table-new>

        <section-empty-state
            v-else-if="!loading"
            icon="user-group"
            message="You haven't added any influencers yet."
            :action="!isDeleted ? 'Assign Creator' : null"
            @action="showAssignCreatorModal = true"
        />

        <table-loader table-class="master-table w-full" v-if="loading && !hideLoader"/>
        
        <!-- modals -->
        
        <link-post-user-deliverable-modal
            :visible="currentUserDeliverable != null && showLinkPostUserDeliverableModal"
            @close="currentUserDeliverable = null"
            @posts-linked="closeLinkPostModal"
            :currentUserDeliverable="currentUserDeliverable"
        />

        <deliverables-table-column-modal
            :visible="showColumnModal"
            :project="project"
            :projectUser="selectedProjectUser"
            :column="selectedColumn"
            :dataTypes="columnDataTypes"
            @close="showColumnModal = false"
            @save-new-column="addColumn"
            @edit-column="editColumn"
        />

        <create-version-modal
            v-if="showCreateVersionModal"
            :visible="showCreateVersionModal"
            @close="closeCreateVersionModal"
            @done="closeCreateVersionModal"
            :isManual="true"
            :projectName="project.name"
            :deliverable="currentUserDeliverable"
            :submissionType="currentUserDeliverableSubmissionType"
            :standAlone="true"
        />

        <add-influencer-deliverable-modal
            v-if="selectedProjectUser"
            :visible="showAddInfluencerDeliverableModal"
            @close="resetAddDeliverableModal"
            @user-deliverable-created="updateUserDeliverable"
            :projectId="project.id"
            :projectUserId="selectedProjectUser.project_user_id"
            :networks="networks"
            :currentUserDeliverable="selectedUserDeliverable"
        />

        <assign-creator-modal
            :visible="showAssignCreatorModal"
            :key="`assign_${assignCreatorKey}`"
            :creators="reducedCreators"
            :initialSelectedElements="[]"
            :projectSlug="project.id"
            :tags="tags"
            :loading="loadingCreators"
            title="Assign Creators"
            @close="showAssignCreatorModal = false"
            @reload-creators="getCreators"
            @confirm-selected-creators="onConfirmSelectedCreators"
        />

        <edit-creators-columns-modal-new
            :visible="editCreatorsColumnsModalVisible"
            :admin="isAdminOrHigher"
            @close="editCreatorsColumnsModalVisible = false"
            :passedSelectedCreatorColumns="selectedCreatorColumns"
            :passedCreatorColumns="creatorColumns"
            :projectId="selectedProjectId || 0"
            @edited-columns="refreshCreatorColumns"
        />

        <share-collection-link-modal
            :visible="selectedUserDeliverable && showShareCollectionLinkModal"
            @close="closeShareCollectionLinkModal"
            :submitBy="submitBy(shareCollectionType)"
            :type="shareCollectionType"
            :deliverableToken="selectedUserDeliverable ? selectedUserDeliverable.token : null"
            :creatorEmail="Object.keys(selectedUser).length > 0 ? selectedUser.email : null"
            :creatorName="Object.keys(selectedUser).length > 0 ? `${selectedUser.first_name} ${selectedUser.last_name}` : null"
        />

        <fixed-loader :visible="addingCreators" label="Adding Creators"/>
    </main>
</template>
<script>

import { mapGetters } from 'vuex';
import { hyphenToCamelCase } from '../lib/strings'
import DeliverablesTableColumnModal from '../components/DeliverablesTableColumnModal.vue';
import LinkPostUserDeliverableModal from '../components/LinkPostUserDeliverableModal';
import CreateVersionModal from '../views/VersionCreate/CreateVersionModal';
import AssignCreatorModal from '../components/AssignCreatorModal.vue';
import EditCreatorsColumnsModalNew from '../components/EditCreatorsColumnsModalNew.vue';
import SectionEmptyState from '../components/SectionEmptyState.vue';
import TableLoader from '../components/TableLoader.vue';
import AddInfluencerDeliverableModal from '../components/AddInfluencerDeliverableModal.vue';
import Tags from './Tags.vue';
import Networks from '../components/Networks.vue';
import ShareCollectionLinkModal from '../components/ShareCollectionLinkModal.vue';
import { formatMoney, cleanMoney, notifyCatchError } from '../common';
import { instagramFollowers, tiktokFollowers, youtubeFollowers } from '../lib/user';

export default {
    components: {
        DeliverablesTableColumnModal,
        LinkPostUserDeliverableModal,
        CreateVersionModal,
        AssignCreatorModal,
        EditCreatorsColumnsModalNew,
        Tags,
        Networks,
        SectionEmptyState,
        TableLoader,
        AddInfluencerDeliverableModal,
        ShareCollectionLinkModal
    },
    props: {
        expanded: {
            type: Boolean,
            default: false
        },
        isStandAlone: {
            type: Boolean,
            default: false
        },
        project: {
            type: Object,
            required: true
        },
        rowData: {
            type: Array,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        },
        hideLoader: {
            type: Boolean,
            default: false
        },
        border: {
            type: Boolean,
            default: true
        },
        passedColumnDataTypes: {
            type: Array,
            default: function() {
                return [];
            },
            required: false
        },
        passedTags: {
            type: Array,
            default: function() {
                return [];
            },
            required: false  
        },
        passedCreatorColumns: {
            type: Array,
            default: () => [],
        },
        passedSelectedCreatorColumns: {
            type: Array,
            default: () => [],
        },
        passedNetworks: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {

            localExpanded: this.expanded,

            firstWatchInvocation: 0,

            tableOptions: [

                // {
                //     isHeader: true,
                //     name: 'expand-collapse',
                //     label: 'Expand',
                //     expandText: ['Expand All', 'Collapse All'],
                //     type: 'expand-all-sub-tables',
                //     expanded: this.expanded,
                //     onClick: () => this.expandCollapse()
                // },

                // {
                //     isHeader: true,
                //     name: 'edit-columns',
                //     label: 'Edit Columns',
                //     type: 'edit-columns',
                //     onClick: (element) => this.editColumns(this.project)
                // },

                {
                    isHeader: true,
                    name: 'add-menu',
                    label: 'Add Menu',
                    type: 'header-button-dropdown',
                    options: (column) => {
                        return {
                            button: {
                                icon: 'plus'
                            },
                            openIndex: 'unset',
                            dropdown: {
                                options: this.isDeleted ? [] : this.projectAddColumnOptions(column),
                                actions: this.projectLevelAddNewDropdownActions(column)
                            }
                        }
                    }
                }

            ],

            // going to build this out later
            // customComponents: [
            //     {
            //     name: "tags",
            //     component: Tags,
            //     props: {
            //       tags: [],
            //       limit: 1
            //     }
            //   },
            // ],

            localRowData: [],

            networks: this.passedNetworks,

            // options vars
            standardColumnOptions: [
                ...(this.isDeleted ? [] : [
                    {label: 'Move Right', icon: 'chevron-double-right', event: 'move-right'},
                    {label: 'Move Left', icon: 'chevron-double-left', event: 'move-left'},
                    {label: 'Edit', icon: 'pencil-alt', event: 'edit', separator: true},
                    {label: 'Delete', icon: 'trash', event: 'delete', theme: 'cancel'}
                ])
            ],

            userProjectOptions: [
                ...(this.isDeleted ? [] : [
                    {label: 'Edit Creator', event: 'edit-creator'},
                    {label: 'Remove Creator', event: 'remove-creator'},
                    {label: 'Add Deliverable', event: 'add-deliverable'},
                ])
            ],

            userDeliverableOptions: [
                ...(this.isDeleted ? [] : [
                    {
                        label: 'Edit Deliverable', 
                        event: 'edit-deliverable',
                        disabled: this.isUserAdminOrHigher == false, 
                        disabledTooltip: this.isUserAdminOrHigher == false ? {
                            content: `Only project managers and admins can perform this action`,
                            classes: 'bg-black text-white rounded py-1 px-2 text-xs', 
                            delay: { 
                                show: 100, hide: 50 
                            } 
                        } : {}
                    },
                    {
                        label: 'Duplicate Deliverable', 
                        event: 'duplicate-deliverable',
                        disabled: this.isUserAdminOrHigher == false, 
                        disabledTooltip: this.isUserAdminOrHigher == false ? {
                            content: `Only project managers and admins can perform this action`,
                            classes: 'bg-black text-white rounded py-1 px-2 text-xs', 
                            delay: { 
                                show: 100, hide: 50 
                            } 
                        } : {}
                    },
                    {
                        label: 'Remove Deliverable', 
                        event: 'remove-deliverable',
                        disabled: this.isUserAdminOrHigher == false, 
                        disabledTooltip: this.isUserAdminOrHigher == false ? {
                            content: `Only project managers and admins can perform this action`,
                            classes: 'bg-black text-white rounded py-1 px-2 text-xs', 
                            delay: { 
                                show: 100, hide: 50 
                            } 
                        } : {}

                    }
                ])
            ],

            // column manipulation related vars
            selectedColumn: null,
            selectedColumnType: null,
            columnDataTypes: [],

            // temp saved column for the undo action to work
            deletedColumn: null,

            // modal-related vars
            deliverableModalKey: false,
            currentUserDeliverable: null,
            currentUserDeliverableSubmissionType: null,
            selectedProjectUser: null,
            assignCreatorKey: false,
            loadingCreators: true,
            creators: [],
            tags: [],
            selectedCreatorColumns: [],
            creatorColumns: [],
            showAddInfluencerDeliverableModal: false,
            selectedUserDeliverable: null,
            showShareCollectionLinkModal: false,
            shareCollectionType: "",


            // modal display vars
            showColumnModal: false,
            showCreateVersionModal: false,
            showLinkPostUserDeliverableModal: false,
            showAssignCreatorModal: false,
            link_post_visible: false,
            addManualConcept: false,
            editCreatorsColumnsModalVisible: false,
            
            // state-related vars
            savingUserColumn: false,

            addingCreators: false,

            selectedProjectId: null,
            
        }
    },

    async mounted() {
        this.getTags();
        this.refreshSelectedCreatorColumns();
        this.refreshCreatorColumns();
        
        // Simulate an asynchronous operation to prevent networks from being loaded more than once
        await new Promise(resolve => setTimeout(resolve, 1000));
        this.getNetworks();

        this.getColumnTypes();
    },

    created() {
        this.localRowData = this.rowData.map(row => {
            return {
              ...row,
              index: row.project_user_id,
              removed: false,
              options: (element) => {
                return {
                  dropdown: {
                    options: this.isDeleted || this.isClient ? [] : this.userProjectOptions,
                    actions: this.projectUserDropdownActions(element)
                  }
                }
              }
            };
          });
    },
    
    computed: {
        ...mapGetters(['isAdminOrHigher', 'isUserAdminOrHigher', 'isGuest', 'isClient', 'fbLogin']),

        selectedUser() {
            if ( this.selectedUserDeliverable )
                return this.localRowData.find( lrd => lrd.project_user_id === this.selectedUserDeliverable.project_user_id ).user;
            else
                return {};
        },

        reducedCreators() {
            let ids = [];
            if (this.project.rows && this.project.rows.length > 0) {
                ids = this.project.rows.map(row => row.user_id);
            }
            return this.creators.filter(item => {
                return !ids.includes(item.id);
            });
        },

        computedCreators() {
            if (this.reducedCreators.length) {
                return this.reducedCreators.map(item => {
                    let { avatar, first_name, id, email, last_name, user_projects, user_tags } = item;
                    return { avatar, first_name, id, email, last_name, user_projects, user_tags };
                });
            }
            return [];
        },

        isDeleted() {
            return this.project.deleted_at !== null && this.project.deleted_at !== undefined;
        },

    },
    watch: {

        // I needed this for something but it breaks the remove creator functionality. I tested everything and it seems to be working ok without it so far...
        // I'm leaving it as a placeholder in case we notice any issues. I'm hoping that the local copy will do the trick though.
        rowData: {

            handler(newValue) {

                if (newValue != null) {
                        
                    // we save the prop locally for manipulation
                    this.localRowData = this.rowData.map( row => {
                        return {
                            ...row,
                            index: row.project_user_id,
                            user_deliverables: { rows: this.prepareRows(row.user_deliverables && row.user_deliverables.rows ? row.user_deliverables.rows : []), id: row.user_deliverables ? row.user_deliverables.id : null },
                            removed: false, 
                            options: (element) => {
                                return {
                                    dropdown: {
                                        options: this.isDeleted || this.isClient ? [] : this.userProjectOptions,
                                        actions: this.projectUserDropdownActions(element)
                                    }
                                }
                            }
                        };
                    });
                }
            }
        },

        localRowData: {
            handler(newValue) {
                if (newValue != null) {
                    // we save the prop locally for manipulation
                    this.localRowData = newValue;
                }
            }

        },

        passedCreatorColumns: {
            deep: true,
            immediate: true,
            handler(newValue) {
                if (newValue == null) {
                    this.refreshCreatorColumns();
                } else {
                    this.creatorColumns = newValue;
                }
            },
        },

        passedSelectedCreatorColumns: {
            deep: true,
            immediate: true,
            handler(newValue) {
                if (newValue == null || newValue.length === 0) {
                    this.refreshSelectedCreatorColumns();
                } else {
                    this.selectedCreatorColumns = newValue;
                }
            },
        },

        passedColumnDataTypes: {
            deep: true,
            immediate: true,
            handler(newValue) {
                if (newValue == null || newValue.length === 0) {
                    this.getColumnTypes();
                } else {
                    this.columnDataTypes = newValue;
                }
            },
        }
    },

    methods: {

        submitBy(type){
            if ( this.selectedUserDeliverable ) {
                let { concept_approval, post_approval, end_date } = this.selectedUserDeliverable;
                return end_date || concept_approval || post_approval;
            } else {
                return null;
            }
        },

        getDynamicComponents() {
            const components = ['Networks', 'Tags'];

            const compObjects = {};

            for ( var component in this.$options.components ) {

                if ( components.indexOf(component) > -1 ) {
                    compObjects[component] = { component: this.$options.components[component], data: { propName: "networks", value: this.networks}};
                }
            }
            
            return compObjects;

        },

        async getNetworks() {

            if ( this.passedNetworks.length == 0 ) {
                const { data } = await this.$http.get('/api/userDeliverables/networks');
                this.networks = data.map(network => {
                    network.value = network.id;
                    network.label = network.display_name;
                    return network;
                });
            } else {
                this.networks = this.passedNetworks;
            }
        },

        projectAddColumnOptions(column) {

            const options = [
                ...(this.isDeleted || this.isClient ? [] : [
                    {label: 'Add Column', event: 'add-column'},
                    {label: 'Add Creator', event: 'add-creator'},
                    {label: 'Edit Visible Columns', event: 'edit-columns'}
                ]),
                {   
                    label: (this.localExpanded) ? "Collapse All" : "Expand All", 
                    event: 'expand-collapse'
                }
            ];

            return options;
        },

        projectAddDeliverableColumnOptions(column) {

            const options = [
                ...(this.isDeleted || this.isClient ? [] : [
                    { label: 'Add Column', event: 'add-column'},
                    {label: 'Add Deliverable', event: 'add-deliverable'},
                    // {label: 'Edit Visible Columns', event: 'edit-columns'},
                ])
            ];

            return options;
        },

        stopPropagation(event) {
            event.stopPropagation();
        },

        async getColumnTypes() {
            if ( this.passedColumnDataTypes == null ) {
                console.log('getting here');
                const columnTypes = await this.$http.get('/api/userDeliverables/columnTypes');
                this.columnDataTypes = columnTypes.data.map(type => {
                    type.value = type.id
                    return type;        
                });

                console.log('this.columnDataTypes', this.columnDataTypes);
            } else {
                this.columnDataTypes = this.passedColumnDataTypes;
            }
        },

        async getTags(){
            if (Array.isArray(this.passedTags) && this.passedTags.length) {
                this.tags = this.passedTags;
            } else {
                const { data } = await this.$http.get(`/api/tags`);
                this.tags = data;
            }
        },

        customUserTagsSearchBy(element) {
            let str = ``;
            element.user.tags.forEach(user_tag => {
                str = str + user_tag + ' '
            });

            return str;
        },

        async refreshCreatorColumns(data = null) {
            if ( data != null ) {
                const updatedColumns = this.creatorColumns.filter(cc => data.includes(cc.id)).map( ccc => {
                    return {...ccc, creator_column_id: ccc.id};
                });
                this.selectedCreatorColumns = updatedColumns;
            } else {
                if ( this.passedCreatorColumns == null ) {
                    const { data } = await this.$http.get(`/api/companies/creator-columns/`);
                    this.creatorColumns = data;
                } else {
                    this.creatorColumns = this.passedCreatorColumns;
                }
            }
        },

        async refreshSelectedCreatorColumns() {
            if ( this.passedSelectedCreatorColumns == null ) {
                const { data } = await this.$http.post(`/api/projects/creator-columns/`, {ids: [this.project.id]});
                this.selectedCreatorColumns = data;
            } else {
                this.selectedCreatorColumns = this.passedSelectedCreatorColumns;
            }            
        },

        async onConfirmSelectedCreators(users) {
            this.addingCreators = true;
            const { data } = await this.$http.post(`/api/projects/${this.project.id}/update-users`, {users: users});
            if (data) {
                this.showAssignCreatorModal = false;
                this.$notify({ title: 'Creators assigned successfully', text: '', type: 'success' });
                this.assignCreatorKey = !this.assignCreatorKey;
                this.$emit('refresh-creators');
                setTimeout(() => {
                    this.$emit('update-stats');
                }, 500);
            } else {
                this.$notify({ title: 'Warning', text: 'Something went wrong', type: 'warn' })
            }

            this.addingCreators = false;
        },

        async getCreators() {
            this.loadingCreators = true;
            const { data } = await this.$http.get('/api/users?creators=true');
            this.creators = data;
            this.loadingCreators = false;
        },

        forceUpdate() {
            this.$forceUpdate();
        },

        async toggleShowUserDeliverables(project_user, event, force = null) {
            const { project_user_id } = project_user;

            if (!project_user.expand) {
                const { data } = await this.$http.get(`/api/projects/new-deliverable/${project_user_id}`);

                const final = this.prepareRows(data.rows);

                project_user.user_deliverables = {id: project_user_id, rows: final};
            }

            project_user.expand = (force != null) ? force : !project_user.expand;
            this.$forceUpdate();
        },

        prepareRows(rows) {
            return rows.map( d => {
                return {
                    ...d,
                    editable_cost: {
                        data: d.cost,
                        edit: false,
                        target: "cost"
                    },
                    index: d.project_user_id,
                    removed: false,
                    options: (element) => {
                        return {
                            dropdown: {
                                options: this.isDeleted || this.isClient ? [] : this.userDeliverableOptions,
                                actions: this.projectUserDeliverableDropdownActions(element, d)
                            }
                        }
                    }
                }
            });
        },

        projectTableColumns(project) {
            let filteredOutput = [];
            let finalOutput = [];
            const output = [
                { 
                    name: 'city',
                    slug: 'city',
                    isNonStandard: false,
                    label: 'City',
                    type: 'string',
                    value: (element) => {
                        return element.user.city || '-'
                    } 
                },
                { 
                    name: 'state',
                    slug: 'state',
                    isNonStandard: false,
                    label: 'State',
                    type: 'string',
                    value: (element) => {
                        return element.user.state || '-'
                    } 
                },
                { 
                    name: 'country',
                    slug: 'country',
                    isNonStandard: false,
                    label: 'Country',
                    type: 'string',
                    value: (element) => {
                        return element.user.country || '-'
                    } 
                },
                { 
                    name: 'phone',
                    slug: 'phone',
                    isNonStandard: false,
                    label: 'Phone',
                    type: 'string',
                    value: (element) => {
                        return element.user.phone || '-'
                    } 
                },
                { 
                    name: 'email',
                    slug: 'email',
                    isNonStandard: false,
                    label: 'Email',
                    type: 'string',
                    value: (element) => {
                        return element.user.email || '-'
                    } 
                },
                {
                    name: 'tags',
                    slug: 'tags',
                    component: 'tags',
                    isNonStandard: false,
                    label: 'Tags',
                    type: 'custom-component',
                    limit: 5,
                    action: false,
                    props: (element) => {
                        return {
                            limit: 5,
                            tags: element.user && element.user.tags ? element.user.tags.map(tag => {return {tag: { label: tag, value: tag, name: tag }}}) : []
                        }
                    },
                    value: (element) => {
                        return element.user && element.user.tags ? element.user.tags.map(tag => {return {tag: { label: tag, value: tag, name: tag }}}) : []
                    },
                    filter: {
                        placeholder: 'Tags',
                        type: 'select',
                        allSelectedPlaceholder: 'All Tags'
                    }
                },
                {
                    name: 'cost-per-post',
                    slug: 'cost-per-post',
                    isNonStandard: false,
                    label: 'Total Cost',
                    type: 'price',
                    hidden: !this.isAdminOrHigher,
                    bold: (element) => { return element.cost.toFixed(2) },
                    fixed_amount: (element) => { return element.cost.toFixed(2) },
                    rowHelp: (element) => {
                        if (element.cost) {
                            return 'This amount is fixed for this user and it will be splitted by the deliverables quantity.';
                        }

                        return null;
                    },
                    value: (element) => element.cost.toFixed(2),
                    options: {
                        stopPropagation: true
                    },
                    // onClick: (element) => this.setAmount(element)
                },
                {
                    name: 'links',
                    slug: 'links',
                    component: 'networks',
                    label: 'Links',
                    isNonStandard: false,
                    action: false,
                    type: 'custom-component',
                    props: (element) => this.getNetworkProps(element),
                },
                {
                    name: 'deliverables',
                    slug: 'deliverables',
                    label: 'Deliverables',
                    isNonStandard: true,
                    type: 'deliverables',
                    columnClass: (element) => {
                        return 'flex justify-start';
                    },
                    options: {
                        stopPropagation: true
                    }
                },
                {
                    name: 'instagram-followers',
                    slug: 'instagram-followers',
                    label: 'Instagram Followers',
                    type: 'number',
                    isNonStandard: false,
                    value: (element) => {
                        return this.instagramFollowersValue(element.user)
                    }
                },
                {
                    name: 'tiktok-followers',
                    slug: 'tiktok-followers',
                    label: 'TikTok Followers',
                    type: 'number',
                    isNonStandard: false,
                    value: (element) => {
                        return this.tiktokFollowersValue(element.user)
                    }
                },
                {
                    name: 'youtube-subscribers',
                    slug: 'youtube-subscribers',
                    label: 'Youtube Subscribers',
                    type: 'number',
                    isNonStandard: false,
                    value: (element) => {
                        return this.youtubeFollowersValue(element.user)
                    }
                },
            ];
            // const filteredOutput = output;
            filteredOutput = output.map(column => {
                return {
                    ...column,
                    id: this.creatorColumns.find(cc => {
                        if (!column.isNonStandard && cc.slug === column.slug) {
                            return cc.id;
                        }
                    })?.id,
                };
            }).filter(fc => this.selectedCreatorColumns.some(obj => obj.creator_column_id === fc.id));
            
            finalOutput = filteredOutput;

            finalOutput.unshift({
                name: 'name',
                slug: 'name',
                required: true,
                isNonStandard: false,
                label: 'Creator',
                type: 'avatar-list',
                value: (element) => {
                    return [element.user]
                } 
            });

            project.columns.forEach((c, index) => {
                finalOutput.push({ 
                    type: 'custom',
                    isNonStandard: true,
                    name: `${c.name}-${c.id}`, 
                    label: c.name, 
                    id: c.id,
                    editable: true,
                    col_type: 'ProjectUserColumnName',
                    columnCustomEditableAction: (element) => this.setEditableField(element, c, 'ProjectUserColumnName'),
                    searchBy: function(element) {
                        return element.column_values.find( value => value.column_name_id == c.id)
                    },
                    searchByValueProperty: "data",
                    options: {
                        dropdown: {
                            options: this.isDeleted || this.isClient ? [] : this.getColumnOptions(index, project.columns),
                            actions: this.dropdownActions(c, index, 'ProjectUserColumnName')
                        },
                        inputOnBlur: (value, column, element) => this.saveColumnValue(value, column, element, 'ProjectUserColumnName')
                    }
                });
            });

            return finalOutput;
        },

        instagramFollowersValue(element) {
            return instagramFollowers(element);
        },

        tiktokFollowersValue(element) {
            return tiktokFollowers(element);
        },

        youtubeFollowersValue(element) {
            return youtubeFollowers(element);
        },

        getNetworkProps(element) {
            return {
                currentAccounts: element.user.social_accounts
                ? element.user.social_accounts
                .filter((network) => network.network)
                .map((network) => network.network_id)
                : [],
                passedNetworks: this.networks,
                size: "small",
                accounts: element.user.social_accounts
                ? element.user.social_accounts.filter((network) => network.network)
                : [],
                setAccounts: (accounts) => {
                    this.$emit("set-accounts", element, accounts);
                },
            };
        },

        setEditableField(element, parentObj, type) {

            const parent_id = element.parent_id;
            const isDeliverableColumn = type === "ProjectUserDeliverableColumnName";

            const updateColumnValues = (column_values) =>
            column_values.map(column_val => {
                const shouldEdit = column_val.column_name_id === element.column_name_id && parent_id === column_val.parent_id;
                return { ...column_val, edit: shouldEdit };
            });

            const updateRow = (row) => {
                const updatedColumnValues = updateColumnValues(row.column_values);
                if (isDeliverableColumn && row.user_deliverables && row.user_deliverables.rows.length > 0) {
                    const updatedUserDeliverables = {
                        ...row.user_deliverables,
                        rows: row.user_deliverables.rows.map(the_row => ({
                            ...the_row,
                            column_values: updateColumnValues(the_row.column_values),
                        })),
                    };
                    return { ...row, user_deliverables: updatedUserDeliverables, column_values: updatedColumnValues };
                }
                return { ...row, column_values: updatedColumnValues };
            };

            this.localRowData = this.localRowData.map(updateRow);
        },

        deliverableTableColumns(project_user) {
            const output = [
                { 
                    name: 'deliverable',
                    label: 'Deliverable',
                    type: 'text',
                    value: (element) => {
                        return element.name || element.type_label
                    } 
                },
                {
                    name: 'type',
                    label: 'Type',
                    type: 'text',
                    value: (element) => element.type_label,
                },
                {
                    name: 'cost',
                    label: 'Payment',
                    editable: this.isUserAdminOrHigher ? true : false,
                    type: 'editable-standard',
                    hidden: !this.isUserAdminOrHigher,
                    raw: (element) => { return element.editable_cost },
                    searchBy: function(element) {
                        return element.editable_cost.data;
                    },
                    options: {
                        stopPropagation: true,
                        inputOnBlur: (column, element) => this.saveStandardColumnValue(column, element, 'projectUserDeliverableValue', 'cost')
                    },
                    getDisplayValue: (element) => {
                        const rawValue = element.editable_cost.data;
                        return formatMoney(rawValue);
                    },
                },
                {
                    name: 'stage',
                    label: 'Stage',
                    type: 'dropdown',
                    options: (element) => { 
                        return { 
                            dropdown: { 

                                // We dynamically display options depending on the status which are sent from the backend statuses.js page
                                // this keeps things consistent throughout the app. It's essentially the source of truth.
                                options: this.isDeleted || this.isClient ? [] : element.phases_progress && element.phases_progress.options ? element.phases_progress.options : [],
                                actions: this.deliverableStatusDropDownActions(element)

                            }
                        }
                    },
                    columnClass: (element) => {
                        return 'float-left self-center';
                    },
                    containerClass: (element) => {
                        return 'flex items-center';
                    },
                    valueDropdown: true,
                    value: (element) => element.phases_progress && element.phases_progress.display_name ? ((element.phases_progress.display_name == 'Draft' || element.phases_progress.display_name == 'Concept') ? element.phases_progress.display_name + 'ing' : element.phases_progress.display_name) : '-'
                },
                
            ];

            this.project.project_user_deliverable_columns.forEach((puc, index) => {
                output.push({ 
                    type: 'custom',
                    name: puc.name,
                    label: puc.name,
                    editable: true,
                    id: puc.id,
                    col_type: 'ProjectUserDeliverableColumnName',
                    columnCustomEditableAction: (element) => this.setEditableField(element, puc, 'ProjectUserDeliverableColumnName'),
                    searchBy: function(element) {
                        return element.column_values.find( value => value.column_name_id == puc.id)
                    },
                    searchByValueProperty: "data",
                    options: {
                        dropdown: {
                            actions: this.dropdownActions(puc, index, 'ProjectUserDeliverableColumnName'),
                            options: this.isDeleted || this.isClient ? [] : this.getColumnOptions(index, this.project.project_user_deliverable_columns)
                        },
                        inputOnBlur: (value, column, element) => this.saveColumnValue(value, column, element, 'ProjectUserDeliverableColumnName')
                    }
                });
            });

            output.push({
                isHeader: true,
                name: 'add-menu',
                label: 'Add Menu',
                type: 'header-button-dropdown',
                options: (column) => {
                    return {
                        button: {
                            icon: 'plus'
                        },
                        openIndex: 102,
                        dropdown: {
                            options: this.isDeleted || this.isClient ? [] : this.projectAddDeliverableColumnOptions(column),
                            actions: this.deliverableLevelAddNewDropdownActions(column, project_user)
                        }
                    }
                }
                
            });

            // output.push({
            //     name: 'add-column',
            //     label: 'Add Column',
            //     type: 'header-button',
            //     options(column) {
            //         return {
            //             button: {
            //                 icon: 'plus'
            //             }
            //         };
            //     },
            //     onAddColumnClick: (column) => this.openAddColumn('ProjectUserDeliverableColumnName', project_user)
            // });

            output.push({
                name: 'empty',
                label: '',
                sort: false,
                type: 'empty'
            });

            return output;
        },

        async saveStandardColumnValue(column, element, type, fieldTarget) {
            
            element[`editable_${fieldTarget}`].edit = false;
            const cleanedValue = cleanMoney(column.raw(element).data);
            this.$set(element[`editable_${fieldTarget}`], 'data', cleanedValue);

            // update the appropriate column value for the user-deliverable id below

            let apiRoute = type;
            apiRoute += `/${element.id}`;

            // To ensure consistency, we need to check for naming mismatches between the table and the database.
            // We can continue adding to this list for any other mismatches. If a mismatch is not found in the following object,
            // we will attempt to save based on the raw argument value.

            const fieldMatch = {
                cost: "budget"
            };

            const dbField = (fieldMatch[fieldTarget]) ? fieldMatch[fieldTarget] : fieldTarget;
            const the_put_data = {};

            the_put_data[dbField] = cleanedValue;

            const { data } = await this.$http.put(`/api/userDeliverables/${apiRoute}`, the_put_data);

            this.$emit('update-deliverable', element);
            this.selectedProjectUser = this.project.rows.find(u => u.user_id === element.user_id);
            this.updateUserDeliverable(data);

        },

        async saveColumnValue(value, column, element, type) {

            value.edit = false;

            const isDeliverableColumn = (type == 'ProjectUserDeliverableColumnName') ? true : false;

            this.savingUserColumn = false;

            if (!this.savingUserColumn) {

                this.savingUserColumn = true;

                let the_post_data = isDeliverableColumn ? {
                    project_user_deliverable_id: value.parent_id,
                    project_user_deliverable_column_name_id: value.column_name_id,
                    data: value.data
                } : {
                    project_user_id: value.parent_id,
                    project_user_column_name_id: value.column_name_id,
                    data: value.data
                };

                const the_id = (value.id && typeof value.id != "undefined") ? value.id : "";

                let apiRoute = isDeliverableColumn ? 'projectUserDeliverableColumnValue' : 'projectUserColumnValue';
                apiRoute += `/${the_id}`;

                // create
                if (!the_id ) {
                    const { data } = await this.$http.post(`/api/userDeliverables/${apiRoute}`, the_post_data);

                    if (data) {
                        
                        this.$set(value, 'id', data.id);
                        this.$notify({ title: 'Data saved successfully', text: '', type: 'success' });
                        
                    } else {
                        this.$notify({ title: 'Unable to save data, try again later', text: '', type: 'error' });
                        value.edit = true;
                    }
                }

                // update
                else {
                    const { data } = await this.$http.put(`/api/userDeliverables/${apiRoute}`, the_post_data);
                    if (data) {
                        value = data;
                        this.$notify({ title: 'Data saved successfully', text: '', type: 'success' });
                    } else {
                        this.$notify({ title: 'Unable to save data, try again later', text: '', type: 'error' });
                        value.edit = true;
                    }
                }

                this.savingUserColumn = false;
            }

        },

        /****** 
        
        BEGINNING OF SPECIAL FUNCTIONS FOR DROPDOWN ACTIONS

        These functions are connected to the backend options being sent from statuses.js to keep things extremely consistent 

        *******/

        // Primary deliverable actions which are called via the dropdown menus - please note that we do not use $emit for this and instead
        // are passing in the functions in order to keep base table super clean and modular

        inviteCreatorAddConcept(currentUserDeliverable) {
            this.selectedUserDeliverable = currentUserDeliverable;
            this.showShareCollectionLinkModal = true;
            this.shareCollectionType = "Concept";
        },

        inviteCreatorAddDraft(currentUserDeliverable) {
            this.selectedUserDeliverable = currentUserDeliverable;
            this.showShareCollectionLinkModal = true;  
            this.shareCollectionType = "Draft";
        },

        inviteCreatorLinkPost(currentUserDeliverable) {
            this.selectedUserDeliverable = currentUserDeliverable;
            this.showShareCollectionLinkModal = true;
        },

        closeShareCollectionLinkModal() {
            this.selectedUserDeliverable = null;
            this.showShareCollectionLinkModal = false;
        },

        addConcept(currentUserDeliverable) {
            this.showCreateVersionModal = true; 
            this.currentUserDeliverableSubmissionType = 'concept';
            this.currentUserDeliverable = currentUserDeliverable;
        },

        addDraft(currentUserDeliverable) {
            this.showCreateVersionModal = true; 
            this.currentUserDeliverableSubmissionType = 'draft';
            this.currentUserDeliverable = currentUserDeliverable;
        },

        viewDeliverable(element) {
            this.openDeliverable(element);
        },

        async closeCreateVersionModal(data) {
            console.log(data);
            if (data !== undefined) {
                this.selectedProjectUser = this.project.rows.find(u => u.user_id === data.user_id);
                this.updateUserDeliverable(data);
            }
            this.showCreateVersionModal = false;
            this.currentUserDeliverableSubmissionType = null;
            this.currentUserDeliverable = null;
        },

        async closeLinkPostModal(userDeliverableToUpdate) {
            this.currentUserDeliverable = null;
            const { data } = await this.$http.get(`/api/userDeliverables/get-single-deliverable/${userDeliverableToUpdate.id}`);
            this.$set(userDeliverableToUpdate, 'phases_progress', data.phases_progress);
        },

        linkPost(currentUserDeliverable) {
            this.currentUserDeliverable = currentUserDeliverable;
            this.showLinkPostUserDeliverableModal = true;
        },

        viewDeliverableDraft(element) {
            this.openDeliverable(element, 'draft');
        },

        viewDeliverableConcept(element) {
            this.openDeliverable(element, 'concept');
        },

        viewPost(element) {
            this.openDeliverable(element, 'posts');
        },

        callFunction(functionName, arg) {
            // Call the function using the string name
            this[functionName](arg);
        },

        deliverableStatusDropDownActions(element) {
            let actions = {};

            if (element.phases_progress && Array.isArray(element.phases_progress.options)) {
                element.phases_progress.options.forEach(option => {
                    actions[option.event] = () => {
                        this.callFunction(hyphenToCamelCase(option.event), element);
                    };
                });
            }

            return actions;
        },

        /****** 
        
        END OF SPECIAL FUNCTIONS FOR DROPDOWN ACTIONS

        These functions are connected to the backend options being sent from statuses.js to keep things extremely consistent 

        *******/

        // Standard column functions

        editColumns(project) {
            this.selectedProjectId = project.id;
            this.editCreatorsColumnsModalVisible = true;
        },

        getColumnOptions(columnIndex, allColumns) {
            let options = [...this.standardColumnOptions];

            if (allColumns.length === 1) {
                options = options.filter(option => option.event !== 'move-left' && option.event !== 'move-right');
            } else {
                if (columnIndex === 0) {
                    options = options.filter(option => option.event !== 'move-left');
                } else if (columnIndex === allColumns.length - 1) {
                    options = options.filter(option => option.event !== 'move-right');
                }
            }

            return options;
        },

        dropdownActions(column, index, type) {
            const actions = {
                'move-right': () => {
                    this.moveColumn(column, index, 'right', type);
                },
                'move-left': () => {
                    this.moveColumn(column, index, 'left', type);
                },
                'delete': () => {
                    this.deleteColumn(column, this.project, type);
                },
                'edit': () => {
                    this.openEditColumn(column, type);
                },
            };

            return actions;
        },

        expandCollapse() {
            this.localExpanded = !this.localExpanded;
            
            for ( const row of this.localRowData ) {
                this.toggleShowUserDeliverables(row, {}, this.localExpanded);
            }

        },

        projectLevelAddNewDropdownActions(column) {
            const actions = {
                'add-creator': () => {
                    this.openAddCreator(column);
                },
                'add-column': () => {
                    this.openAddColumn('ProjectUserColumnName', column)
                },
                'edit-columns': () => {
                    this.editColumns(this.project);
                },
                'expand-collapse': () => {
                    this.expandCollapse();
                }
            };

            return actions;
        },

        deliverableLevelAddNewDropdownActions(column, project_user) {
            const actions = {
                'add-deliverable': () => {
                    this.openAddDeliverable(project_user);
                },
                'add-column': () => {
                    this.openAddColumn('ProjectUserDeliverableColumnName', column)
                },
                // 'edit-columns': () => {
                //     this.editColumns(this.project);
                // },
            };

            return actions;
        },

        openRemoveColumn(projectUser) {
            this.$swal.fire({
                title: `Are you sure you want to remove this creator?`,
                text: '',
                icon: 'warning',
                iconColor: '#0E092C',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                reverseButtons: true,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {                        
                        const { data } = await this.$http.post(`/api/projects/${projectUser.project_id}/remove-user`, {
                            userId: projectUser.user_id
                        });
                        if (data) {

                            // Find the index of the user in the rows array and store it for later use
                            const userIndex = this.localRowData.findIndex(row => row.user_id === projectUser.user_id);
                            console.log('userIndex', userIndex);
                            this.$store.dispatch('restorer/set', {
                                data: { userId: projectUser.user_id },
                                message: 'Creator removed from project successfully',
                                success: 'Creator restored successfully',
                                failed: 'Creator cannot be restored',
                                route: `/api/projects/${projectUser.project_id}/restore-user`,
                                action: data.show_undo,
                                fn: () => {
                                    if (userIndex !== -1) {
                                        this.localRowData.splice(userIndex, 0, projectUser);
                                        this.getCreators();
                                    }

                                    this.$emit('update-stats');
                                }
                            });

                            // Find the index of the user in the rows array and remove them
                            if (userIndex !== -1) {
                                this.localRowData.splice(userIndex, 1);
                                this.getCreators();
                            }

                            this.$emit('update-stats');

                        }
                    } catch(e) {
                        this.$notify({ title: 'Error', text: 'Something went wrong', type: 'error' });
                        console.error(e);
                    }
                }
            });
        },


        projectUserDropdownActions(element) {

            const actions = {
                'edit-creator': () => {
                    this.$router.push(`/creator/${element.user.id}/edit`);
                },
                'remove-creator': () => {
                    this.openRemoveColumn(element)
                },
                'add-deliverable': () => {
                    this.openAddDeliverable(element);
                },
            };

            return actions;
        },

        async userDeliverableOption(deliverable, option){
            let project = this.project;
            let project_user = project.rows.find(u => u.user_id === deliverable.user_id);
            let user = project_user.user;
            let data = null;
            let deliverableType = JSON.parse(JSON.stringify(deliverable.deliverable));  
            switch(option){
                case 'edit':
                    this.openEditDeliverable(project_user, deliverable, deliverableType);
                    break;
                case 'duplicate':
                    try{
                        data = await this.$http.post(`/api/userDeliverables/duplicate/${deliverable.id}`);
                        if (data.data) {
                            this.selectedProjectUser = project_user;
                            this.$notify({ title: 'Success', text: 'Deliverable duplicated successfully', type: 'success' });
                            this.updateUserDeliverable(data.data);
                        }
                    }catch(err){
                        notifyCatchError(err, this.$notify)
                    }
                    break;
                case 'delete':
                    this.$swal.fire({
                        title: `Are you sure you want to delete this deliverable?`,
                        text: '',
                        icon: 'warning',
                        iconColor: '#0E092C',
                        showCancelButton: true,
                        confirmButtonText: 'Delete',
                        reverseButtons: true,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.processDeleteDeliverable(deliverable, project, user);
                        }
                    });
                    break;
                default:
                    break;
            }
        },

        async processDeleteDeliverable(deliverable, project, user) {
            let row = this.localRowData.find(lcrd => lcrd.project_user_id == deliverable.project_user_id);
            if (row) {
                this.selectedProjectUser = deliverable;
                try{
                    const { data } = await this.$http.post(`/api/userDeliverables/delete/${deliverable.id}`);
                    if (data) {
                        this.removeFromDeliverables(deliverable);
                        this.$store.dispatch('restorer/set', {
                            message: 'Deliverable deleted successfully',
                            success: 'Deliverable restored successfully',
                            fail: 'Deliverable cannot be restored',
                            route: `/api/userDeliverables/restore/${deliverable.id}`,
                            action: data.show_undo,
                            receivePayload: true,
                            forceFn: false,
                            fn: async (data) => {
                                this.updateUserDeliverable(data);
                            }
                        });
                    }
                }catch(err){
                    notifyCatchError(err, this.$notify)
                }
            }
        },

        projectUserDeliverableDropdownActions(element, deliverable) {
            const actions = {
                'edit-deliverable': () => {
                    this.userDeliverableOption(deliverable, 'edit');
                },
                'remove-deliverable': () => {
                    this.userDeliverableOption(deliverable, 'delete');
                },
                'duplicate-deliverable': () => {
                    this.userDeliverableOption(deliverable, 'duplicate');
                }
            };

            return actions;
        },

        openAddCreator(column, index) {
            this.showAssignCreatorModal = true;
        },

        openCreator(element) {
            if(!this.isClient) this.$router.push(`/creator/${element.user_id}`);
        },

        openDeliverable(element, tab = null) {
            let link = `/deliverables/${element.id}`;
            link += ( tab ) ? `?tab=${tab.replace(/^\w/, c => c.toUpperCase())}` : '';

            this.$router.push(link);
        },

        collapseToggle(project){
            this.$set(project, 'collapsed', !project.collapsed);
            localStorage.setItem(`project_${project.id}_collapsed`, project.collapsed);
        },

        async editColumn(columnNameObj) {
            const {data} = await this.$http.put(`/api/userDeliverables/projectColumn/${columnNameObj.id}`, {name: columnNameObj.name, type: this.selectedColumnType});
            const columnNames = (this.selectedColumnType == 'ProjectUserColumnName') ? this.project.columns : this.project.project_user_deliverable_columns;
            let existingColumn = columnNames.find( column => column.id == columnNameObj.id );
            Object.assign(existingColumn, data);
            this.selectedColumn = null;
            this.selectedColumnType = null;
        },

        openEditColumn(column = null, type) {
            this.selectedColumnType = column.type;
            this.selectedColumn = column;
            this.showColumnModal = true;
        },

        openAddColumn(type, project_user = null) {
            this.selectedColumnType = type;
            this.selectedColumn = null;
            this.showColumnModal = true;
            this.selectedProjectUser = project_user;
        },    

        openAddDeliverable(project_user){
            this.selectedProjectUser = project_user;
            console.log('this.selectedProjectUser', this.selectedProjectUser);
            this.selectedUserDeliverable = null;
            this.showAddInfluencerDeliverableModal = true;
        },
        
        openEditDeliverable(project_user, deliverable, deliverableType){
            let item = {...deliverable};
            this.selectedProjectUser = project_user;

            this.selectedUserDeliverable = item;

            this.selectedUserDeliverable.deliverable = { id: deliverableType.id };
            this.selectedUserDeliverable.type = { value: item.type };
            this.selectedUserDeliverable.budget = item.cost;

            this.showAddInfluencerDeliverableModal = true;
        },

        resetAddDeliverableModal() {
            this.showAddInfluencerDeliverableModal = false;
            this.selectedProjectUser = null;
            this.selectedUserDeliverable = null;
        },

        async removeFromDeliverables(deliverable) {
            const matchedRow = this.localRowData.find(lcrd => lcrd.project_user_id === deliverable.project_user_id);

            if (matchedRow.user_deliverables && matchedRow.user_deliverables.rows) {
                let pos = matchedRow.user_deliverables.rows.findIndex(r => r.id == deliverable.id);
                if (pos > -1) {
                    matchedRow.user_deliverables.rows.splice(pos, 1);
                }

                // Use the $set method to push the new item and trigger reactivity
                matchedRow.user_deliverables.rows = this.prepareRows(matchedRow.user_deliverables.rows);
                this.$forceUpdate();
            }
        },

        async updateUserDeliverable(data) {
            const new_user_deliverable = {
                column_values: [],
                ...data,
                editable_cost: {
                    data: data.cost,
                    edit: false,
                    target: "cost"
                },
                options: (element) => {
                    return {
                        dropdown: {
                            options: this.isDeleted || this.isClient ? [] : this.userDeliverableOptions,
                            actions: this.projectUserDeliverableDropdownActions(element, data)
                        }
                    }
                }
            };

            const matchedRow = this.localRowData.find(lcrd => lcrd.project_user_id === this.selectedProjectUser.project_user_id);

            if (matchedRow.user_deliverables && matchedRow.user_deliverables.rows) {
                let array_length = matchedRow.user_deliverables.rows.length;
                let pos = matchedRow.user_deliverables.rows.findIndex(r => r.id == new_user_deliverable.id);
                if (pos < 0) {
                    pos = array_length;
                }

                // Use the $set method to push the new item and trigger reactivity
                this.$set(matchedRow.user_deliverables.rows, pos, new_user_deliverable);
                matchedRow.user_deliverables.rows = this.prepareRows(matchedRow.user_deliverables.rows);
                this.$forceUpdate();
            } else {
                this.$set(matchedRow, 'user_deliverables', {rows: []});
                matchedRow.user_deliverables.rows.push(new_user_deliverable);
                console.log('heretfg', matchedRow.user_deliverables);
            }

            this.resetAddDeliverableModal();
        },  

        async addColumn(newColumn, parentObj) {

            const columnNames = (this.selectedColumnType == 'ProjectUserColumnName') ? this.project.columns : this.project.project_user_deliverable_columns;
            newColumn.position = columnNames.length ? columnNames[(columnNames.length - 1)].position + 1 : 1;
            const {data} = await this.$http.post('/api/userDeliverables/projectColumn', {...newColumn, project_id: this.project.id, type: this.selectedColumnType});
            columnNames.push(data);
            const template = {
                column_name_id: data.id,
                data: "",
                edit: false,
                position: data.position,
                type: data.column_type_id
            };

            for (var row of this.localRowData) {
                if (this.selectedColumnType == 'ProjectUserColumnName') {
                    template.parent_id = row.project_user_id;
                    row.column_values.push({ ...template });
                } else {
                    if (row.user_deliverables && row.user_deliverables.rows.length > 0) {
                        for (var ud_row of row.user_deliverables.rows) {
                            const updatedTemplate = { ...template };
                            updatedTemplate.parent_id = ud_row.id;
                            ud_row.column_values.push(updatedTemplate);
                        }
                    }
                }
            }

            this.selectedProjectUser = null;
        }, 
        
        async updateColumnPositions(type) {

            const columns = (type == 'ProjectUserColumnName') ? this.project.columns : this.project.project_user_deliverable_columns;

            const updatedColumns = [];

            for (let i = 0; i < columns.length; i++) {
                const column = columns[i];
                if (column.position !== i) {
                    column.position = i + 1;
                    updatedColumns.push({ id: column.id, position: i });
                }
            }

            if (updatedColumns.length > 0) {
                
                // Send a batch update request to the server
                await this.$http.put('/api/userDeliverables/projectColumns/batchUpdate', {
                  columns: updatedColumns.map(column => ({ ...column, position: column.position + 1 })),
                  type: type
                });

                // Update the local state after the successful update
                for (const updatedColumn of updatedColumns) {
                    const columnToUpdate = this.project.columns.find(column => column.id === updatedColumn.id);
                    if (columnToUpdate) {
                        columnToUpdate.position = updatedColumn.position;
                    }
                }
            }
        },

        async moveColumn(columnName, columnIndex, direction, type) {

            const col_type = columnName.type;
            const columns = (type == 'ProjectUserColumnName') ? this.project.columns : this.project.project_user_deliverable_columns;

            let targetIndex = -1;

            if (direction === 'right' && columnIndex < columns.length - 1) {
                targetIndex = columnIndex + 1;
            } else if (direction === 'left' && columnIndex > 0) {
                targetIndex = columnIndex - 1;
            }

            if (targetIndex !== -1) {
                const firstColumnId = columns[columnIndex].id;
                const firstColumnPosition = columns[columnIndex].position;
                const secondColumnId = columns[targetIndex].id;
                const secondColumnPosition = columns[targetIndex].position;

                // Swap positions
                columns[columnIndex].position = secondColumnPosition;
                columns[targetIndex].position = firstColumnPosition;

                // Save new positions to the API
                await this.$http.put(`/api/userDeliverables/projectColumn/${firstColumnId}`, { position: secondColumnPosition, type: col_type });
                await this.$http.put(`/api/userDeliverables/projectColumn/${secondColumnId}`, { position: firstColumnPosition, type: col_type });

                // Sort the columns based on the new positions
                const sortedColumns = columns.sort((a, b) => a.position - b.position);

                // Update the appropriate property in `this` based on the selectedColumnType
                if (col_type == 'ProjectUserColumnName') {
                    this.project.columns = sortedColumns;
                } else {
                    this.project.project_user_deliverable_columns = sortedColumns;
                    // this.rowData.find(row => row.project_user_id == columnName.project_user_id).user_deliverables.columns = sortedColumns;
                }
                this.$forceUpdate();
            }
        },

        async undoDeleteColumn() {
            if (this.deletedColumn) {

                const col_type = this.deletedColumn.type;
                const columns = (col_type == 'ProjectUserColumnName') ? this.project.columns : this.project.project_user_deliverable_columns;
                
                // Find the first column with position equal to or greater than the deleted column's position
                let targetIndex = columns.findIndex(column => column.position >= this.deletedColumn.position);

                // If targetIndex is -1, it means the deleted column should be inserted at the end
                if (targetIndex === -1) {
                    targetIndex = columns.length;
                }

                // Insert the deleted column back into the columns array at the target index
                columns.splice(targetIndex, 0, this.deletedColumn);

                // Update the positions of the columns after the target index
                for (let i = targetIndex + 1; i < columns.length; i++) {
                    columns[i].position = i;
                }

                // This is buggy and is only necessary if we want to make sure the column gets added back to the exact location as before
                // skipping debugging this code until later if it becomes something we have to have
                // i will leave here as a reference in the meantime

                // Send a batch update request to the server to update the positions of affected columns
                // const updatedColumns = this.project.columns.slice(targetIndex + 1).map(column => ({ id: column.id, position: column.position }));
                // if (updatedColumns.length > 0) {
                //     await this.$http.put('/api/userDeliverables/projectColumns/batchUpdate', { columns: updatedColumns });
                // }

                // Clear the deletedColumn data
                this.deletedColumn = null;
            }
        },

        async reverseUpdateColumnPositions() {
            const updatedColumns = [];

            const columnsToReorder = this.project.columns.filter(column => column.position >= this.deletedColumn.position);

            // Sort columns by position in descending order to ensure that the deletedColumn takes precedence
            const sortedColumns = columnsToReorder.sort((a, b) => b.position - a.position);

            for (let column of sortedColumns) {
                column.position = column.position + 1;
                updatedColumns.push({ id: column.id, position: column.position });
            }

            if (updatedColumns.length > 0) {
                // Send a batch update request to the server
                await this.$http.put('/api/userDeliverables/projectColumns/batchUpdate', { columns: updatedColumns });

                // Update the local state after the successful update
                this.project.columns = this.project.columns.map(column => {
                    const updatedColumn = updatedColumns.find(updated => updated.id === column.id);
                    if (updatedColumn) {
                        column.position = updatedColumn.position;
                    }
                    return column;
                });
            }
        },

        async deleteColumn(column, project) {
            const col_type = column.type;
            const columns = (col_type == 'ProjectUserColumnName') ? this.project.columns : this.project.project_user_deliverable_columns;

            // Store the deleted column data temporarily
            this.deletedColumn = column;

            this.$swal.fire({
                title: `Are you sure you want to delete the column?`,
                text: '',
                icon: 'warning',
                iconColor: '#0E092C',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                reverseButtons: true,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const { data } = await this.$http.delete(`/api/userDeliverables/projectColumn/${col_type}/${column.id}`);

                    if (data) {

                        this.$store.dispatch('restorer/set', {
                            resourceLabel: 'Column',
                            route: `/api/userDeliverables/projectColumn/${col_type}/${column.id}/restore`,
                            action: data.show_undo,
                            onClose: () => {
                                // Clear the deletedColumn data when the Swal is closed
                                this.deletedColumn = null;
                            },
                            fn: () => {
                                this.undoDeleteColumn();

                                /*
                                
                                or I can refetch the project columns and $set them to always ensure they're 
                                in order (since the backend is flawless with this logic right now) 
                                
                                */
                            }
                        });

                        // Remove column from the nested array
                        const columnIndex = columns.findIndex(column_ => column_.id === column.id);
                        
                        if (columnIndex !== -1) {
                            columns.splice(columnIndex, 1);
                        }

                        // Update positions of the remaining columns
                        await this.updateColumnPositions(col_type);

                    }
                }
            });
        }

    }
}
</script>

<style lang="scss">

.base-table-body:hover {
  border: 1px solid #ccc;
}

</style>
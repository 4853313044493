<template>
    <div class="bg-tan-m creator-report-wrapper flex justify-center">
        <div class="left-bar">
            <ul role="list" class="divide-y divide-gray-100">
                <li
                    v-for="(creator, i) in creators"
                    :key="i"
                    :class="['creator-list-item', { active: selectedCreator === i }]"
                    @click="selectedCreator = selectedCreator === i ? null : i"
                >
                    <img class="h-12 w-12 flex-none rounded-full bg-gray-50" :src="getCreatorAttribute(creator, 'avatar')" alt="" />
                    <div class="min-w-0">
                        <p class="text-sm font-semibold leading-6 text-gray-900">{{ getCreatorAttribute(creator, "name") }}</p>
                        <p class="mt-1 truncate text-xs leading-5 text-gray-500">
                            {{ creatorSocialAccounts(creator) }}
                        </p>
                    </div>
                </li>
            </ul>
        </div>
        <div class="container">
            <div class="pl-12">
                <h1>{{ report.project_name }}</h1>
                <h4>{{ report.name }}</h4>
            </div>
            <div class="flex space-x-4 mt-8 justify-center">
                <div v-for="(stat, i) in statCols" :key="i" class="py-4 bg-white flex flex-col justify-center items-center w-40 rounded mb-4">
                    <div class="inline-flex items-center justify-center rounded-full bg-purple-m-main flex-shrink-0 flex-grow-0 w-14 h-14">
                        <div class="text-green-m-main inline-flex justify-center items-center h-7 w-7 relative">
                            <div class="flex justify-center items-center z-0 h-7 w-7" v-html="stat.icon"></div>
                        </div>
                    </div>
                    <div class="flex flex-col items-center justify-center mt-4">
                        <div class="text-3xl uppercase font-extrabold">
                            {{ i === "reach" ? totalReach : engagements[i] | numeral("0.0a") }}
                        </div>
                        <div class="text-h6 text-gray-400">{{ stat.label }}</div>
                        <!-- <div class="mt-4"><bars-chart :item="{ name: 'testing', value: 5000, weight: '0.351' }" /></div> -->
                    </div>
                </div>
            </div>

            <div class="mt-8 mx-12 tabbed-view">
                <div class="px-4 sm:px-0">
                    <tabs :tabs="tabItems" v-model="currentTab" capitalize custom-class="ml-12 sm:ml-8" />
                    <!-- <h3 class="text-base font-semibold leading-7 text-gray-900">Additional Information</h3> -->
                    <!-- <p class="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Personal details and application.</p> -->
                </div>

                <div class="media-row" v-if="currentTab === 'Media'">
                    <div class="media-item taller" v-for="(post, i) in filteredPosts" :key="i" @click="showPost(i)">
                        <div class="media-wrapper rounded">
                            <template v-if="post.video">
                                <video style="position: absolute; top: 0; z-index: 10;" :src="post.video" autoplay muted loop />
                                <div class="media-img" style="top: 0; z-index: 1;"><img :src="post.display_url" /></div>
                            </template>
                            <template v-else>
                                <div class="photo-bg" :style="{ 'background-image': `url(${post.display_url})` }"></div>
                                <div class="media-img"><img :src="post.display_url" /></div>
                            </template>
                            <div class="stats space-x-4">
                                <span class="flex items-center"><base-icon name="like" class="mr-1" /> {{ post.likes | numeral("0a") }}</span>
                                <span class="flex items-center"><base-icon name="comments" class="mr-1" /> {{ post.comments | numeral("0a") }}</span>
                                <span class="flex items-center" v-if="post.views && post.views > 0"
                                    ><base-icon name="views" class="mr-1" /> {{ post.views | numeral("0a") }}</span
                                >
                            </div>
                        </div>
                    </div>
                </div>

                <div class="audience-data-wrapper" v-if="currentTab === 'Audience Data'">
                    <div class="flex items-center space-x-12 py-10">
                        <!-- Gender Chart -->
                        <gender-chart :data="compiledAudienceData.gender" v-if="compiledAudienceData.gender" />

                        <!-- Gender Age Chart -->
                        <gender-age-chart :data="compiledAudienceData.ages" :age-only="true" v-if="compiledAudienceData.ages" />

                        <div class="w-1/2" v-if="compiledAudienceData.cities">
                            <chart-options
                                titles="{ audience_geo: 'Location', cities: 'Cities' states: 'States', countries: 'Countries' }"
                                :hide-options-all="['audience_geo']"
                                :category="{ cities: true, states: true, countries: true }"
                                :index="'audience_geo'"
                                :follower-audience="{ audience_geo: { cities: compiledAudienceData.cities, countries: compiledAudienceData.countries } }"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <post-details-modal
            :visible="postDetails.visible"
            :post="posts[postDetails.postIndex]"
            :existing-posts="[]"
            @close="postDetails.visible = false"
            @edited-post="updateEditedPost"
            :can-edit="canEdit"
            :hide-post-swap="true"
        />
    </div>
</template>
<script>
import PostDetailsModal from "./PostDetailsModal";
import Tabs from "@/components/Tabs";
import GenderChart from "@/components/_charts/GenderChart";
import GenderAgeChart from "@/components/_charts/GenderAgeChart";

const arrayMerge = (arr1 = [], arr2 = [], keyName = "", valueName = "") => {
    // console.log("mergining", arr1, arr2);
    for (let i in arr1) {
        const matchedItemIndex = arr2.findIndex((k) => k[keyName] === arr1[i][keyName]);
        if (matchedItemIndex !== -1) {
            arr2[matchedItemIndex][valueName] += arr1[i][valueName];
        } else {
            arr2.push(arr1[i]);
        }
    }
    return arr2;
};

export default {
    components: {
        PostDetailsModal,
        Tabs,
        GenderChart,
        GenderAgeChart,
    },

    props: ["report", "networks", "canEdit"],

    data() {
        return {
            postDetails: {
                visible: false,
                postIndex: null,
            },

            selectedCreator: null,
            currentTab: "Media",
            audienceData: [],
        };
    },

    mounted() {
        this.getAudienceData();
    },

    watch: {
        currentTab(val) {
            if (val === "Audience Data" && this.audienceData.length === 0) {
                this.getAudienceData();
            }
        },

        selectedCreator() {
            this.currentTab = "Media";
        },
    },

    computed: {
        tabItems() {
            if (this.selectedCreator && Object.keys(this.compiledAudienceData).length === 0) {
                return ["Media"];
            }

            return ["Media", "Audience Data"];
        },

        posts() {
            return [...this.report.posts]
                .sort((a, b) => (new Date(a.date).getTime() > new Date(b.date).getTime() ? -1 : 1))
                .map((p) => {
                    const socialAccount = p.user.social_accounts.find((s) => s.network_id === p.network_id);
                    p.post_social_account = socialAccount;

                    if (p.is_video) {
                        const video = p.media.find((m) => m.mimetype === "video/mp4");
                        if (video) {
                            p.video = video.url;
                        }
                    }

                    return p;
                });
        },

        filteredPosts() {
            if (this.selectedCreator) {
                const handles = this.creators[this.selectedCreator].map((c) => c.post_social_account.handle.toLowerCase());

                return this.posts.filter((p) => {
                    return handles.includes(p.post_social_account.handle.toLowerCase());
                });
            }

            return this.posts;
        },

        creators() {
            const creators = this.posts
                .map((p) => ({ ...p.user, post_profile: p.profile_item, post_social_account: p.post_social_account }))
                .reduce((prev, curr) => {
                    const handle = curr.post_social_account.handle.toLowerCase().replace(/ /gi, "");
                    if (!prev[handle]) {
                        prev[handle] = [];
                    }

                    prev[handle].push(curr);
                    return prev;
                }, {});

            return creators;
        },

        totalReach() {
            if (this.filteredPosts.length === 0) {
                return 0;
            }

            return this.filteredPosts.reduce((prev, curr) => {
                if (curr.profile_item?.total_followers) {
                    prev += parseFloat(curr.profile_item?.total_followers);
                } else if (curr.post_social_account?.reach) {
                    prev += parseFloat(curr.post_social_account.reach);
                }
                return prev;
            }, 0);
        },

        engagements() {
            const totals = {
                comments: 0,
                likes: 0,
                shares: 0,
                views: 0,
                saves: 0,
            };

            return this.filteredPosts.reduce((prev, curr) => {
                Object.keys(totals).forEach((t) => (prev[t] += curr[t]));
                return prev;
            }, totals);
        },

        engagementPercent() {
            const totalEngagements = Object.values(this.engagements).reduce((prev, curr) => (prev += curr), 0) - this.engagements.views;
            return totalEngagements / this.totalReach;
        },

        statCols() {
            return {
                reach: {
                    label: "Total Reach",
                    icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" class="h-full w-full">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"></path>
                        </svg>`,
                },
                comments: {
                    label: "Comments",
                    icon: `<svg width="21" height="20" viewBox="0 0 21 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg" class="h-full w-full"><path d="M10.4999 0.373047C4.77625 0.373047 0.11792 4.20985 0.11792 8.92235C0.134889 10.0567 0.400809 11.1734 0.896882 12.1936C1.39296 13.2139 2.10707 14.1127 2.98875 14.8265L0.912364 18.9703C0.869716 19.0536 0.854605 19.1483 0.869205 19.2408C0.883805 19.3333 0.927365 19.4187 0.993614 19.4849C1.05944 19.5514 1.14433 19.5958 1.23652 19.612C1.32871 19.6282 1.42365 19.6153 1.5082 19.5751L7.06028 16.9932C8.17749 17.3198 9.33589 17.484 10.4999 17.4807C16.2235 17.4807 20.8818 13.6439 20.8818 8.92235C20.8818 4.20082 16.2235 0.373047 10.4999 0.373047Z" fill="currentColor"></path></svg>`,
                },
                likes: {
                    label: "Likes",
                    icon: `<svg width="21" height="19" viewBox="0 0 21 19" fill="currentColor" xmlns="http://www.w3.org/2000/svg" class="h-full w-full"><path d="M20.3882 3.13438C19.9781 2.33233 19.3854 1.63786 18.6577 1.10696C17.9299 0.576069 17.0877 0.22361 16.1987 0.0780089C15.3098 -0.0675926 14.3991 -0.00226221 13.54 0.268732C12.681 0.539726 11.8976 1.0088 11.2532 1.63813L10.5007 2.32063L9.77441 1.65563C9.12941 1.01634 8.34133 0.539992 7.47548 0.266086C6.60964 -0.00781862 5.69098 -0.0713949 4.79566 0.0806282C3.90537 0.218843 3.06111 0.56819 2.33347 1.09946C1.60583 1.63073 1.01595 2.32848 0.613156 3.13438C0.0711623 4.19326 -0.118429 5.39758 0.0720214 6.57176C0.262472 7.74595 0.823008 8.8286 1.67191 9.66188L9.87066 18.1144C9.95228 18.1991 10.0501 18.2664 10.1584 18.3124C10.2666 18.3584 10.383 18.3821 10.5007 18.3821C10.6183 18.3821 10.7347 18.3584 10.8429 18.3124C10.9512 18.2664 11.049 18.1991 11.1307 18.1144L19.3207 9.67938C20.1724 8.84435 20.7354 7.75915 20.9274 6.58188C21.1194 5.40461 20.9305 4.19679 20.3882 3.13438Z" fill="currentColor"></path></svg>`,
                },
                shares: {
                    label: "Shares",
                    icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-full w-full"><path d="M3.478 2.405a.75.75 0 00-.926.94l2.432 7.905H13.5a.75.75 0 010 1.5H4.984l-2.432 7.905a.75.75 0 00.926.94 60.519 60.519 0 0018.445-8.986.75.75 0 000-1.218A60.517 60.517 0 003.478 2.405z"></path></svg>`,
                },
                views: {
                    label: "Views",
                    icon: `<svg width="28" height="18" viewBox="0 0 28 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg" class="h-full w-full"><path d="M27.3353 7.27368C24.2553 3.87868 19.1686 0.273676 14.0003 0.367009C8.83194 0.460342 3.74527 3.87868 0.665274 7.27368C0.227478 7.75134 -0.0153809 8.37574 -0.0153809 9.02368C-0.0153809 9.67161 0.227478 10.296 0.665274 10.7737C2.71861 12.9903 7.86361 17.6337 14.0003 17.6337C20.1369 17.6337 25.2703 12.967 27.3353 10.7153C27.7568 10.2412 27.9896 9.6289 27.9896 8.99451C27.9896 8.36012 27.7568 7.7478 27.3353 7.27368ZM8.63361 9.00034C8.63361 7.93892 8.94836 6.90133 9.53805 6.01878C10.1277 5.13624 10.9659 4.44838 11.9465 4.04219C12.9272 3.636 14.0062 3.52972 15.0473 3.73679C16.0883 3.94387 17.0445 4.45499 17.7951 5.20554C18.5456 5.95608 19.0567 6.91233 19.2638 7.95336C19.4709 8.99439 19.3646 10.0734 18.9584 11.0541C18.5522 12.0347 17.8644 12.8729 16.9818 13.4626C16.0993 14.0523 15.0617 14.367 14.0003 14.367C12.5769 14.367 11.2119 13.8016 10.2055 12.7951C9.19902 11.7887 8.63361 10.4237 8.63361 9.00034Z" fill="currentColor"></path><path d="M14.0001 11.3346C15.2887 11.3346 16.3334 10.29 16.3334 9.0013C16.3334 7.71264 15.2887 6.66797 14.0001 6.66797C12.7114 6.66797 11.6667 7.71264 11.6667 9.0013C11.6667 10.29 12.7114 11.3346 14.0001 11.3346Z" fill="currentColor"></path></svg>`,
                },
                saves: {
                    label: "Saves",
                    icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-full w-full"><path fill-rule="evenodd" d="M6.32 2.577a49.255 49.255 0 0111.36 0c1.497.174 2.57 1.46 2.57 2.93V21a.75.75 0 01-1.085.67L12 18.089l-7.165 3.583A.75.75 0 013.75 21V5.507c0-1.47 1.073-2.756 2.57-2.93z" clip-rule="evenodd"></path></svg>`,
                },
            };
        },

        compiledAudienceData() {
            const audiences = [...this.audienceData]
                .filter((a) => {
                    if (this.selectedCreator) {
                        const userIds = this.creators[this.selectedCreator].map((c) => parseInt(c.id));
                        return a.data && userIds.includes(parseInt(a.user_id));
                    }

                    return a.data;
                })
                .map((a) => a.data);

            if (audiences.length === 0) {
                return {};
            }

            const compiledAudiences = audiences.reduce(
                (prev, curr) => {
                    prev.cities = arrayMerge(curr.audience_geo.cities, prev.cities, "name", "value");
                    prev.countries = arrayMerge(curr.audience_geo.countries, prev.countries, "name", "value");
                    prev.ages = arrayMerge(curr.audience_ages, prev.ages, "name", "value");
                    prev.gender = arrayMerge(curr.audience_genders, prev.gender, "name", "value");

                    // recalculate weights since we compiled multiple audiences
                    const totals = {
                        cities: prev.cities.reduce((prev, curr) => (prev += curr.value), 0),
                        countries: prev.countries.reduce((prev, curr) => (prev += curr.value), 0),
                        ages: prev.ages.reduce((prev, curr) => (prev += curr.value), 0),
                        gender: prev.gender.reduce((prev, curr) => (prev += curr.value), 0),
                    };

                    prev.cities = prev.cities.map((c) => ({ ...c, weight: c.value / totals.cities }));
                    prev.countries = prev.countries.map((c) => ({ ...c, weight: c.value / totals.countries }));
                    prev.ages = prev.ages.map((c) => ({ ...c, weight: c.value / totals.ages }));
                    prev.gender = prev.gender.map((c) => ({ ...c, weight: c.value / totals.gender }));

                    return prev;
                },
                { cities: [], countries: [], ages: [], gender: [] }
            );

            return compiledAudiences;
        },
    },

    methods: {
        showPost(postIndex) {
            this.postDetails.postIndex = postIndex;
            this.postDetails.visible = true;
        },

        updateEditedPost(payload) {
            this.userPosts[this.postDetails.postIndex] = payload;
        },

        creatorAvatar(profiles) {
            return profiles.map((p) => p.avatar || p.post_social_account.avatar || p.post_profile.profile_picture_url).find((a) => a);
        },

        creatorSocialAccounts(creator) {
            return creator.map((c) => this.networks[c.post_social_account.network_id].label).join(", ");
        },

        getCreatorAttribute(profiles, attribute) {
            switch (attribute) {
                case "avatar":
                    return profiles.map((p) => p.avatar || p.post_social_account.avatar || p.post_profile.profile_picture_url).find((a) => a);
                case "name":
                    return profiles.filter((p) => p.first_name || p.last_name).map((p) => [p.first_name, p.last_name].join(" "))[0];
            }
        },

        async getAudienceData() {
            const { data } = await this.$http.get(`/api/reports/v2/${this.$route.params.id}/audience`);
            this.audienceData = data;
        },
    },
};
</script>

<template>
   <div class="w-full text-sm" :class="[isNested ? '' : 'pt-6 pb-3', border ? 'border rounded-2xl' : '']">
        <slot name="table-header"></slot>
        <!-- Filters -->
        <div class="pb-4 flex lg:gap-4 sm:gap-1 sm:items-center sm:justify-between" :class="searchClass" v-if="search && (data.length || hasExternalSrc)" :key="refreshKey">

            <!-- searchbar  -->
            <div class="flex-shrink w-full" :style="searchbarStyles">
                <div class="relative flex w-full">
                    <div class="absolute w-16 h-full flex items-center justify-center pointer-events-none">
                        <base-icon name="search" class="text-purple-m-secondary" />
                    </div>
                    <FormulateInput
                        type="text"
                        v-model="filters.search.selected"
                        v-if="filters && filters.search"
                        placeholder="Search"
                        outer-class="w-full"
                        element-class="w-full"
                        :disabled="loading"
                        wrapper-class="border rounded-md py-2 px-1 pl-16 bg-white h-16 flex items-center"
                        v-debounce:1000="setFilter"
                    />
                </div>
            </div>
            
            <!-- all other filters -->
            <template v-for="(filter, index) in filters">
                <div v-if="filter.type === 'select'" :key="filter.type + '_' + index" class="flex-grown w-full">
                    <custom-select
                        class="w-full"
                        :label="filter.placeholder"
                        :placeholder="filter.placeholder"
                        :options="filter.options"
                        v-model="filter.selected"
                        prevent-mounted-emit
                        :key="'c_s_' + index"
                        @input="setFilter"
                    />
                </div>
            </template>

            <div class="flex justify-between w-full" v-if="filters.length <= 1 && (filtering || search)">
                <base-button @action="cleanFilters()" type="label" label="Clear"/>
            </div>

        </div>
        
        <slot name="filters-footer"></slot>

        <slot name="tabs-section"></slot>

        <div v-if="!collapsed" :class="computedContainerClass" @scroll="onScroll" class="relative" :style="containerStyles">
            
            <loader :visible="reload" fixed :opacity="50"/>
            <!-- Loading State -->
            <table class="bg-white" :class="tableClass" v-if="loading">
                <tbody>
                    <tr v-for="i in 10" :key="'sk' + i" class="h-15">
                        <td>
                            <div class="grid justify-items-center">
                                <skeleton :width="5"/>
                            </div>
                        </td>
                        <td class="px-1 py-6">
                            <div class="flex items-center space-x-3 lg:pl-2">
                                <skeleton :full-width="true"/>
                            </div>
                        </td>
                        <td class="px-1 py-6">
                            <div class="flex items-center space-x-3 lg:pl-2">
                                <skeleton :full-width="true"/>
                            </div>
                        </td>
                        <td class="px-1 py-6">
                            <div class="flex items-center space-x-3 lg:pl-2">
                                <skeleton :full-width="true"/>
                            </div>
                        </td>
                        <td class="px-1 py-6">
                            <div class="flex items-center space-x-3 lg:pl-2">
                                <skeleton :full-width="true"/>
                            </div>
                        </td>
                        <td>
                            <div class="grid justify-items-center">
                                <skeleton type="circle" :width="5"/>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <draggable style="padding: 0; margin: 0;" :class="tableClass" tag="table" :id="tableId" :disabled="!isDraggable" handle=".handle" v-model="filteredRows" v-else>
                
                <!-- Head -->
                <thead slot="header" v-if="!hideHeaders">
                    <!-- table head row -->

                    <tr :data-ref="tableHeaderRef" :data-header-index="headerIndex" class="forced-white border-gray-m-light text-gray-headers bg-white" :class="`${isScrolledX ? 'shadow-md' : ''} ${isNested ? '' : 'outerTableHeader'} ${stickyHeader ? 'sticky z-skip-1 top-0' : ''}`">
                        <!-- selection checkbox -->
                        <th class="pb-3" v-if="bulkActionsEnabled" v-bind="getColumnStyle(0)">
                            <div class="flex justify-center items-center h-6 w-6 mx-3" style="margin-top: 3px">

                                <!-- This type of checkbox only appears when there is one or more elements selected
                                but not all filtered rows are selected yet -->
                                <base-button
                                    v-if="(selectedElements.length > 0) && !allFilteredRowsSelected"
                                    @action="addAllToSelectedElements"
                                    type="label"
                                    icon="minus-sm"
                                    class="bg-gray-400"
                                    style="color: white"
                                />

                                <!-- The normal checkbox appears when there are no elements selected or when all
                                filtered rows are selected, and its only checked when all filtered rows are selected  -->
                                <div class="">
                                    <input
                                        v-if="(selectedElements.length == 0) || allFilteredRowsSelected"
                                        class="focus:ring-gray-500 h-4 w-4 text-gray-600 border-gray-300"
                                        type="checkbox"
                                        :checked="allFilteredRowsSelected"
                                        @click="toggleSelectedElements"
                                    >
                                    <FormulateInput
                                        type="checkbox"
                                        v-if="(selectedElements.length == 0) || allFilteredRowsSelected"
                                        :checked="allFilteredRowsSelected"
                                        @click="toggleSelectedElements"
                                    />
                                </div>

                            </div>
                        </th>
                        <!-- normal state shows all column names -->
                        <template v-else>
                            <template v-for="(column, index) in columns.filter(c => c.type !== 'empty')">
                                <th
                                :colspan="index < columns.filter(c => c.type !== 'empty').length - 1 ? 1 : 2"
                                v-if="column.type != 'empty' && column.type != 'header-button' && column.type != 'header-button-dropdown' && column.type != 'expand-all-sub-tables' && column.type != 'edit-columns' && !column.hidden"
                                :key="`col_${index}_${column.name}`"
                                :class="`pb-3 ${column.type == 'empty' ? 'w-10' : ''} ${sorting && !(column.sort == false) ? 'cursor-pointer' : ''} px-1 py-1 ${column.hidden == true || column.header_hidden == true ? `hidden` : ``} ${index === 0 ? 'pl-6 fixed-column' : 'pl-0'} ${index === hoveredIndex ? '' : 'fixed-column-base'} ${isScrolled ? 'shadow-right' : ''}`"
                                :style="[column.options && column.options.thStyles ? column.options.thStyles : {}]"
                                v-bind="getColumnStyle(index)"
                                @click="() => column.sort != false && (typeof column.options === 'undefined') || (typeof column.options.dropdown === 'undefined') ? setSort(column) : null"
                                >
                                    <div class="flex items-center gap-x-2">
                                        <div class="text-left font-bold text-h6 overflow-hidden whitespace-no-wrap">
                                            {{ column.label }}
                                            <span class="text-xs font-normal" v-if="column.help">{{ column.help }}</span>
                                        </div>
                                        <template>
                                            <Sort
                                                v-if="column.sort != false && column.label !== '' && sorting && (typeof column.options === 'undefined') || (column.options && typeof column.options.dropdown === 'undefined')"
                                                :sort="sort"
                                                :col="column.name"
                                            />
                                            <options-dropdown
                                                :customZIndex="10"
                                                v-if="column.options && column.options.dropdown"
                                                theme="cancel"
                                                :options="column.options.dropdown.options"
                                                :actions="column.options.dropdown.actions"
                                                @click.native="stopPropagation"
                                            />
                                        </template>
                                    </div>
                                </th>

                                <th
                                    v-else-if="column && column.type != 'empty'"
                                    :key="`button_${index}_${column.name}`"
                                    class="pb-3 px-1 py-1 text-right force-right"
                                    v-bind="getColumnStyle(index)"
                                    :class="isNested ? '' : 'border-b border-gray-m-light'"
                                    :colspan="columns[columns.length -1].type == 'empty' ? 4 : 2"
                                    >
                                    <template v-if="column.type == 'header-button'">
                                        <base-button theme="cancel" v-if="column.options && column.options(column)" @action="column.onAddColumnClick(column)" type="label" :icon-size="5" :icon="column.options(column).button.icon"/>
                                    </template>

                                    <template v-if="column.type == 'header-button-dropdown'">
                                        <options-dropdown
                                            :customOpenZIndex="column.options(column, index).openIndex"
                                            v-if="column.options(column, index) && column.options(column, index).dropdown"
                                            theme="cancel"
                                            :options="column.options(column, index).dropdown.options"
                                            :actions="column.options(column, index).dropdown.actions"
                                            
                                            >
                                            <template #icon>
                                                <base-icon name="plus" :size="5" />
                                            </template>
                                        </options-dropdown>
                                    </template>

                                    <template v-if="column.type == 'expand-all-sub-tables'">
                                        <button @click="column.onClick(column)" class="focus:outline-none">{{(column.expanded) ? column['expandText'][1] : column['expandText'][0] }}</button>
                                    </template>

                                    <template v-if="column.type == 'edit-columns'">
                                        <button @click="column.onClick(column)" icon="column-edit" class="focus:outline-none">{{column.label}}</button>
                                    </template>
                                </th>
                            </template>
                        </template>

                    </tr>
                </thead>

                <!-- body from loader -->
                <tbody v-for="(element, index) in filteredRows" :key="'frb_' + index" class="base-table-body">
                    <tr
                      :key="element.id || 'frr' + index"
                      :id="`tr-${index}`"
                      :class="[
                          rowClass(element),
                          index < (filteredRows.length - 1) ? 'border-b' : '',
                          'focus:bg-green-500',
                          onRowClick ? 'cursor-pointer hover:bg-gray-m-extra-light transition duration-300' : '',
                          element.expanded && stickyRow && index === stickyRowIndex ? 'sticky-row' : '',
                        ]"

                      @click="onRowClick ? onRowClick(element) : null"
                      :data-ref="!isNested ? 'parentFirstRow' : ''"
                      :data-index="index"
                    >
                    
                        <!-- checkbox -->
                        <td v-if="bulkActionsEnabled">
                            <div class="flex justify-center items-center h-6 w-6 mx-3" style="margin-top: 3px" >
                                <FormulateInput
                                    :options="[{value: element.id}]"
                                    type="checkbox"
                                    v-model="selectedElements"
                                />
                            </div>
                        </td>

                        <!-- pinned -->
                        <td v-if="pinningEnabled">
                            <base-button
                                class="ml-auto"
                                @action="$emit('on-toggle-pinned', element.id, element.isPinned)"
                                type="label"
                                icon="flagged"
                                :iconFill="element.isPinned ? true : null"
                            />
                        </td>

                        <td
                            v-for="(column, c_index) in columns"
                            :key="'c_i_td_' + c_index"
                            :class="[
                                'py-1',
                                c_index === 0 ? 'pl-6 fixed-column' : 'pl-0',
                                index === hoveredIndex ? 'fixed-column-hover' : 'fixed-column-base',
                                isScrolled && !c_index ? 'shadow-right' : '',
                                column.type == 'empty' ? 'w-10' : ''
                            ]"
                            :style="`${column.options && column.options.cellStyles ? column.options.cellStyles : ``}`"
                            @click="(e) => (column.options && column.options.stopPropagation) ? e.stopPropagation() : null"
                        >

                            <template v-if="!column.hidden">

                                <!-- avatar -->
                                <template v-if="column.type == 'avatar'">
                                    <avatar
                                        class="cursor-pointer max-w-none h-6 w-6 rounded-full text-white shadow-solid"
                                        v-tooltip="{ content: `${getValue(column, element).first_name} ${getValue(column, element).last_name}`, classes: 'bg-black text-white rounded py-1 px-1 text-xs', delay: { show: 100, hide: 50 } }"
                                        :user="getValue(column, element).user"
                                        size="auto"
                                    />
                                </template>

                                <!-- tooltip -->
                                <template v-if="column.type == 'tooltip'">
                                    <base-icon
                                        v-if="column.visible ? column.visible(element) : true"
                                        :name="column.icon"
                                        :class="column.iconClass"
                                        v-tooltip="{ content: column.text, classes: 'bg-black text-white rounded py-1 px-2 text-xs', delay: { show: 100, hide: 50 } }"
                                        @action="column.onClick ? column.onClick(element) : null"
                                    />
                                </template>

                                <!-- avatar list -->
                                <template v-else-if="column.type == 'avatar-list'">
                                    <div class="flex gap-4 items-center mr-10" style="">
                                        <base-icon name="sorter" class="cursor-move handle" v-if="isDraggable"/>
                                        <div v-if="!getValue(column, element).length" class="inline-flex gap-2 items-center">
                                            <span :class="['inline-flex items-center justify-center sm h-6 w-6 rounded-full bg-gray-m']">
                                                <span :class="['text-white text-2xs']">UA</span>
                                            </span>
                                            <span class="text-sm text-gray-m-disable">Unassigned</span>
                                        </div>

                                        <avatar-list v-else
                                            :class="column.extraClasses ? column.extraClasses : ''"
                                            :users="getValue(column, element)"
                                            :showAmount="5"
                                            :showExtra="true"
                                            size="auto"
                                        />
                                    </div>
                                </template>

                                <!-- avatar list -->
                                <template v-else-if="column.type == 'thumbnail'">
                                    <img :src="getValue(column, element)" class="h-10 w-10 rounded-full object-cover" @error="setAltImg"/>
                                </template>

                                <!-- price -->
                                <template v-else-if="column.type == 'price'">
                                    <div
                                        class="text-purple-m-secondary text-ps"
                                        @click="column.onClick ? column.onClick(element) : null"
                                    >
                                        {{ getValue(column, element) | numeral('$0,0.00') }}
                                    </div>
                                </template>

                                <!-- link -->
                                <template v-else-if="column.type == 'link'">
                                    <div
                                        class="cursor-pointer truncate hover:text-purple-m-secondary text-purple-m-main font-bold text-h6"
                                        @click="column.onClick ? column.onClick(element) : null"
                                        v-tooltip="{ content: getValue(column, element), classes: 'bg-black text-white rounded py-1 px-2 text-xs', delay: { show: 600, hide: 50 } }"
                                    >
                                        {{ getValue(column, element) }}
                                    </div>
                                </template>

                                <!-- button -->
                                <template v-else-if="column.type == 'button'">
                                    <base-button @action="column.onClick ? column.onClick(element) : null" :disabled="!column.disabled ? false : typeof column.disabled == 'boolean' ? column.disabled : column.disabled(element)"
                                        :secondaryIcon="column.secondaryIcon" type="secondary" theme="dark" size="lg" class="font-bold float-right" padding="p-2"
                                    >
                                        {{ getValue(column, element) }}
                                    </base-button>
                                </template>

                                <div class="flex flex-actions" v-else-if="column.type == 'actions'">
                                    <base-button
                                        v-for="(action, act_key) in column.actions"
                                        :key="act_key"
                                        size="md"
                                        type="secondary"
                                        theme="dark"
                                        text-class="text-xs font-bold"
                                        :label="action.label"
                                        @action="action.onClick ? action.onClick(element) : null"
                                        :disabled="!action.disabled ? false : typeof action.disabled == 'boolean' ? action.disabled : action.disabled(element)"
                                    />
                                </div>

                                <!-- icon -->
                                <template v-else-if="column.type == 'single-icon'">
                                    <div @click="column.onClick ? column.onClick(element) : null" :class="column.onClick ? 'cursor-pointer' : ''">
                                        <base-icon :name="column.icon"/>
                                    </div>
                                </template>

                                <template v-else-if="column.type === 'single-select'">
                                    <div :style="`${column.width ? `width: ${column.width}` : ``}`">
                                        <custom-select
                                            :value="typeof column.options === 'function' ? column.value(element, column) : element[column.name][0]"
                                            :options="typeof column.options === 'function' ? column.options(element, column) : column.options"
                                            :placeholder="column.placeholder"
                                            :multiple="false"
                                            v-tooltip="( column.disabled && column.disabledTooltip ) ? { content: `${column.disabledTooltip}`, classes: 'bg-black text-white rounded py-1 px-2 text-xs', delay: { show: 100, hide: 50 } } : {}"
                                            :disabled="!column.disabled ? false : typeof column.disabled == 'boolean' ? column.disabled : column.disabled(element)"
                                            :key="element.id + '_cs_' + c_index"
                                            size="sm"
                                            :type="column.style ? column.style : 'select'"
                                            @changed="(val) => {
                                                return selectCheck(element, column, val)
                                            }"
                                        />
                                    </div>
                                </template>

                                <!-- date -->
                                <template v-else-if="column.type == 'date'">
                                    <div>
                                        <span v-if="getValue(column, element) !== null">{{ getValue(column, element) | moment('MMM D, Y') }}</span>
                                        <span v-else>-</span>
                                    </div>
                                </template>

                                <template v-else-if="column.type === 'number'">
                                    <div v-if="helpIsNumber(getValue(column, element))">{{ getValue(column, element) | numeral('0,0') }}</div>
                                    <div v-else>-</div>
                                </template>

                                <template v-else-if="column.type === 'number-format'">
                                    <div class="flex items-center gap-2 comparition-table-item" v-if="Array.isArray(getValue(column, element))">
                                        <div
                                            v-for="(val, v_key) in getValue(column, element)"
                                            @click="column.onClick ? column.onClick(element) : null"
                                            :key="v_key"
                                            class="comp-item uppercase flex items-center"
                                            :class="{'cursor-pointer': column.onClick}"
                                        >{{ val | numeral('0.0a') }}</div>
                                    </div>
                                    <template v-else>
                                        <div v-if="helpIsNumber(getValue(column, element))" class="uppercase">{{ getValue(column, element) | numeral('0.0a') }}</div>
                                        <div v-else>-</div>
                                    </template>
                                </template>

                                <template v-else-if="column.type === 'money-format'">
                                    <div class="flex items-center gap-2 comparition-table-item" v-if="Array.isArray(getValue(column, element))">
                                        <div
                                            v-for="(val, v_key) in getValue(column, element)"
                                            @click="column.onClick ? column.onClick(element) : null"
                                            :key="v_key"
                                            class="comp-item uppercase flex items-center"
                                            :class="{'cursor-pointer': column.onClick}"
                                        >${{ val | numeral('0.0a') }}</div>
                                    </div>
                                    <template v-else>
                                        <div v-if="helpIsNumber(getValue(column, element))" class="uppercase">${{ getValue(column, element) | numeral('0.0a') }}</div>
                                        <div v-else>-</div>
                                    </template>
                                </template>

                                <template v-else-if="column.type == 'toggle-open'">
                                    <div @click="$emit('toggle-open', element, index)" class="cursor-pointer">
                                        <base-icon :name="getValue(column, element) ? 'chevron-up' : 'chevron-down'"/>
                                    </div>
                                </template>

                                
                                <template v-else-if="column.type === 'email'">
                                    <div class="flex gap-2 items-center">
                                        <div :class="[{'cursor-pointer': column.onClick !== undefined}, column.columnClass !== undefined ? column.columnClass(element) : 'text-purple-m-secondary text-ps']" @click="column.onClick ? column.onClick(element) : null">
                                            {{ getValue(column, element) }}
                                        </div>
                                    </div>
                                </template>

                                <template v-else-if="column.type === 'code-display'">
                                    <code class="language-js" v-html="formatData(getValue(column, element))"></code>
                                </template>

                                <!-- custom component -->
                                <template v-else-if="column.type === 'custom-component'">
                                    <div>
                                        <component v-on:action="column.action" :is="column.component" v-bind="column.props(element)" />
                                    </div>
                                </template>

                                <!-- OTHER TYPES ... -->

                                <div :class="column.containerClass !== undefined ? column.containerClass(element) : ''" v-else>
                                    
                                    <template v-if="column.type == 'editable-standard'">

                                        <template>
                                            <div v-if="column.editable && column.raw(element).edit">

                                                <FormulateInput
                                                    type="text"
                                                    @click.native="stopPropagation"
                                                    outer-class="w-full"
                                                    :id="`input-${column.name}-${column.type}-${element.id}`"
                                                    element-class="w-full"
                                                    wrapper-class="border rounded-md py-1 px-1 bg-white h-10 flex items-center"
                                                    input-class="focus:outline-none text-purple-m-secondary resize-none bg-transparent"
                                                    style="width: auto; min-width: 60px; text-align: left; max-width: 200px;"
                                                    :placeholder="column.label"
                                                    v-model="column.raw(element).data"
                                                    @keyup.enter="$event.target.blur()"
                                                    @blur="saveColumnData(column, element)"

                                                />
                                            </div>

                                            <base-button
                                                v-else
                                                @click.native="stopPropagation"
                                                @action="focusOnInput(column.raw(element), `input-${column.name}-${column.type}-${element.id}`);"
                                                type="label"
                                                justify="start"
                                                rounded="lg"
                                                style="width: auto; min-width: 100px; text-align: left;"
                                                class="px-1 py-1 hover:bg-tan-m gap-3 rounded-lg text-h6 font-base border focus:outline-none transition duration-150 ease-in-out border-transparent text-purple-m-main hover:text-purple-m-secondary focus:text-purple-m-secondary"
                                                >
                                                {{ column.getDisplayValue(element) != "" ? column.getDisplayValue(element) : "-" }}

                                            </base-button>

                                        </template>


                                    </template>

                                    <!-- default string -->

                                    <template v-else>
                                        <div :class="[column.span !== undefined ? 'flex flex-col gap-1' : '', {'cursor-pointer': column.onClick !== undefined}, column.columnClass !== undefined ? column.columnClass(element) : 'text-purple-m-secondary text-ps']" @click="column.onClick ? column.onClick(element) : null">
                                            {{ getValue(column, element) }}
                                            <span @click="column.span.action ? column.span.action(element) : null" v-if="column.span && ((column.span.visible ? column.span.visible(element) : true))" :class="column.span.class">{{ column.span.text }}</span>
                                        </div>
                                    </template>

                                    <template v-if="column.rowHelp && column.rowHelp(element)">
                                        <base-icon
                                            class="text-gray-600"
                                            v-tooltip="{ content: column.rowHelp(element), classes: 'bg-black text-white rounded py-1 px-2 text-xs', delay: { show: 100, hide: 50 } }"
                                            name="info-circle"
                                        />
                                    </template>

                                    <template v-if="column.type == 'custom' && element.column_values && element.column_values.length > 0 && element.column_values.find( value => value.column_name_id == column.id)">

                                        <template v-if="column.editable">
                                            <div v-if="element && element.column_values && getValue(column, element, true).edit">
                                                <FormulateInput
                                                type="text"
                                                @click.native="stopPropagation"
                                                outer-class="w-full"
                                                :id="`input-${getValue(column, element, true).id}-${column.type}-${column.id}`"
                                                element-class="w-full"
                                                wrapper-class="border rounded-md py-1 px-1 bg-white h-10 flex items-center"
                                                input-class="focus:outline-none text-purple-m-secondary resize-none bg-transparent"
                                                style="width: auto; min-width: 60px; text-align: left; max-width: 200px;"
                                                placeholder="empty"
                                                v-model="getValue(column, element, true).data"
                                                @keyup.enter="$event.target.blur()"
                                                @blur="column.options.inputOnBlur(getValue(column, element, true), column, element)"

                                                />
                                            </div>
                                            <base-button
                                                v-else
                                                @click.native="stopPropagation"
                                                @action="focusOnInput(getValue(column, element, true), `input-${getValue(column, element, true).id}-${column.type}-${column.id}`);"
                                                type="label"
                                                justify="start"
                                                rounded="lg"
                                                style="width: auto; min-width: 100px; text-align: left;"
                                                class="px-1 py-1 hover:bg-tan-m gap-3 rounded-lg text-h6 font-base border focus:outline-none transition duration-150 ease-in-out border-transparent text-purple-m-main hover:text-purple-m-secondary focus:text-purple-m-secondary"
                                                >
                                                {{ getValue(column, element, true)[column.searchByValueProperty] != "" ? getValue(column, element, true)[column.searchByValueProperty] : "-" }}

                                            </base-button>

                                        </template>


                                    </template>

                                    <template v-if="column.valueDropdown">
                                        <options-dropdown
                                          :customZIndex="index == filteredRows.length - 1 ? zIndex + index : zIndex - index"
                                          :custom-open-z-index="element.hasOwnProperty('expand') && element.expand ? 102 : 103"
                                          theme="cancel"
                                          :options="column.options(element).dropdown.options"
                                          :actions="column.options(element).dropdown.actions"
                                          @click.native="stopPropagation"
                                        >
                                            <template #icon>
                                                <base-icon class="text-black" name="chevron-down" :size="3" />
                                            </template>
                                        </options-dropdown>

                                    </template>
                                </div>
                            </template>
                           
                        </td>   

                        <!-- row actions -->
                        <td 
                            @click.stop="()=>null" 
                            v-if="rowActionsEnabled"
                            :class="`px-1 py-1 ${fixedRowActions ? `sticky-table-cell right-0` : ``}`"
                            :style="`${fixedRowActions ? `z-index: ${filteredRows.length - (index+1)}` : ``}`"
                        >
                            <div class="relative flex justify-center items-center">
                                <options-dropdown
                                    :options="[...filteredRowActions(element)]"
                                    :external-status="element.isOpen"
                                    v-on="setRowActionsEvents(element)"
                                    :top="actionsPosition === 'top'"
                                    @toggle-status="$emit('toggle-status', element)"
                                />
                            </div>
                        </td>

                        <td class="py-2">
                            <div class="flex h-4 items-center justify-end gap-4">
                                <div
                                    :id="`option-dropdown-container-${element.index || element.id}`"
                                    :style="`position: relative; right: 13px;${element.hasOwnProperty('expand') && element.expand ? (!!element.optionsOpened ? 'z-index: 202' : 'z-index: 99') : 'z-index: 99'}`"
                                    v-if="element.options && typeof element.options === 'function'"
                                    class="flex justify-center"
                                >
                                    <options-dropdown
                                        :customZIndex="zIndex"
                                        :custom-open-z-index="element.hasOwnProperty('expand') && element.expand ? 110 : 113"
                                        v-if="element.options && element.options(element).dropdown"
                                        theme="cancel"
                                        :external-trigger="!!element.optionsOpened"
                                        :options="element.options(element).dropdown.options"
                                        :actions="element.options(element).dropdown.actions"
                                        @dropdown-status="(val) => setOptionsStatus(element, val)"
                                        @click.native="stopPropagation"
                                    />
                                </div>
                            <div
                                style="position: relative; right: 13px"
                                v-if="element.hasOwnProperty('expand')"
                                v-tooltip="{
                                    content: `${element.expand ? 'Hide ' + element.name + 's' : 'Show ' + element.name + 's'}`,
                                    classes: 'bg-black text-white rounded py-1 px-1 text-xs',
                                    delay: { show: 100, hide: 50 },
                                    placement: 'left'
                                }"
                                @click="(e) => toggleNested(element, e)"
                                :class="[`flex justify-center`, `expand-trigger-${element.index || element.id}`]"
                                >

                                <base-icon
                                :class="element.expand ? '' : ''"
                                :size="element.expand ? 3 : 4"
                                :name="element.expand ? 'chevron-down' : 'chevron-right'"
                                />
                            </div>
                        </div>
                        </td>

                        <template v-if="columns.length && columns[columns.length - 1] !== undefined && columns[columns.length - 1].type == 'empty'">
                            <td class="py-2 w-8">
                                <div></div>
                            </td>
                        </template>
                    </tr>

                    <tr v-if="columns.length && data.length && element.expand">
                        <td :colspan="(columns.length + 1)">
                            <slot name="nested-table" :data="element"></slot>
                        </td>
                    </tr>

                </tbody>
                <!--</tbody>-->

            </draggable>

            <!-- Empty State -->
            <div v-if="(!data.length || !filteredRows.length) && !loading" class="p-4 w-full text-md text-center text-purple-m-main font-semibold">
                {{ emptyText }}
            </div>

            <div class="flex gap-2 mb-8 cursor-pointer font-bold justify-center" @click="$emit('add-row')" v-if="allowAdd && $listeners['add-row'] !== undefined">
                <base-icon name="plus"/>
                <div>{{ addRowLabel }}</div>
            </div>
       </div>

       <slot name="table-footer"></slot>

       <div v-if="(hasExternalSrc || internalPagination) && total > 0" class="flex justify-between items-center py-4 mx-12 sm:mx-8">
           <div v-if="!showingAllPaginated">Showing {{ start }} to {{ end }} of {{ total }} results.</div>
           <div v-else>Showing {{ total }} results</div>
           <div v-if="allowShowAll && (filteredRows.length !== total || showingAllPaginated)" class="cursor-pointer font-bold text-xs" @click="showingAllPaginated = !showingAllPaginated">{{  showingAllPaginated ? 'Collapse' : 'Show all' }}</div>
           <div class="flex justify-center">
                <base-button v-show="!showingAllPaginated" icon="chevron-left" size="xs" type="secondary" rounded="0" :disabled="page === 1" class="border border-r-0 border-gray-300 rounded-l-xl" @action="alterPage(-1)"/>
                <base-button v-show="!showingAllPaginated" icon="chevron-right" size="xs" type="secondary" rounded="0" :disabled="page === lastPage" class="border border-gray-300 rounded-r-xl" @action="alterPage(1)"/>
           </div>
       </div>
   </div>
</template>

<script>
import Sort from './Sort';
import AvatarList from './AvatarList';
import { mapGetters } from 'vuex';
import draggable from 'vuedraggable';

export default {
    props: {
        dynamicComponents: {
          type: Object,
          required: false,
          default: () => { return {}; }
        },
        tableOptions: {
            type: Array,
            required: false,
        },
        tableKey: {
            type: Number,
            default: 0,
        },
        headerIndex: {
            type: Number,
            default: 0,
        },
        customComponents: {
            type: Array,
            required: false,
        },
        isNested: {
            type: Boolean,
            required: false,
            default: false
        },
        reload: {
            type: Boolean,
            required: false
        },
        collapsed: {
            type: Boolean,
            required: false
        },
        invalidRows: {
            type: Set,
            default: () => new Set(),
        },
        loadingRows: {
            type: Array
        },
        addColumnEnabled: {
            type: Boolean,
            required: false,
            default: false
        },
        columns: {
            type: Array,
            required: true
        },
        data: {
            type: Array,
            required: true
        },
        onRowClick: {
            type: Function,
            required: false
        },
        rowActions: {
            type: Array,
            required: false
        },
        fixedRowActions: {
            type: Boolean,
            required: false,
            default: false
        },
        bulkActions: {
            type: Array,
            required: false
        },
        loading: {
            type: Boolean,
            required: false
        },
        hideHeaders: {
            type: Boolean,
            required: false
        },
        hideChildHeaders: {
            type: Boolean,
            required: false
        },
        pinnedElements: {
            type: Array,
            required: false,
            default: function () {
                return [];
            }
        },
        options: {
            type: Object,
            required: false
        },
        title: {
            type: String,
            required: false
        },
        searchbarStyles: {
            type: String,
            required: false
        },
        search: {
            type: Boolean,
            default: true
        },
        filtering: {
            type: Boolean,
            default: true
        },
        emptyText: {
            type: String,
            default: 'No results found'
        },
        childEmptyText: {
            type: String,
            default: 'No results found'
        },
        addRowLabel: {
            type: String,
            default: 'Add row'
        },
        addChildLabel: {
            type: String,
            default: 'Add row'
        },
        allowAdd: {
            type: Boolean,
            default: true
        },
        allowAddChild: {
            type: Boolean,
            default: true
        },
        page: {
            type: Number,
        },
        perPage: {
            type: Number,
        },
        total: {
            type: Number
        },
        defaultFilters: {
            type: Object,
            default: function () {
                return {};
            }
        },
        overflow: {
            type: Boolean,
            default: false
        },
        tableId: {
            type: String,
            default: 'base-table'
        },
        actionsPosition: {
            type: String,
            default: 'bottom'
        },
        containerClass: {
            type: String,
            default: 'base-table-container'
        },
        containerStyles: {
            type: String,
            default: 'z-1'
        },
        tabs: {
            type: Array,
            default: function () {
                return [];
            }
        },
        currentTab: {
            type: String
        },
        searchClass: {
            type: String,
            default: ''
        },
        tableClass: {
            type: String,
            default: 'min-w-full'
        },
        stickyHeader: {
            type: Boolean,
            default: false
        },
        stickyRow: {
            type: Boolean,
            default: false
        },
        childColumns: {
            type: Array,
            default: function () {
                return [];
            }
        },
        childAttribute: {
            type: String
        },
        propSort: {
            type: Object,
            default: function () {
                return null;
            }
        },
        isDraggable: {
            type: Boolean,
            default: false
        },
        internalPagination: {
            type: Boolean,
            default: false
        },
        allowShowAll: {
            type: Boolean,
            default: false
        },
        sorting: {
            type: Boolean,
            default: true
        },
        border: {
            type: Boolean,
            default: true
        },
        handleScroll: {
            type: Boolean,
            default: false
        },
        pinnedUser: {
            type: Number,
            default: 0,
        }
    },
    components: {
        Sort,
        AvatarList,
        draggable
    },

    async created() {

        for (const componentName in this.dynamicComponents) {
            this.$options.components[componentName] = this.dynamicComponents[componentName].component;

            if ( this.dynamicComponents[componentName].data ) {
                this.$data.[this.dynamicComponents[componentName].data.propName] = this.dynamicComponents[componentName].data.value;
            }
        }
    },

    data() {
        return {

            rows: [],

            zIndex: 100,

            isScrolled: false,

            isScrolledX: false,

            tableHeader: null,

            hoveredIndex: -1,
            visibleItems: [],

            selectedElements: [],

            sort: {
                column: '',
                asc: true
            },

            filters: {
                search: {
                    type: 'string',
                    selected: ''
                },
            },

            showingAllPaginated: false,

            refreshKey: true,
            savingUserColumn: false,

            tooltip: {
                content: 'Collaborators are not allowed to perform this action.',
                classes: 'bg-black text-white rounded py-1 mb-2 mr-16 px-1 text-xs',
                delay: {
                    show: 100,
                    hide: 50
                }
            },

            outerTableHeaderHeight: 0,

            rendered: false
        }
    },

    beforeDestroy() {

    },

    mounted(){

        this.rows = this.data;

        this.tableHeader = this.getTableHeaderRef();

        this.setFilters();
        if (this.columns.length) {
            let column = this.columns[0].name;
            let asc = null;
            this.setSort(column, false, asc);
        }

        this.initialSelectedElements ? this.selectedElements = this.initialSelectedElements : null;
    },
    computed: {
        
        ...mapGetters(['statuses', 'colors', 'isAdminOrHigher', 'isUserAdminOrHigher', 'isGuest', 'isClient']),

        computedContainerClass() {
            return `${this.containerClass}-${this.tableKey}`;
        },
        tableHeaderRef() {
            return this.isNested ? 'nestedTableHeader' : 'outerTableHeader';
        },

        stickyRowIndex() {
            // Return the index of the row you want to make sticky, e.g., the first row
            return 0;
        },

        rowClass() {

          return (element) => {
            const invalidRowClass = element.target_column && this.invalidRows.has(element.target_column.name) ? 'bg-red-100 border-l-4 border-red-500 text-red-700 p-4' : '';
            return `draggable-item relative h-15 ${invalidRowClass}`;
          };
        },

        start() {
            if (this.hasExternalSrc || this.internalPagination) {
                return ((this.page - 1) * this.perPage) + 1;
            }
            return 1;
        },

        end() {
            if (this.hasExternalSrc || this.internalPagination) {
                let end = this.page * this.perPage;
                if (end > this.total) {
                    return this.total;
                }
                return end;
            }
            return 10;
        },

        lastPage() {
            let pages = this.total / this.perPage;
            return parseInt(Math.ceil(pages));
        },

        hasExternalSrc() {
            return this.perPage !== undefined && this.page !== undefined && this.total !== undefined && !this.internalPagination;
        },
        
        filteredRows: {
            get() {
                let start = this.internalPagination && this.page ? ((this.page - 1) * this.perPage) : 0;
                let end = this.internalPagination && this.page ? (start + this.perPage) : this.data.length;
                let data = this.rows.map(element => {
                element.isPinned = this.pinningEnabled ? this.pinnedElements.includes(element.id) : false
                return element;

                }).filter(element => {

                    if (this.hasExternalSrc || this.isDraggable) {
                        return true;
                    }
                    let errors = 0;

                    // iterate through all available filters to see if this element passes

                    for (const key in this.filters) {

                        const filter = this.filters[key]

                        let searchTerm = ''

                        // searchbar filter
                        // these checks on all available columns
                        if (filter.type === 'string') {

                            searchTerm = filter.selected.trim().toLowerCase();

                            let found = 0

                            // compare the term to each column's value
                            this.columns.forEach(column => {

                                let value = this.getValue(column, element)

                                if (value) {

                                    // if the column has property searchBy we use that value
                                    if (column.searchBy) {
                                        value = column.searchBy(element);
                                    } else {

                                        if (Array.isArray(value) && typeof value[0] === 'string') {
                                            // value is an array of strings
                                            value = value.join(',').toLowerCase();
                                        } else if (typeof value !== 'string') {
                                            // value is not a string, so convert it to a string
                                            value = value.toString().toLowerCase();
                                        }

                                        // check if the term was found on this column
                                        if (value.includes(searchTerm)) {
                                            found++;
                                        }
                                    }

                                }

                            })
                            // if the term was not found on any column, this element doesnt pass
                            if (!found) {
                                errors++
                            }

                        }

                        // select filters,
                        // only checks on a single column
                        if (filter.type === 'select') {

                            searchTerm = filter.selected && filter.selected.value ? filter.selected.label.trim().toLowerCase() : ''

                            if (searchTerm !== '') {

                                // find this filter's column
                                let column = this.columns.find(column => column.name == key)

                                let value = this.getValue(column, element)

                                if (value) {

                                    // if the column has property searchBy we use that value
                                    if (column.searchBy) {
                                        value = column.searchBy(element)
                                    } else {

                                        // if the value is a string, number or array of strings, we use that, anything else its ignored (doesnt apply the filter)
                                        if ((Array.isArray(value) && typeof value[0] === 'string') || typeof value === 'string' || typeof value === 'number') {
                                            value = value.toString().toLowerCase()
                                        } else {
                                            value = ''
                                        }

                                    }

                                    // if the term was not found on this column, this element doesn't pass
                                    if (!(value !== undefined && value.includes(searchTerm))) {
                                        errors++;
                                    }

                                }

                            }
                        }

                    }

                    return !errors;

                }).sort((a, b) => {
                  if (this.hasExternalSrc || this.isDraggable || !this.sorting) {
                    return 0;
                  }

                  let columnName = this.sort.column;
                  let column = this.columns.find(column => column.name == columnName);

                  // if no column found, a = b
                  if (!column) {
                    return 0;
                  }

                  if (a.isPinned === b.isPinned) {
                    let aValue = this.getValue(column, a, true);
                    let bValue = this.getValue(column, b, true);

                    // handle objects with a 'label' property
                    if (typeof aValue === 'object' && aValue[0]?.hasOwnProperty('label') && typeof bValue === 'object' && bValue[0]?.hasOwnProperty('label')) {
                      aValue = aValue[0].label;
                      bValue = bValue[0].label;
                    }

                    // Preprocess price values
                    if (column.type === 'price') {
                      aValue = parseFloat(aValue.replace(/[^\d.-]/g, ''));
                      bValue = parseFloat(bValue.replace(/[^\d.-]/g, ''));
                    }

                    const aType = typeof aValue;
                    const bType = typeof bValue;

                    if (aType === 'number' && bType === 'number') {
                        return this.sort.asc ? aValue - bValue : bValue - aValue;
                    } else if (aType === 'string' && bType === 'string') {
                        aValue = aValue.toLowerCase();
                        bValue = bValue.toLowerCase();
                        return this.sort.asc ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
                    } else {
                        aValue = String(aValue).toLowerCase();
                        bValue = String(bValue).toLowerCase();
                        return this.sort.asc ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
                    }
                  }

                  return b.isPinned - a.isPinned;
                });
                
                if (this.showingAllPaginated) {
                    return data;
                }
                return data.slice(start, end);
            },
            set(value) {
                this.$emit('update-values', value);
            }
        },

        allFilteredRowsSelected(){
            let errors = 0;
            this.filteredRows.forEach(filteredRow => {
                if(!this.selectedElements.includes(filteredRow.id)){
                    errors++
                }
            });
            return !errors 
        },
        rowActionsEnabled(){
            return this.rowActions && this.rowActions.length
        },
        
        bulkActionsEnabled(){
            return this.bulkActions && this.bulkActions.length
        },
        
        pinningEnabled(){
            return !!this.pinnedElements.length;
        }        
    },

    methods: {
        setOptionsStatus(element, val) {
            element.optionsOpened = val;
            this.$forceUpdate();
        },

        scrollListener() {
            if (!this.isNested && this.handleScroll) {
                let fixed = document.getElementsByClassName('outerTableHeader');
                if (fixed.length) {
                    let fix = fixed[0];
                    let items = this.data.filter(i => i.expand && i.optionsOpened);
                    if (items.length) {
                        let fixed_position = this.getElementOffset(fix).top;
                        let fixed_height = fix.offsetHeight;

                        items.forEach(item => {
                            let id = `option-dropdown-container-${item.index || item.id}`;
                            let el = document.getElementById(id);
                            if (el) {
                                let toCross_position = this.getElementOffset(el).top;
                                let toCross_height = el.offsetHeight;

                                if (!((fixed_position + fixed_height < toCross_position) || (fixed_position > toCross_position + toCross_height))) {
                                    item.optionsOpened = false;
                                    this.$forceUpdate();
                                }
                            }
                        });
                    }
                }
            }
        },

        getElementOffset(element) {
            var de = document.documentElement;
            var box = element.getBoundingClientRect();
            var top = box.top + window.pageYOffset - de.clientTop;
            var left = box.left + window.pageXOffset - de.clientLeft;
            return { top, left };
        },

        checkNestedScrolling() {

            // Get the sticky table row elements
            const stickyRows = this.$el.querySelectorAll(`tr[data-ref="nestedTableHeader"]`);

            if ( typeof stickyRows[0] != "undefined" ) {

                let initialStickyRowTop = stickyRows[0].getBoundingClientRect().top;


                stickyRows.forEach((stickyRow, index) => {

                    const stickyRowTop = stickyRow.getBoundingClientRect().top;

                    // need to take out the hardcoding here eventually
                    let isSticking = stickyRowTop === 230;

                    if ( isSticking ) {
                        stickyRow.classList.add('shadow-md');
                    } else {
                        stickyRow.classList.remove('shadow-md');
                    }

                });
            }
        },

        saveColumnData(column, element) {
            if (column.options && column.options.inputOnBlur) {
                column.options.inputOnBlur(column, element);
            }
        },

        getColumnStyle(index) {
            if (!this.tableHeader || !this.columns || !this.$el.querySelector('.master-table')) return {};

            const tableWidth = this.$el.querySelector('.master-table').offsetWidth;
            const columnCount = this.columns.length;
            const columnWidth = tableWidth / columnCount;

            const widthStyle = { width: `${columnWidth}px` };
            const customStyles = this.columns[index].options && this.columns[index].options.thStyles
            ? this.columns[index].options.thStyles
            : {};
            return {};
            // return { ...customStyles, ...widthStyle };
        },


        onClick() {
            if (this.index === this.filteredRows.length - 1) {
                this.zIndex += this.index;
            } else {
                this.zIndex -= this.index;
            }
            this.stopPropagation();
        },

        getTableHeaderRef() {
            const tableHeaderRef = this.tableHeaderRef;
            const tableHeaderElement = this.$el.querySelector(`tr[data-ref="${tableHeaderRef}"]`);
            return tableHeaderElement;
        },

        onScroll(event) {
            this.isScrolled = event.target.scrollLeft > 0;
            this.isScrolledX = event.target.scrollTop > 0;

            this.checkNestedScrolling();
            this.scrollListener();
        },

        focusOnInput(column, inputId) {
            column.edit = true;

            this.$nextTick(() => {
                let idName = inputId;

                // this is so the input focuses upon the click action
                // had to use raw javascript for it to work...
                const inputElement = document.getElementById(idName);
                if (inputElement) {
                    inputElement.focus();
                }
            });
        },

        stopPropagation(event) {
            event.stopPropagation();
        },

        toggleNested(element, event) {
            event.stopPropagation();
            this.$emit('toggle-nested', element, event);
        },

        selectCheck(element, column, val) {
            this.$emit('change-single-select', element, column, val);
        },

        isInteger(value) {
            return Number.isInteger(parseInt(value, 10));
        },

        setFilter() {
            if (this.hasExternalSrc || this.internalPagination) {
                let filters = {};
                for (let i in this.filters) {
                    let filter = this.filters[i];
                    if (filter.selected !== null && filter.selected !== '') {
                        if (filter.selected.value !== undefined && filter.selected.value !== null) {
                            filters[i] = filter.selected.value;
                        } else {
                            if (filter.selected.value === undefined) {
                                filters[i] = filter.selected;
                            }
                        }
                    }
                }
                
                if(Object.keys(this.filters).length === 1 && this.filters.search){
                    this.$emit('search', this.filters.search.selected)
                }
                this.$emit('changePage', 1, false);
                this.$emit('reload-filters', filters);
            }
        },

        alterPage(add) {
            let page = this.page;
            if (this.page > 1 && add === -1) {
                page--;
            }

            if (this.page < this.lastPage && add === 1) {
                page++;
            }

            this.setPage(page);
        },

        setPage(page) {
            this.$emit('changePage', page);
        },

        setAltImg (e) {
            e.target.src = require('../assets/images/default-picture.png');
        },

        helpIsNumber(element) {
            return !window.isNaN(element);
        },

        setRowActionsEvents(element){
            let obj = {}
            this.rowActions.forEach(action => {
                obj[action.event] = () => {
                    action.handler(element)
                }
            })
            return obj
        },

        async setFilters(){

            let columnsWithFilters = await this.columns.filter(element => element.filter);

            let searchSelected = '';

            if (this.defaultFilters.search !== undefined) {
                searchSelected = this.defaultFilters.search;
            }
            
            let filters = {
                search: {
                    type: 'string',
                    selected: searchSelected
                }
            };
            
            // filter options must have value & label
            await columnsWithFilters.forEach(column => {
                let options = column.filter.options
                filters[column.name] = {
                    options: [
                        { 
                            value: null, 
                            label: column.filter.allSelectedPlaceholder 
                        },
                        ...((options && options.length > 0) ? options : [])
                    ],
                    selected: null,
                    placeholder: column.filter.placeholder,
                    type: column.filter.type
                }

            })

            this.filters = this.filtering ? filters : [];

        },

        setSort(col, emit = true, asc = null) {
            let column_name = col.name;
            let changeColumn = false;
            
            this.sort.asc = !this.sort.asc;
            this.sort.column = column_name;
            changeColumn = true;

            if (asc != null) {
                this.sort.asc = asc;
            }

            this.sort.sort_type = col.sort_type || null;
            
            if (emit) {
                this.$emit('set-sort', this.sort, changeColumn);
            }
        },

        toggleSelectedElements() {
            if (this.selectedElements.length) {
                this.selectedElements = [];
            } else {
                this.filteredRows.forEach(element => {
                    this.selectedElements.push(element.id);
                });
            }
        },

        addAllToSelectedElements() {
            this.filteredRows.forEach(element => {
                if (!this.selectedElements.includes(element.id)) {
                    this.selectedElements.push(element.id)
                }   
            })
        },

        dropdownData(options) {
            console.log('data', options);
        },

        getValue(column, element, searchable = false){

            let value = '';
            // if value function is not specified on the column, we will search by the column name
            if(!column.value){
                value = element[column.name];
            }else{
                value = column.value(element);
            }
            // if we are looking for the searchable value, and there is a searchBy property on the
            // column, we will use that
            if(searchable && column.searchBy){
                value = column.searchBy(element);
            }

            return value;
        },

        cleanFilters() {
            for (let i in this.filters) {
                this.filters[i].selected = this.filters[i].type === 'string' ? '' : null;
            }
            this.refreshKey = !this.refreshKey;
        },

        filteredRowActions(element) {
            return this.rowActions.filter(action => {
                let passed = true
                if(action.if){
                    passed = action.if(element)
                }
                return passed 
            })
        }
    },

    updated(){
        const body = document.getElementsByClassName('base-table-body')[0]
        if(body && this.data){
            if(this.rendered == false && ((body.children && body.children.length) || (body.children.length == this.data.length && this.data.length == 0))){
                this.rendered = true
                this.$emit('rendered')
            }
        }
    },

    watch: {

        data: {
            handler(newValue) {
                if (newValue != null) {
                    // we save the prop locally for manipulation
                    this.rows = newValue;
                }
            }

        },

        // fires every time you select an element, so you can manage the selected elements elsewhere
        selectedElements: {
            handler: function () {
                let numberElements = [...this.selectedElements].map(element => {
                    return isNaN(element) ? element : parseInt(element);
                });
                let elements = this.data.filter(element => {
                    return this.selectedElements.includes(element.id) || numberElements.includes(element.id);
                });
                this.$emit('select-element', elements);
            }
        },

        columns: {
            deep: true,
            handler: function(val, old) {
                if (JSON.stringify(old) !== JSON.stringify(val)) {
                    this.setFilters();
                }
            }
        }

    }
}
</script>

<style lang="scss" scoped>

.z-skip-1 {
    z-index: 200 !important;
}

td, th {
    white-space: nowrap !important;
}

.forced-white th {
    background: #fff !important;
    &:hover {
        --bg-opacity: 1;
        background-color: #fff !important;
      }
}

.fixed-column {
  position: sticky;
  left: 0;
  background-color: transparent;
  z-index: 100;
  transition-duration: 300ms;

  &:hover {
    --bg-opacity: 1;
    background-color: #F3F2F4;
    background-color: rgba(243, 242, 244, var(--bg-opacity));
  }
}

.fixed-column.fixed-column-base {
    background-color: white;
    z-index: 100 !important;
    &:hover {
        background-color: #F3F2F4;
    }
}

.hover\:bg-gray-m-extra-light:hover {
    .fixed-column.fixed-column-base {
        background-color: #F3F2F4;
    }
}

.fixed-column-base {
  transition-duration: 300ms;
  z-index: 11;

  &.shadow-right:first-child:before {
        box-shadow: inset 10px 0 8px -8px #00000026;
        position: absolute;
        top: 0;
        right: 0;
        bottom: -1px;
        width: 30px;
        transform: translate(100%);
        transition: box-shadow .3s;
        content: "";
        pointer-events: none;
    }
}

.fixed-column-hover {
  --bg-opacity: 1;
  transition-duration: 300ms;
  background-color: #F3F2F4 !important;
  background-color: rgba(243, 242, 244, var(--bg-opacity)) !important;
  z-index: 11;

  &.shadow-right:before {
        box-shadow: inset 10px 0 8px -8px #00000026;
        position: absolute;
        top: 0;
        right: 0;
        bottom: -1px;
        width: 30px;
        transform: translate(100%);
        transition: box-shadow .3s;
        content: "";
        pointer-events: none;
    }
}

.master-table table tr:not(.nested-table):not(:first-child):hover {
  --bg-opacity: 1;
  background-color: #F3F2F4 !important;
  background-color: rgba(243, 242, 244, var(--bg-opacity)) !important;
}


    .nested-table .sticky-header {
      /* ... */
      top: var(--outer-table-header-height, 0px);
    }

    .sticky-row {
        background-color: #f2f2f2;
        position: sticky;
        z-index: 50;
        top: 0;
    }

    .invalid-row {
      background-color: #ffcccc; /* or any other color to highlight the row */
    }

    .sticky-table-cell{
        @apply sticky top-0 bottom-0 table-cell text-center align-middle;
        background: rgb(255 255 255 / 86%)
    }

    .flex-actions button:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none; /* Prevent double borders */
    }
    .flex-actions button:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left-width: 1px; /* Prevent double borders */
    }
    .comparition-table-item .comp-item:last-child:before {
        content: url('data:image/svg+xml,<svg fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"></path></svg>');
        width: 20px;
        height: auto;
        display: inline-block;
        margin-top: 0.4em;
    }

    .force-right > * {
        float: right;
    }
</style>

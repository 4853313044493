<template>
    <div class="wrapper" v-if="user && user.id !== 0">

        <div class="loader" v-if="loading">
            <base-icon size="8" name="loader" />
            <div class="title">Generating audience data...</div>
            <div class="subtitle">Please wait</div>
        </div>

        <template v-else>
            
            <!-- has audience data, tiktok or IG -->
            <div class="content" v-if="hasAudienceData()">
                <div class="actions">
                    <base-button
                        label="Refresh audience data"
                        size="xlg"
                        class="font-bold"
                        :disabled="loading"
                        @action="generateAudienceData(true)"
                    />
                </div>
                <report-user-card-stats :user="user" />
            </div>
            
            <!-- does not have audience data -->
            <div v-else>

                <!-- if has ig network but no social login invite to IG -->
                <div class="empty-state" v-if="hasIgNetworkUser && !hasIgSocialLogin">
                    Invite the user to log in with instagram to get audience data.
                </div>

                <!-- if has tiktok network but no social login invite to TikTok -->
                <div class="empty-state" v-if="hasTikTokNetworkUser && !hasTikTokSocialLogin">
                    Invite the user to log in with tiktok to get audience data.
                </div>
                
                <div class="empty-state" v-else>
                    <div class="title">No audience data yet.</div>
                    <base-button
                        label="Generate audience data"
                        size="xlg"
                        class="font-bold"
                        :disabled="loading"
                        @action="generateAudienceData(false)"
                    />
                </div>
            </div>

        </template>
    </div>
</template>
<script>
import ReportUserCardStats from '../../components/ReportUserCardStats.vue'
import { getObjectValue, isObject } from '../../common.js'
export default {
    props: ['user'],
    components: {
        ReportUserCardStats
    },
    data(){
        return {
            loading: false,
            tries: 0 
        }
    },
    mounted(){
        this.checkUser()
    },
    watch: {
        user(val){
            this.checkUser()
        }
    },
    computed: {
        isUserReady(){
            const user = this.user
            if(user && isObject(user) && user.social_logins){
                return true;
            }else{
                return false;
            }
        },
        // ig
        hasIgSocialLogin(){
            if(!this.isUserReady) return false;
            return !!this.user.social_logins.find(element => element.network_id == 6); // fb
        },
        hasIgAudienceData(){
            if(!this.isUserReady) return false;
            return !!this.user.audience_data.find(element => element.network_id == 2); // ig
        },
        hasIgNetworkUser(){
            if(!this.isUserReady) return false;
            return !!this.user.social_accounts.find(element => element.network_id == 2); // ig
        },

        // tiktok
        hasTikTokSocialLogin(){
            if(!this.isUserReady) return false;
            return !!this.user.social_logins.find(element => element.network_id == 14); // tiktok
        },
        hasTikTokAudienceData(){
            if(!this.isUserReady) return false;
            return !!this.user.audience_data.find(element => element.network_id == 14); // tiktok
        },
        hasTikTokNetworkUser(){
            if(!this.isUserReady) return false;
            return !!this.user.social_accounts.find(element => element.network_id == 14); // tiktok
        },
    },
    methods: {
        // check if the user does not have audience data, but has facebook social login 
        // in that case, generate the audience data automatically
        checkUser(){
            if(this.tries > 0) return;
            if(this.isUserReady){
                // check for ig
                if(!this.hasIgAudienceData && this.hasIgSocialLogin && this.hasIgNetworkUser){
                    this.tries++;
                    this.generateAudienceData();
                }
                // check for tiktok
                if(!this.hasTikTokAudienceData && this.hasTikTokSocialLogin && this.hasTikTokNetworkUser){
                    this.tries++;
                    this.generateAudienceData();
                }
            }
        },
        generateAudienceData(forceUpdate){
            this.loading = true
            this.$http.post('/users/generate-audience-data', {
                userId: this.user.id,
                force_update: forceUpdate
            }).then(({data}) => {
                this.$emit('reload')
                if(data){
                    this.$notify({ title: 'Success', text: 'Audience data generated', type: 'success' });
                }
            }).catch(err => {
                console.log('generateAudienceData error', err)
            }).finally(() => {
                this.loading = false
            })
        },

        // has IG or TikTok audience data
        hasAudienceData(){
            const audienceData = this.user.audience_data
            if(audienceData && Array.isArray(audienceData) && audienceData.length){
                const filteredAudienceData = audienceData.filter(element => {
                    return [2, 4, 14].includes(element.network_id)
                })
                let hasPayload = false
                for (const data of filteredAudienceData) {
                    const audience_followers = getObjectValue(data, 'payload.audience_followers')
                    const user_profile = getObjectValue(data, 'payload.user_profile')
                    if(audience_followers || user_profile){
                        hasPayload = true
                    }
                }
                return hasPayload
            }else{
                return false
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .wrapper{
        @apply px-12 py-6;
    }
    .content{
        > .actions{
            @apply w-full flex justify-end items-center;
        }
    }
    .empty-state{
        @apply w-full mt-10 flex flex-col items-center gap-y-4;
    }
    .loader{
        @apply flex flex-col items-center;
        > .title{
            @apply mt-4 text-h3 font-bold;
        }
        > .subtitle{
            @apply mt-2 font-bold;
        }
    }
    .title{
        @apply text-h3 font-bold;
    }
</style>

<template>
    <!-- The whole footer is hidden on the last "ReportLink" step -->
    <div 
        class="footer"
        :class="{ 'invisible': isReportLinkStep }"
    >
        <div class="left">
            <base-button 
                label="Back"
                v-if="!isFirstStep"
                type="cancel"
                class="font-bold border border-gray-500"
                icon="arrow-left"
                @action="$emit('back')" 
            />
        </div>
        <div>
            <base-button 
                label="Next"
                v-if="!isLastStep"
                :disabled="!nextEnabled"
                class="font-bold"
                secondary-icon="arrow-right"
                @action="$emit('next')" 
            />
            <base-button
                v-if="isLastStep" 
                label="Create Report"
                @action="$emit('submit')" 
                class="font-bold"  
            /> 
        </div>
    </div>
</template>
<script>
export default { 
    props: ['step', 'nextEnabled'],
    computed: {
        isFirstStep(){
            return this.step == 1;
        },
        isLastStep(){
            return this.step == 6;
        },
        isReportLinkStep(){
            return this.step == 7;
        }
    }
}
</script>
<style lang="scss" scoped>
    .footer{
        @apply py-4 px-12 border-t border-gray-200;
        @apply flex justify-between items-center;
        margin: 0px; // overwrite global footer styles
    }
</style>
<template>
<main class="flex-1 overflow-y-auto focus:outline-none" tabindex="0">

<div class="flex-1 min-w-0 bg-white creator-detail-wrapper">

    <Header 
        :loading="loading" 
        :projectUser="headerProjectUser"
        :user="user"
        :noProject="noProject"
        :project="project"
        v-model="viewTab"
        @showBoostedPostsModal="boostedPostsModalVisible = true"
    />

    <tab :visible="viewTab == 'Deliverable'">
        <deliverable-tab
            :loading="loading"
            :projects="projects"
            :status="status"
            :user-deliverables="userDeliverables"
            @reset-data="getData(false)"
            @open-add-deliverable-modal="openAddDeliverable"
            @user-deliverable-option="userDeliverableOption"
        />
    </tab>

    <!-- 
        <template v-if="viewTab == 'Performance'">
            <performance-tab 
                :user="projectUser.user"
            />
        </template> 
    -->

    <tab :visible="viewTab == 'Audience'">
        <audience-tab :user="user" @reload="getData()" />
    </tab>

    <tab :visible="viewTab == 'Info'">
        <info-tab :user="user" @reload="getData()" />
    </tab>

    <tab :visible="viewTab == 'Ratings'">
        <ratings-tab v-if="user.id" :user="user" @reload="getData()"/>
    </tab>

    <template v-if="project.id">

        <template v-if="viewTab === 'Documents'">
            <div class="flex justify-between px-12 py-10">
                <div v-if="isAdminOrHigher" class="flex gap-x-4">
                    <base-button 
                        class="font-bold"
                        size="lg"
                        icon="plus"
                        label="New Folder"
                        @action="createFolderModalVisible = true"
                    />
                </div>     
            </div>

            <!-- reports & files section -->
            <div class="px-12 pb-10">
                <!-- content -->
                <div class="w-full h-full">
                    <div>
                        <file-grid
                            title="Documents"
                            :project-id="project.id" 
                            :user-id="projectUser.user_id" 
                            :is-project-owner="isProjectOwner()"
                            :resource="{ type: 'project-user', id: projectUser.id }"
                            
                            @filesChange="(files) => fileCount = files.length"
                            @navigationChange="(navigation) => fileGridNavigation = navigation"

                            :force-file-grid-refresh="forceFileGridRefresh"
                        />
                    </div>
                </div>
            </div>
            <div class="px-12 ">Showing {{fileCount}} items</div>
        </template>

        <tab :visible="viewTab == 'Notes'">
            <notes-tab
                :notes="notes"
                :stagedNotes="stagedNotes"
                @refresh-data="getData(false)"
            />
        </tab>
    </template>

    <add-influencer-deliverable-modal
        :visible="addInfluencerDeliverableModalVisible"
        :key="`modal_key_${addInfluencerDeliverableModalKey}`"
        @close="addInfluencerDeliverableModalVisible = false; selectedProjectUser = null; selectedUserDeliverable = null;"
        @user-deliverable-created="onUserDeliverableCreated"
        :projectId="selectedProject"
        :fixed-amount="fixedAmount"
        :projectUserId="selectedProjectUser"
        :networks="networks"
        :currentUserDeliverable="selectedUserDeliverable"
    />

    <create-folder-modal
        :visible="createFolderModalVisible"
        :resource="{ type: 'project-user', id: projectUser.id }"
        :folderId="currentFolderId"
        @close="createFolderModalVisible = false"
        @created="forceFileGridRefresh = !forceFileGridRefresh"
    />

    <boosted-posts-modal
        :visible="boostedPostsModalVisible"
        @close="boostedPostsModalVisible = false"
        :user="projectUser.user"
    />

</div>
</main>
</template>

<script>
import FileList from '../../components/FileList';
import AddInfluencerDeliverableModal from "../../components/AddInfluencerDeliverableModal";
import CreateFolderModal from '../../components/CreateFolderModal.vue'
import BoostedPostsModal from './BoostedPostsModal/index.vue'
import PerformanceTab from './PerformanceTab.vue'
import Header from './Header.vue'
import DeliverableTab from './DeliverableTab.vue'
import NotesTab from './NotesTab.vue'
import AudienceTab from './AudienceTab.vue'
import InfoTab from './InfoTab/index.vue'
import RatingsTab from './RatingsTab/index.vue'
import { mapGetters } from 'vuex';
import { getObjectValue, notifyCatchError } from '../../common';
export default {
    components: {
        FileList,
        AddInfluencerDeliverableModal,
        CreateFolderModal,
        BoostedPostsModal,
        PerformanceTab,
        Header,
        DeliverableTab,
        NotesTab,
        AudienceTab,
        InfoTab,
        RatingsTab
    },

    data() {
        return {
            viewTab: 'Deliverable',
            noProject: false,
            project: {},
            projectUser: {
                user: {
                    id: 0
                }
            },

            userDeliverables: [],

            loading: false,

            createUserDeliverable: {
                show: false,
                editingId: null,
            },
            networks: [],

            showAddInfluencerDeliverableModal: false,
            editedUserDeliverable: null,

            status: [],
            projects: [],
            notes: [],
  
            stagedNotes: '',

            createFolderModalVisible: false,
            fileGridNavigation: [],
            forceFileGridRefresh: false,
            fileCount: 0,

            // addInfluencerDeliverableModal
            selectedProject: null,
            selectedUserDeliverable: null,
            selectedProjectUser: null,
            fixedAmount: null,
            addInfluencerDeliverableModalKey: 0,
            addInfluencerDeliverableModalVisible: false,
            boostedPostsModalVisible: false

        }
    },

    computed: {
        ...mapGetters(['me', 'user', 'isAdminOrHigher', 'isGuest', 'isClient']),

        user() {
            return this.projectUser.user;
        },

        displayName() {
            // use company name if it exists
            let useCompanyName = this.user.user_companies && this.user.user_companies[0] && (this.user.user_companies[0].first_name && this.user.user_companies[0].last_name)
            return useCompanyName ? `${this.user.user_companies[0].first_name} ${this.user.user_companies[0].last_name}` : `${this.user.first_name} ${this.user.last_name}`
        },

        currentFolderId() {
            let nav = this.fileGridNavigation
            return nav.length > 0 ? nav[nav.length-1].id : null
        },

        headerProjectUser() {
            let projectUser = this.projectUser;
            let images = [];
            
            const avatar = getObjectValue(projectUser, 'user.avatar')
            if (avatar && avatar !== undefined && avatar !== '') {
                images.push(avatar);
            }

            const audience_data = getObjectValue(projectUser, 'user.audience_data')
            if (audience_data && Array.isArray(audience_data)) {
                for (let audience of audience_data) {
                    const picture = getObjectValue(audience, 'profile.picture')
                    if (picture !== undefined && picture !== '') {
                        images.push(picture);
                    }
                }
            }
            
            const user = getObjectValue(projectUser, 'user')
            if(user){
                projectUser.user.imagesArray = images;
            }

            return projectUser;
        },
    },

    watch: {
        showAddInfluencerDeliverableModal: function (val, old)  {
            if (!val) {
                this.editedUserDeliverable = null;
            }
        },
        viewTab(val){ 
            this.$router.push({ query: { ...this.$route.query, tab: val }})
        }
    },

    beforeMount() {
        this.$setTitle('Creator Detail');
    },

    mounted() {
        this.getStatus();
        this.getData();
        this.getNetworks();
        if (this.$route.query.tab) {
            this.viewTab = this.$route.query.tab;
        }
    },

    methods: {

        openAddDeliverable(id, projectId, fixed, project){
            this.selectedProject = projectId 
            this.selectedUserDeliverable = null;
            this.selectedProjectUser = this.projectUser.id;
            this.fixedAmount = fixed
            this.addInfluencerDeliverableModalKey++
            this.addInfluencerDeliverableModalVisible = true
        },

        onUserDeliverableCreated(){
            this.getProjects(this.selectedProject)
        },

        async userDeliverableOption(deliverable, option, fixed = false, project){
            switch(option){
                case 'edit':
                    this.addInfluencerDeliverableModalKey++;
                    this.openEditDeliverable(project.id, this.projectUser.id, deliverable, fixed);
                    break;
                case 'duplicate':
                    await this.$http.post(`/api/userDeliverables/duplicate/${deliverable.id}`)
                    await this.getProjects(project.id)
                    break;
                case 'delete':
                    this.$swal.fire({
                        title: `Are you sure you want to delete this deliverable?`,
                        text: '',
                        icon: 'warning',
                        iconColor: '#0E092C',
                        showCancelButton: true,
                        confirmButtonText: 'Delete',
                        reverseButtons: true,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.processDeleteDeliverable(deliverable, project);
                        }
                    });
                    break;
                default:
                    break;
            }
        },

        async processDeleteDeliverable(deliverable, project) {
            const { data } = await this.$http.post(`/api/userDeliverables/delete/${deliverable.id}`);
            if (data) {
                this.$store.dispatch('restorer/set', {
                    message: 'Deliverable deleted successfully',
                    success: 'Deliverable restored successfully',
                    fail: 'Deliverable cannot be restored',
                    route: `/api/userDeliverables/restore/${deliverable.id}`,
                    action: data.show_undo,
                    forceFn: true,
                    fn: async () => {
                        await this.getProjects(project.id)
                    }
                });
            }
        },

        openEditDeliverable(projectId, projectUserId, userDeliverable, fixed){
            this.selectedProject = projectId
            this.selectedProjectUser = projectUserId
            this.selectedUserDeliverable = userDeliverable
            this.fixedAmount = fixed

            if (userDeliverable.deliverables_type) {
                this.selectedUserDeliverable.type = userDeliverable.deliverables_type;
                this.selectedUserDeliverable.type.id = userDeliverable.deliverables_type.slug;
            }

            this.addInfluencerDeliverableModalVisible = true;
        },

        openAccount(account) {
            let link = `https://${account.network.name}.com/${this.getHandle(account)}`;
            window.open(link, '_blank');
        },

        getHandle(account) {
            let handle = account.network.name === 'tiktok' ? '@' : '';
            let username = account.handle ? account.handle : (account.social_id ? account.social_id : '');
            handle = handle + username;
            return handle;
        },

        editUserDeliverable(userDeliverable) {
            this.editedUserDeliverable = userDeliverable;
            this.showAddInfluencerDeliverableModal = true;
        },

        async getNetworks() {
            const { data } = await this.$http.get('/api/userDeliverables/networks');
            this.networks = data.map(network => {
                network.value = network.id;
                network.label = network.display_name;
                return network;
            });
        },

        getNetwork(networkId) {
            let network = this.networks.find(network => {
                return network.id === networkId;
            });

            return network ? network.name : '';
        },

        async viewFile(userDeliverable) {
            if (userDeliverable.deliverable.id === 4) {
                return window.open(`/files/${this.project.brief_attachment.guid}?acknowledge=1`);
            }
        },

        // deprecated ?
        async updateUserDeliverable({ id }) {
            await new Promise(resolve => setTimeout(resolve, 500));
            //console.log('after timeout');

            await this.getUserDeliverables();
            let userDeliverable = this.userDeliverables.find(userDeliverable => userDeliverable.id === id);
            this.selectedUserDeliverable = userDeliverable ? userDeliverable : null;
        },

        // deprecated ?
        selectUserDeliverable(userDeliverable) {
            if (this.selectedUserDeliverable === null) {
                this.selectedUserDeliverable = userDeliverable;
            } else if (this.selectedUserDeliverable.id !== userDeliverable.id) {
                this.selectedUserDeliverable = userDeliverable;
            } else {
                this.selectedUserDeliverable = null;
            }
        },
        async getData(loading = true) {
            this.loading = loading;
            if(this.$route.query.project){
                const { data } = await this.$http.get(`/api/projects/${this.$route.query.project}/${this.$route.params.userId}`);

                const projectUser = data.project_users[0];
                delete data.project_users;

                this.project = data;
                this.projectUser = projectUser;

                if (projectUser && projectUser.user && projectUser.user.notes) {
                    this.notes = projectUser.user.notes.map(note => {
                        note.showOptions = false;
                        note.visible = true;
                        note.editing = false;
                        note.submitting = false;
                        return note;
                    });

                    this.stagedNotes = JSON.stringify(this.notes);
                }

                this.$emit('update-title', this.displayName);
                
                await this.getUserDeliverables();
                if(!this.selectedUserDeliverable && this.userDeliverables.length)
                    this.selectUserDeliverable(this.userDeliverables[0])
                this.getProjects();
                this.loading = false;
            } else {
                this.noProject = true;
                this.$http.get(`/api/users/${this.$route.params.userId}/details`).then(async ({data}) => {
                    this.projectUser.user = data;
                    await this.getUserDeliverables();
                    this.getProjects();
                }).catch(err => {
                    notifyCatchError(err, this.$notify);
                }).finally(() => {
                    this.loading = false;
                });
            }
        },

        async getProjects(openProjectId = null) {
            const { data } = await this.$http.get(`/api/projects/searchByUser/${this.$route.params.userId}`);
            this.projects = data.map((project) => {
                project.status = this.status.filter(status => status.id === project.status)
                project.status[0].color = project.status[0].color?.name
                if(openProjectId){
                    project.showUserDeliverables = project.id == openProjectId ? true : false
                }else{
                    project.showUserDeliverables = false
                }
                project.userDeliverables = project.project_users[0].project_user_deliverables
                return project;
            });
        },

        async getStatus() {
            const { data } = await this.$http.get('/api/statuses');
            this.status = data.map(status => {
                status.label = status.name;
                return status;
            });
        },

        async getUserDeliverables() {
            // get project users linked to this user            
            const projectUsers = await this.$http.get(`/api/users/project-users/${this.$route.params.userId}`);

            // get user deliverables for each project user
            this.userDeliverables = [];
            await Promise.all(projectUsers.data.map(async (user) => {                
                let { data } = await this.$http.get(`/api/userDeliverables/${user.id}`);
                this.userDeliverables.push(data);
            }));

            // concat all deliverables.
            this.userDeliverables = [].concat.apply([], this.userDeliverables);
        },

        userDeliverableCreated(payload) {
            this.createUserDeliverable.show = false;
            this.userDeliverables.push(payload);
        },

        addDeliverable() {
            this.showAddInfluencerDeliverableModal = true;
            console.log('adding deliverable');
        },

        isProjectOwner(){
            return !!(this.project.project_owners && this.project.project_owners.find(element => element.user.id === this.user.id));
        },

    }
}
</script>

<style lang="scss">
.creator-detail-wrapper {

    .file-list-container {
        height: calc(100vh - 180px);
        overflow-y: auto;
    }


    .chart-wrapper {
        .legend {
            padding-top: 20px;
            display: flex;
            justify-content: space-around;
            max-width: 50%;
            margin: 0 auto;

            .stat {

                width: 30%;

                .display {
                    text-align: center;
                    font-size: 16px;

                    &.option-1 {
                        color: #FF6384;
                    }

                    &.option-2 { 
                        color: #36A2EB;
                    }
                }

                .label {
                    text-align: center;
                    color: #999;
                    font-size: 13px;
                }
            }
        }
    }


    .inner-data {
        .progress-wrapper {
            display: flex;
            font-size: 14px;
            color: #596b88;
            align-items: center;
            margin-bottom: 4px;

            .label, .display {
                font-size: 14px;
                color: #999;
            }

            .label {
                min-width: 50px;

                &.wider {
                    min-width: 80px;
                }
            }

            .display {
                font-weight: bold;
                text-align: right;
                font-size: 13px;
                width: 50px;
            }

            progress[value] {
                -webkit-appearance: none;
                appearance: none;
                border-radius: 0;
            }

            progress {
                width: 100%;
                color: #596b88;
                height: 15px;
                background: #eee;
            }

            progress::-webkit-progress-bar {
                background-color: transparent;
                width: 100%;
                height: 15px;
            }

            progress::-webkit-progress-value {
                background-color: #36A2EB !important;
                height: 15px;
            }

        }

        .progress-wrapper:nth-of-type(1n+8) {
            display: none !important;
        }
    }
}

</style>

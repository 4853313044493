<template>
    <div class="header">
        <back-button v-if="prevRoute" :prev-route="prevRoute"/>
        <div class="info-container">
            <div class="name">
                <skeleton v-if="loading" :height="6" :width="36"/>
                <h1 v-else>
                    <template v-if="isNotGuestClientOrCreator">
                        <a 
                            href="#" 
                            @click="$router.push(`/projects/${userDeliverable.project_user.project.slug}`)"
                        >
                            {{userDeliverableProject}}
                        </a> - {{userDeliverableName}} {{ slugName }}
                    </template>
                    <template v-else>{{userDeliverableProject}}</template>
                </h1>
            </div>
            <skeleton v-if="loading" :height="6"/>
            <template v-else>
                <options-dropdown
                    v-if="isNotGuestClientOrCreator"
                    theme="dark"         
                    :options="[
                        {label: 'Share Deliverable', icon: 'share', event: 'share'}
                    ]"
                    @share="shareDeliverablesModalVisible = true"
                />
            </template>
        </div>

        <div class="owners">
            <skeleton class="loader" v-if="loading" :height="6" :full-rounded="true"/>
            <div v-else class="wrapper">            
                <div class="container">                
                    <avatar-list :internal="userDirect" v-if="!isGuest && !isClient" :users="projectUser" cursor="pointer" theme="dark" />
                    <div class="text-white" v-else>{{ userDeliverableName }}</div>
                </div>                           
            </div>
        </div>
        
        <div class="concept-draft-required">
            <skeleton class="loader" v-if="loading" :height="6" :full-rounded="true"/>
            <template>
                <div class="text" v-if="!isAdminOrHigher && toggleDeliverables == true">Concept and Draft required</div>
                <FormulateInput
                    v-else
                    type="checkbox"
                    :key="`checkbox-${key}`"
                    :value="toggleDeliverables"
                    :checked="toggleDeliverables" 
                    @input="e => $emit('toggleDeliverablesInput', e)"
                    
                    input-class="cursor-pointer border-1 border-white"
                    label-class="ml-2 cursor-pointer"
                    label="Require a concept and draft"
                    class="w-full text-white text-sm"
                />
            </template>
        </div>

        <!-- tabs -->
        <div class="tabs-container">
            <tabs 
                theme="dark" 
                :tabs="deliverableTabs"

                :value="viewTab" 
                @input="val => $emit('viewTabInput', val)"
                
            />
        </div>

        <networks 
            :wrapperClass="'flex gap-4 justify-end absolute right-0 bottom-0 pr-12 pb-4'" 
            :accounts="platforms" 
        />
    
        <share-deliverables-modal
            :visible="shareDeliverablesModalVisible"
            :deliverable="userDeliverable"
            @close="shareDeliverablesModalVisible = false"
        />
    </div>
</template>

<script>
import ShareDeliverablesModal from "./ShareDeliverablesModal.vue";
import Networks from '../../components/Networks.vue';
import AvatarList from '../../components/AvatarList';

import { mapGetters } from 'vuex';

export default {
    props: {
        viewTab: {
            type: String,
            required: true
        },
        toggleDeliverables: {
            type: Boolean,
            required: true
        },
        userDeliverable: {
            type: Object,
            required: true,
            default: () => {
                return {}
            }
        },
        loading: {
            type: Boolean,
            required: true,
            default: false
        },
        userDirect: {
            type: Boolean,
            default: false
        },
        prevRoute: {
            type: Object,
            default: function () {
                return null;
            }
        }
    },
    components: {
        ShareDeliverablesModal,
        Networks,
        AvatarList
    },
    data(){
        return {
            shareDeliverablesModalVisible: false,
            key: false
        }
    },
    watch: {
        toggleDeliverables(val){
            this.key = !this.key
        }
    },
    computed: {
        ...mapGetters(['isAdminOrHigher', 'isGuest', 'isClient', 'isOnlyCreator']),
        isNotGuestClientOrCreator(){
            return (!this.isGuest && !this.isClient && !this.isOnlyCreator)
        },
        project() {
            if (Object.keys(this.userDeliverable).length){
                return this.userDeliverable.project_user && this.userDeliverable.project_user.project ? this.userDeliverable.project_user.project : {};
            }
            return {};
        },
        userDeliverableProject() {
            if(Object.keys(this.userDeliverable).length){
                return this.project.name ? this.project.name : 'Project Name'
            }
            else{
                return 'Project Name'
            }            
        },
        userDeliverableName() {
            if(Object.keys(this.userDeliverable).length){
                return this.userDeliverable.name && this.userDeliverable.name !== '' ? this.userDeliverable.name : `${this.userDeliverable.deliverable.name} Deliverable`
            }
            else{
                return 'Deliverable Name'
            }            
        },
        slugName() {
            if (this.userDeliverable && this.userDeliverable.deliverable !== undefined) {
                let name = this.userDeliverable.deliverable.name;
                if (!this.userDeliverableName.includes(name)) {
                    return `- ${name}`;
                }
            }

            return '';
        },
        projectUser() {
            let user = this.userDeliverable.project_user.user;
            if (this.platforms.length === 1) {
                user.account = this.platforms;
            }

            if (this.userDirect) {
                user.direct = true;
                user.social_url = `/creator/${user.id}`;
            }
            return [user];
        },
        deliverableTabs() {
            const visibleTabs = ['Concept', 'Draft'];
            if (this.userDeliverable.is_social_post_required)
                visibleTabs.push('Posts');
            return visibleTabs;
        },
        platforms() {
            let userDeliverable = this.userDeliverable;
            let accounts = [];
            if (userDeliverable && userDeliverable.project_user && userDeliverable.project_user.user && userDeliverable.project_user.user.social_accounts) {
                accounts = userDeliverable.project_user.user.social_accounts;                
                if (userDeliverable && userDeliverable.network_id) {
                    let currentAccount = accounts.find(a => a.network_id === userDeliverable.network_id);
                    if (currentAccount) {
                        return [currentAccount];
                    }
                }
            }
            return accounts;
        },
    }
}   
</script>

<style lang="scss" scoped>
    .header{
        @apply px-12 py-10 pb-0 bg-gradient-to-b from-gradient-m-main-start to-gradient-m-main-end relative;
        .info-container{
            @apply flex justify-between items-center pt-10;
            .name{
                @apply flex justify-start gap-x-8 items-center;
                h1{
                    @apply text-h2 font-bold text-white;
                }
            }
        }
        .owners{
            .loader{
                @apply mt-2 mb-14;
            }
            .wrapper{
                @apply flex mt-2 items-center pb-10;
                .container{
                    @apply w-full h-full flex justify-between items-center gap-x-4;
                }
            }
        }
        .concept-draft-required{
            .text{
                @apply text-white text-sm;
            }
        }
        .tabs-container{
            @apply flex justify-start items-center mt-4;
        }
    }
</style>

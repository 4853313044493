<template>
    <div class="featured-posts">

        <div
            class="list" 
            v-if="isArray(value)"
            @click="modalVisible = true"
        >
            <template v-for="(post, index) in value">   
                <!-- if its more than 3 we dont show them -->
                <div
                    v-if="index <= 2"
                    class="item" 
                    :key="`featured-post-${index}`"
                >
                    
                    <!-- tooltip -->
                    <div class="tooltip" @click.stop="openUrl(post.url)">
                        {{post.url}}
                    </div>

                    <!-- pre-upload state -->
                    <div v-if="uploadingPosts.includes(post.url)" class="uploading-wrapper">
                        <div class="uploading-container">
                            <div class="centerer">
                                <div class="uploading-background"/>
                            </div>
                            <div class="centerer">
                                <div class="uploading-icon">
                                    <base-icon name="cloud-arrow-up" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- if no thumbnail yet, just show the IG icon -->
                    <div v-if="post.found && post.preview && !post.preview.use_embed">
                        <!-- video or image -->
                        <template>
                            <video v-if="getMediaData(post.preview).is_video" autoplay muted>
                                <source :src="getMediaData(post.preview).media_url" type="video/mp4"/>
                            </video>
                            <img v-else :src="getMediaData(post.preview).media_url" />
                        </template>
                    </div>
                    <base-icon
                        v-else 
                        :name="post.network_name"
                    />

                </div>
            </template>
            <div class="additional" v-if="value.length > 3">
                <div class="title">+ {{value.length - 3}} post{{(value.length - 3) == 1 ? `` : `s`}}</div>
            </div>
        </div>

        <div v-else class="empty-state">
            <div class="title">No posts</div> 
            <base-button 
                v-if="!readonly"
                style="padding: 9px 0px; width:128px"
                label="Add posts"
                bold
                size="sm"  
                @action="modalVisible = true" 
            />
        </div>

        <base-table-column-featured-posts-modal
            :visible="modalVisible"
            :value="value"
            :handle="handle"
            :network="network"
            :readonly="readonly"
            @update:value="val => $emit('update:value', val)"
            @done="readonly ? null : uploadPosts()"
            @close="modalVisible = false"
        />

    </div>
</template>
<script>
import { isArray, isValidArray, isValidString } from '../common';
import BaseTableColumnFeaturedPostsModal from './BaseTableColumnFeaturedPostsModal.vue';
const defaultImage = require('../assets/images/default-picture.png');
export default {
    props: {
        value: {
            type: Array,
            required: true
        },
        handle: {
            type: String,
            required: true
        },
        network: {
            type: Object,
            required: true
        },
        readonly: {
            type: Boolean,
            required: true
        }
    },
    components: {
        BaseTableColumnFeaturedPostsModal
    },
    data(){
        return {
            modalVisible: false,
            uploadingPosts: [],
            isArray
        }
    },
    methods: {
        uploadPosts(){
            if(isValidArray(this.value)){
                for (const post of this.value) {
                    this.uploadingPosts.push(post.url);
                    this.$http.post('/report-user-post/pre-upload', {
                        url: post.url,
                        handle: this.handle
                    }).then(({ data }) => {
                        if(data){
                            console.log('Post uploaded successfully', data);
                        }
                    }).catch(err => {
                        console.log('Error while uploading post', err);
                    }).finally(() => {
                        this.uploadingPosts = this.uploadingPosts.filter(element => element != post.url);
                    });  
                }
            }
        },
        getMediaData(post){

            // media url, type and mimetype
            let media_url = null;
            let media_mimetype = null;
            let is_video = false;
            
            if(post.media_type == "CAROUSEL_ALBUM" && post.children && post.children.data){
                const children = post.children.data.filter(element => element.media_url && element.media_type);
                if(children.length){
                    media_url = children[0].media_url;
                    if(children[0].media_type == "IMAGE"){
                        media_mimetype = "image/png"
                    }
                    if(children[0].media_type == "VIDEO"){
                        media_mimetype = "video/mp4"
                        is_video = true
                    }
                }
            }else if(post.media_url){
                media_url = post.media_url;
                if(post.media_type == "IMAGE"){
                    media_mimetype = "image/png"
                }
                if(post.media_type == "VIDEO"){
                    media_mimetype = "video/mp4"
                    is_video = true
                }
            }

            if(!media_url || !media_mimetype){
                // try to use the thumbnail
                if(isValidString(post.thumbnail_url)){
                    media_url = post.thumbnail_url;
                }else{
                    media_url = defaultImage;
                }
                media_mimetype = "image/png"
            } 
            
            return {
                "media_url": media_url,
                "media_mimetype": media_mimetype,
                "is_video": is_video
            }

        },
        openUrl(url){        
            window.open(url, '_blank')
        }
    }
}
</script>
<style lang="scss" scoped>
    .featured-posts{
        .list{
            @apply flex gap-x-2 items-center;
            @apply cursor-pointer;
            &:hover{
                > .item{
                    @apply bg-gray-400;
                }
            }
            .item{
                @apply relative;
                @apply bg-gray-300 rounded-md flex justify-center items-center;
                @apply transition-colors duration-200;
                width: 39px;
                height: 54px;
                > .uploading-wrapper{
                    @apply absolute inset-0;
                    z-index: 10;
                    > .uploading-container{
                        @apply relative w-full h-full;
                        > .centerer{
                            @apply absolute inset-0 flex justify-center items-center;
                            > .uploading-background{
                                @apply flex justify-center items-center bg-white rounded-full;
                                width: 28px;
                                height: 28px;
                            }
                            > .uploading-icon{ 
                                animation: breathe 2s ease-out infinite;
                            } 
                        }
                    }
                }
                > .tooltip{
                    @apply invisible absolute cursor-pointer bg-white rounded-lg border border-gray-400 py-2 px-4;
                    @apply flex justify-center items-center;
                    @apply font-bold text-pxs text-blue-500;
                    @apply transition-colors duration-200;
                    &:hover{
                        @apply text-blue-700;
                    }
                }
                &:hover{
                    > .tooltip{
                        @apply visible;
                        top: -32px;
                    }
                }
            }
            > .additional{
                @apply flex justify-center items-center;
                > .title{
                    @apply text-center font-bold;
                }
            }
        }
        .empty-state{
            @apply flex flex-col items-center gap-y-1;
            > .title{
                @apply font-bold;
            }
        }
    }

    @keyframes breathe {
        0% {
            opacity: 0.30;
        }
        50% {   
            opacity: 1;
        }
        100% {
            opacity: 0.30;
        }
    }
</style>

<template>
    <div class="flex progress-wrapper" :class="{ 'force-visible': forceVisible}">
        <div 
            class="label wider"
            v-tooltip="{ content: `${(item.name || item.code)}`, classes: 'bg-black text-white rounded py-1 px-2 text-xs', delay: { show: 100, hide: 50 } }"
        >
            {{ (item.name || item.code).toLowerCase() }}
        </div>
        <div class="progress-simulator">
            <div class="progress-value" :style="progressValue"></div>
        </div>
        <div class="display text-black pl-2">{{ item.weight | numeral('0.0%') }}</div>
    </div>
</template>

<script>
    export default {
        props: {
            item: {},
            forceVisible: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            progressValue() {
                let value = (this.item.weight || 0) * 100;
                return {
                    width: `${value}%`
                }
            }
        }
    };
</script>

<style lang="scss">
    .progress-wrapper {
        display: flex;
        font-size: 14px;
        color: #596b88;
        align-items: center;
        margin-bottom: 4px;

        .label {
            @apply cursor-pointer text-left pr-2 font-bold capitalize truncate w-48;
            min-width: 50px;
            font-size: 14px;

            &.wider {
                min-width: 100px;
                max-width: 100px;
            }
        }

        .display {
            font-weight: bold;
            text-align: right;
            font-size: 13px;
            width: 50px;
        }
        
        .progress-simulator {
            @apply h-4 w-full relative;
            background: #D8D8D8;

            .progress-value {
                @apply absolute h-4 left-0;
                background: #70F880;
            }
        }
    }

    .progress-wrapper:not(.force-visible):nth-of-type(1n+8) {
        display: none !important;
    }
</style>
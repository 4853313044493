<template>
    <base-modal 
        title="Custom Fields" 
        :visible="visible"
        @close="close()"
        styles="max-height: 90vh; overflow-y: auto; min-width: 60vw; width: 60vw;"
    >
        <div 
            class="additional-info" 
            v-if="user && user.report_user && user.report_user.id"
        >

            <div class="header">
                <div class="title">Select the creator's custom fields you want for this report</div>
                <div class="actions">
                    <base-button
                        label="Add Info"
                        bold
                        icon="plus"
                        @action="createAdditionalInfoModalVisible = true"
                    />
                </div>
            </div>
            
            <div class="content">
                <div 
                    class="empty-state" 
                    v-if="!isArray(userVal.additional_info)"
                >
                    No additional info
                </div>
                <div 
                    v-else 
                    class="info-list"
                >
                    <!-- view parsed info, edit info, delete them, create -->
                    <div
                        class="info-item"
                        :class="{ 'active': isSelected(info) }"
                        v-for="(info, index) in userVal.additional_info" 
                        :key="`info-${index}`"
                        @click="toggleInfo(info)"
                    >
                        <div class="content">
                            <div class="name">{{info.name}}</div>
                            <!-- link type -->
                            <a 
                                v-if="info.type_detail.slug == 'link'" 
                                :href="info.value.split('|')[1]"
                                target="_blank"
                                class="value link"
                            >
                                {{info.value.split('|')[0]}}
                            </a>
                            <!-- other values -->
                            <div v-else class="value">{{ info.value }}</div>
                        </div>
                        <div @click.stop class="actions">
                            <options-dropdown               
                                :options="[
                                    {label: 'Edit', event: 'edit'},
                                    {label: 'Delete', event: 'delete'}
                                ]"
                                :theme="isSelected(info) ? 'dark' : 'light'"
                                @edit="openCreateAdditionalInfoModal(info)"
                                @delete="deleteAdditionalInfoIntent(info.id)"
                            />
                        </div>
                    </div>

                </div>
            </div>

            <div class="footer">
                <base-button
                    label="Save"
                    bold
                    @action="submit()"
                />
            </div>

            <create-additional-info-modal
                :visible="createAdditionalInfoModalVisible"
                :user-id="user.id"
                :current-additional-info="targetAdditionalInfo"
                @reload="getAdditionalInfo()"
                @close="closeCreateAdditionalInfoModal()"
            />

        </div>
    </base-modal>
</template>

<script>

    import { isArray, notifyCatchError } from '@/common';
    import CreateAdditionalInfoModal from '../CreatorDetail/InfoTab/CreateAdditionalInfoModal.vue'
    export default {
        components: { 
            CreateAdditionalInfoModal 
        },
        name: 'CustomFieldsModal',
        props: ['visible', 'user'],
        watch: {
            user(val){
                // copy the object
                this.userVal = val;
            },
            visible(val){
                if(val == true){
                    this.getAdditionalInfo();
                }
            }
        },
        data(){
            return {
                userVal: this.user,
                selectedAdditionalInfo: [],
                createAdditionalInfoModalVisible: false,
                targetAdditionalInfo: null,
                loading: false,
                isArray
            }
        },
        methods: {

            submit(){
                this.loading = true;
                this.$http.put(`/report-user-additional-info/${this.user.report_user.id}`, {
                    additional_info: this.selectedAdditionalInfo
                }).then(({ data }) => {
                    this.$notify({title: 'Info saved successfully', text: '', type: 'success'})
                    this.$forceUpdate();              
                }).catch(err => {
                    notifyCatchError(err, this.$notify);
                    console.log('submit error', err);
                }).finally(() => {
                    this.loading = false;
                });
                return;
            },

            toggleInfo(info){
                if(this.isSelected(info)){
                    this.selectedAdditionalInfo = this.selectedAdditionalInfo.filter(element => element.id !== info.id);
                }else{
                    this.selectedAdditionalInfo.push(info);
                }
                this.$emit('change', this.selectedAdditionalInfo);
            },
            
            // like toggle info but you can set the value specifically
            setInfo(info, val = true){
                if(val == true){
                    this.selectedAdditionalInfo.push(info);
                }else{
                    this.selectedAdditionalInfo = this.selectedAdditionalInfo.filter(element => element.id !== info.id);
                }
                this.$emit('change', this.selectedAdditionalInfo);
            },

            isSelected(info){
                const found = this.selectedAdditionalInfo.find(element => element.id == info.id);
                if(found){
                    return true;
                }
                return false;
            },

            // probably wont need this
            getAdditionalInfo(){
                this.loading = true;
                this.$http.get(`/user-additional-info/${this.user.id}`).then(({ data }) => {
                    this.userVal.additional_info = data
                    if(this.user && this.user.report_user && isArray(this.user.report_user.additional_info)){
                        for (const additional_info of this.user.report_user.additional_info) {
                            const foundInfo = this.userVal.additional_info.find(element => element.id == additional_info.id);
                            if(foundInfo){
                                this.setInfo(foundInfo, true);
                            }
                        }
                    }
                    this.$forceUpdate();              
                }).catch(err => {
                    console.log('getAdditionalInfo error', err);
                }).finally(() => {
                    this.loading = false;
                });
                return;
            },

            deleteAdditionalInfoIntent(id){
                this.$swal.fire({
                    title: 'Are you sure you want to delete this information for this creator?',
                    text: `Changes made will also be reflected on this creator's reports`,
                    icon: 'warning',
                    iconColor: '#0E092C',
                    showCancelButton: true,
                    confirmButtonText: 'Delete',
                    reverseButtons: true,
                }).then((result) => {
                    if(result.isConfirmed){
                        this.deleteAdditionalInfo(id)
                    }
                })
            },

            async deleteAdditionalInfo(id){
                this.loading = true;
                this.$http.delete(`/user-additional-info/${id}`).then(async (res) => {
                    if(res.data){
                        this.$notify({title: 'Info deleted successfully', text: '', type: 'success'})
                        this.getAdditionalInfo();
                    }
                }).catch((err) => {
                    console.log('deleteAdditionalInfo error', err)
                    notifyCatchError(err, this.$notify);
                }).finally(() => {
                    this.loading = false
                })
            },
            
            openCreateAdditionalInfoModal(info){
                this.targetAdditionalInfo = info;
                this.createAdditionalInfoModalVisible = true;
            },

            closeCreateAdditionalInfoModal(){
                this.targetAdditionalInfo = null;
                this.createAdditionalInfoModalVisible = false;
            },

            close(){
                this.selectedAdditionalInfo = []
                this.createAdditionalInfoModalVisible = false;
                this.targetAdditionalInfo = null;
                this.loading = false;
                this.$emit('close');
            }
        }
    }
</script>
<style lang="scss" scoped>
    .additional-info{
        > .header{
            @apply w-full mb-4 flex justify-between items-center;
            > .title{
                @apply font-bold text-h5;
            }
        }
        > .content{
            @apply mt-8 mb-10;
            > .empty-state{
                @apply;
            }
            > .info-list{
                @apply flex flex-wrap gap-3;
                > .info-item{
                    @apply py-3 px-6;
                    width: 320px;
                    @apply flex gap-x-10 justify-between items-center; 
                    @apply cursor-pointer rounded-lg transition-colors duration-300;
                    &.active{
                        @apply bg-purple-m-main text-white;
                    }
                    > .content{
                        @apply overflow-hidden flex flex-col;
                        > .name{
                            @apply font-bold;
                        }
                        > .value{
                            text-overflow: ellipsis;
                            overflow: hidden;
                            &.link{
                                @apply font-bold text-blue-500 cursor-pointer;
                                &:hover{
                                    @apply opacity-70;
                                }
                            }
                        }
                    }
                    > .actions{
                        @apply invisible;
                    }
                    &:hover{
                        @apply opacity-80;
                        > .actions{
                            @apply visible;
                        }
                    }
                }
            }
        }
        > .footer{
            @apply flex justify-end;
        }
    }
</style>

<template>
   <div class="post" >
        <template v-if="value.found && value.preview">
            <!-- video or image -->
            <template>
                <video v-if="post.is_video" autoplay muted>
                    <source :src="post.media_url" type="video/mp4"/>
                </video>
                <img v-else :src="post.media_url" />
            </template>
            <!-- date -->
            <div class="date">{{post.date}}</div>
            <!-- caption -->
            <div 
                ref="caption"
                :class="['caption', { 'not-expanded': !expanded } ]"
            >
                {{ parseUnicodeCharacters(post.caption) }}
            </div>
            <!-- show more button -->
            <div class="show-more" v-if="isExpandable">
                <span @click.stop="expanded = !expanded">{{ expanded ? 'Collapse' : 'Show more' }}</span>
            </div>
        </template>
        <template v-else>
            <!-- if the post was not found or it does not have a preview it will still be visible -->
            <div class="default">
                <base-icon
                    :name="value.network_name"
                />
            </div>
        </template>
        <!-- view on instagram button -->
        <div @click.stop="()=>null">
            <base-button
                label="View on IG"
                type="secondary"
                class="h-9 font-bold text-pxs py-0 mx-auto mt-2"
                @action="viewOnInstagram()"
            />
        </div>
        <!-- add featured post button -->
        <div @click.stop="()=>null">
            <base-button
                label="Add post"
                type="secondary"
                :disabled="disabled"
                class="h-9 font-bold text-pxs py-0 mx-auto mt-2"
                @action="$emit('add-post'); $emit('close');"
            />
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import { parseUnicodeCharacters } from '../../lib/strings'
import { isValidString } from '../../common';
    const defaultImage = require('../../assets/images/default-picture.png');
    export default {
        name: 'AddFeaturedPostModalItem',
        props: ['value', 'readonly', 'disabled'],
        data() {
            return {
                isExpandable: false,
                expanded: false,
                defaultImage,
                key: false,
                parseUnicodeCharacters
            }
        },
        // wait for caption element to be fully rendered to check if the caption is expandable
        mounted(){
            this.$nextTick(() => {
                this.checkIsExpandable();
            });
        },
        computed: {
            post(){
                let post = this.value.preview
                // media url, type and mimetype
                let media_url = null;
                let media_mimetype = null;
                let is_video = false;
                
                if(post.media_type == "CAROUSEL_ALBUM" && post.children && post.children.data){
                    const children = post.children.data.filter(element => element.media_url && element.media_type);
                    if(children.length){
                        media_url = children[0].media_url;
                        if(children[0].media_type == "IMAGE"){
                            media_mimetype = "image/png"
                        }
                        if(children[0].media_type == "VIDEO"){
                            media_mimetype = "video/mp4"
                            is_video = true
                        }
                    }
                }else if(post.media_url){
                    media_url = post.media_url;
                    if(post.media_type == "IMAGE"){
                        media_mimetype = "image/png"
                    }
                    if(post.media_type == "VIDEO"){
                        media_mimetype = "video/mp4"
                        is_video = true
                    }
                }

                if(!media_url || !media_mimetype){
                    // try to use the thumbnail
                    if(isValidString(post.thumbnail_url)){
                        media_url = post.thumbnail_url;
                    }else{
                        media_url = defaultImage;
                    }
                    media_mimetype = "image/png"
                } 

                // date
                let date = moment(post.timestamp).format('LL');

                return {
                    "id": post.id,
                    "url": post.permalink,
                    "caption": post.caption,
                    "date": date,
                    "likes": post.like_count,
                    "comments": post.comments_count,
                    "media_url": media_url,
                    "media_mimetype": media_mimetype,
                    "is_video": is_video
                }
            }
        },
        methods: {
            checkIsExpandable(){
                const { caption } = this.$refs
                const captionHeight = caption && caption.offsetHeight ? caption.offsetHeight : null
                if (captionHeight && captionHeight >= 72) {
                    this.isExpandable = true;
                }
            },
            viewOnInstagram(){
                window.open(this.value.url, '_blank')
            }
        }
    }
</script>
<style lang="scss" scoped>
    .post{
        @apply relative rounded-md p-4 cursor-pointer transition-colors duration-200;
        @apply flex flex-col overflow-hidden;
        width: calc(150px + 2rem);
        &:hover{
            @apply bg-gray-200;
        }
        > .default{
            @apply bg-gray-300 rounded-lg text-white flex justify-center items-center;
            width: 150px; 
            height: 150px;
        }
        > video, img{
            @apply rounded-md;
            width: calc(150px);
            height: calc(150px);
        }
        > .date{
            @apply text-right text-pxs mt-2 font-bold;
        }
        > .caption{
            @apply mt-1 text-gray-m-disable text-sm overflow-x-hidden leading-6;
            text-overflow: ellipsis;
            // initial state
            &.not-expanded {
                @apply overflow-y-hidden max-h-20;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                transition: max-height 3s cubic-bezier(0, 1, 0, 1);
            }
        }
        .show-more{
            @apply text-right;
            > span{
                @apply cursor-pointer text-xs font-bold text-gray-800;
            }
        }
    }
</style>

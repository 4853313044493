<template>
    <div class="general-form">
        
        <!-- Report name -->
        <FormulateInput
            type="text"
            label="Report Name"
            class="w-full"
            placeholder="Enter report name"
            validation="required"
            validation-name="Report name"
            :value="name"
            :key="key"
            @input="val => $emit('update:name', val)"
        />
        
        <!-- Report type (static value) -->
        <custom-select
            label="Report Type"
            :options="[]"
            disabled
            :value="{ label: 'Creators', value: 1 }"
        />

        <!-- Report's project (static value) -->
        <custom-select
            v-if="projectSlug && project"
            label="Project"
            :options="[]"
            disabled
            :value="project"
        />
        
        <!-- Report tag selection -->
        <custom-pills
            class="w-full"
            label="Tags"
            placeholder="Select the report tag(s)"
            
            :options="tagOptions"
            :value="tags"

            search
            :multiple="true"
            size="auto"

            allow-create
            create-label="Create new tag"
            model-name="Tag"
            :create-route="`/api/tags`"
            :create-body="{type: 'reports'}"

            @input="val => $emit('update:tags', val)"
            @finish-create="getTags"

        />

        <!-- Report description -->
        <vue-editor 
            :value="description"
            placeholder="Description" 
            :editorToolbar="$customToolbar()"
            @input="val => $emit('update:description', val)"
        />
        
        <!-- Post Limit -->
        <div class="col-span-1">
            <custom-select
                label="Posts limit"
                placeholder="Posts limit"
                :options="postLimitOptions"
                :value="postsLimit"
                @input="val => $emit('update:posts-limit', val)"
            />
        </div>
        
        <!-- Password protection section -->
        <div class="col-span-2 flex gap-5">

            <div style="width: calc(50% - 10px);">
                <FormulateInput
                    type="checkbox"
                    label="Protect with password"
                    class="flex items-center label-alone"
                    :style="!passwordProtected ? 'margin-bottom: -15px;' : ''"
                    :value="passwordProtected"
                    :checked="passwordProtected"
                    @input="val => $emit('update:password-protected', val)"
                />
                <FormulateInput
                    v-if="passwordProtected"
                    type="text"
                    :value="password"
                    placeholder="Enter password"
                    wrapper-class="border rounded-md py-2 px-3 bg-white h-16 flex items-center mt-2"
                    @input="val => $emit('update:password', val)"
                />

            </div>

        </div>

        <!-- Other advanced settings section -->
        <div class="col-span-2">

            <!-- Enable feedback section -->            
            <FormulateInput
                type="checkbox"
                label="Enable creator feedback"
                :value="allowFeedback"
                :checked="allowFeedback"
                @input="val => $emit('update:allow-feedback', val)" 
            />

            <!-- Manage feedback section -->
            <div 
                class="border-b border-gray-200" 
                style="width: calc(50% - 10px); padding-left: 33px;padding-top: 5px; padding-bottom: 22px; margin-bottom: 22px;" 
                v-if="allowFeedback"
            >

                <div class="text-ps">Please select the questions you want to include in the report</div>

                <FormulateInput
                    v-for="(item, index) in feedback"
                    :key="`checkbox-${index}`"
                    type="checkbox"
                    :label="`${item.label} (${feedbackOptionLabel(item.type)})`"
                    :value="item.active"
                    :checked="item.active"
                    @input="val => $emit('update:feedback', index, val)"
                />

                <div class="mt-2 w-full" v-if="creatingFeedbackOption">
                    <div class="flex gap-4">
                        <FormulateInput
                            label="Field"
                            type="text"
                            placeholder="Enter"
                            class="w-3/5"
                            v-model="newFeedbackOptionField"
                        />
                        <custom-select
                            label="Type"
                            placeholder="Select"
                            :options="feedbackOptionTypes"
                            v-model="newFeedbackOptionType"
                            class="w-2/5"
                        />
                    </div>
                    <div class="mt-3 flex gap-3 justify-end">
                        <base-button 
                            label="Cancel"
                            @action="cancelFeedbackOptionCreation" 
                            type="label" 
                            size="sm" 
                        />
                        <base-button 
                            label="Save"
                            @action="createFeedbackOption" 
                            type="label" 
                            size="sm"  
                            :disabled="!newFeedbackOptionField || !newFeedbackOptionType"
                        />
                    </div>
                </div>

                <div v-else class="mt-3">
                    <base-button 
                        label="Add Option"
                        @action="creatingFeedbackOption = true" 
                        type="secondary"
                        icon-size="4"
                        size="lg"
                        class="border border-gray-500"
                        style="padding: 12px 0px;width: 160px;"
                        icon="plus-circle"
                    />
                </div>
            
            </div>
            
            <!-- Show boosted data setting -->
            <FormulateInput
                type="checkbox"
                label="Show organic + boosted stat breakdown and post ads"
                :value="showBoostedData"
                :checked="showBoostedData"
                @input="val => $emit('update:show-boosted-data', val)"
            />
            
            <!-- Hide stats setting -->
            <FormulateInput
                type="checkbox"
                label="Hide stats on each individual post"
                :value="hideStats"
                :checked="hideStats"
                @input="val => $emit('update:hide-stats', val)"
            />
            
            <!-- Show users without audience data setting -->
            <FormulateInput
                type="checkbox"
                label="Show users that don't have audience data"
                :value="preview"
                :checked="preview"
                @input="val => $emit('update:preview', val)"
            />

        </div>
    </div>
</template>
<script>
import { notifyCatchError } from '../../common';
const postLimitOptions = [
    { id: 3, label: 3 }, { id: 4, label: 4 }, { id: 5, label: 5 },
    { id: 6, label: 6 }, { id: 7, label: 7 }, { id: 8, label: 8 },
    { id: 9, label: 9 }
];
const feedbackOptionTypes = [
    { id: 1, label: 'Rank 1-5' },
    { id: 2, label: 'Free Text' },
]
export default {
    props: [
        'name',
        'type',
        'projectSlug',
        'tags',
        'description',
        'passwordProtected',
        'password',

        'postsLimit',
        'allowFeedback',
        'feedback',
        'showBoostedData',
        'hideStats',
        'preview'

    ],
    data(){
        return {
            project: null,
            loadingProject: false,
            
            tagOptions: [],
            loadingTags: false,

            creatingFeedbackOption: false,
            newFeedbackOptionField: '',
            newFeedbackOptionType: null,

            postLimitOptions,
            feedbackOptionTypes,

            key: false
        }
    },
    mounted(){
        this.getProject();
        this.getTags();
    },
    watch: {
        name(val, old){
            if(val && val !== old){
                this.key = !this.key;
            }
        }
    },
    methods: {
        getProject(){
            if(this.projectSlug){
                this.loadingProject = true;
                this.$http.get(`/projects/${this.projectSlug}/single`).then(({ data }) => {
                    this.project = {
                        label: data.name,
                        value: data.id
                    };
                    this.$emit('update:project-id', data.id);
                    this.$emit('update:project-name', data.name);
                }).catch(err => {
                    notifyCatchError(err, this.$notify);
                }).finally(() => {
                    this.loadingProject = false;
                });
            }
        },
        getTags(){
            this.loadingTags = true;
            this.$http.get('/api/tags?type=reports').then(({ data }) => {
                this.tagOptions = data.map(tag => {
                    return {
                        value: tag.id,
                        label: tag.name
                    }
                });
            }).catch(err => {
                notifyCatchError(err, this.$notify);
            }).finally(() => {
                this.loadingTags = false;
            });
        },
        feedbackOptionLabel(type) {
            let option = this.feedbackOptionTypes.find(t => t.id === type);
            if (option) {
                return option.label;
            }
            return '';
        },
        createFeedbackOption() {
            this.$emit('create-feedback-option', {
                id: this.feedback.length + 1,
                active: true,
                label: this.newFeedbackOptionField,
                type: this.newFeedbackOptionType.id
            });
            this.cancelFeedbackOptionCreation();
        },
        // reset values
        cancelFeedbackOptionCreation() {
            this.newFeedbackOptionField = '';
            this.newFeedbackOptionType = null;
            this.creatingFeedbackOption = false;
        }
    }
}
</script>
<style lang="scss" scoped>
    .general-form{
        @apply grid grid-cols-2 gap-x-5 gap-y-4;
    }
</style>
<template>
    <base-modal
        :visible="open"
        @close="$emit('close')"
        title="Advanced Audience Insights"
    >
        <div class="flex flex-col justify-between" :key="!open" v-if="audience.payload">
          <div class="grid grid-cols-6 gap-5">
            <div class="field col-span-6">
              <img v-if="uploadingFile" class="h-auto w-5" src="../../assets/images/spinner.gif" />
              <img :src="profileUrl" class="w-32 h-32 rounded cursor-pointer border-2 border-dashed object-contain" @click="$refs.file.click()"/>
              <input class="invisible h-0" type="file" ref="file" accept="image/*" :disabled="uploadingFile" @change="uploadAudienceProfilePicture">
            </div>
            <div class="field col-span-2">
              <div class="text-h6 font-bold">Avg Likes</div>
              <FormulateInput type="text" placeholder="Avg Likes" v-model="audience.payload.user_profile.avg_likes"/>
            </div>
            <div class="field">
              <div class="text-h6 font-bold">Followers</div>
              <FormulateInput type="text" placeholder="Followers" @keyup="processEngagementRate" v-model="audience.payload.user_profile.followers"/>
            </div>
            <div class="field col-span-2">
              <div class="text-h6 font-bold">Engagements</div>
              <FormulateInput type="text" placeholder="Engagements" @keyup="processEngagementRate" v-model="audience.payload.user_profile.engagements"/>
            </div>
            <div class="field">
              <div class="text-h6 font-bold">Eng. Rate</div>
              <FormulateInput type="text" placeholder="Eng. Rate" v-model="audience.payload.user_profile.engagement_rate"/>
            </div>
            <div class="field">
              <div class="text-h6 font-bold">City</div>
              <FormulateInput type="text" placeholder="Location (City)" v-model="audience.payload.user_profile.geo.city.name"/>
            </div>
            <div class="field">
              <div class="text-h6 font-bold">State</div>
              <FormulateInput type="text" placeholder="Location (State)" v-model="audience.payload.user_profile.geo.state.name"/>
            </div>
            <div class="field">
              <div class="text-h6 font-bold">Country</div>
              <FormulateInput type="text" placeholder="Location (Country)" v-model="audience.payload.user_profile.geo.country.name"/>
            </div>
            <div class="field col-span-3">
              <div class="text-h6 font-bold">Description</div>
              <FormulateInput type="text" placeholder="Description" v-model="audience.payload.user_profile.description"/>
            </div>
          </div>
          <div class="cursor-pointer font-bold mt-2 flex items-center gap-3" @click="postsOpen = !postsOpen">
            <base-icon :name="!postsOpen ? 'chevron-down' : 'chevron-up'"/>
            Posts
          </div>
          <div :class="{'hidden': !postsOpen}" class="flex flex-col gap-3">
            <div class="flex gap-3 items-center ">
              <FormulateInput
                  type="url"
                  v-model="link"
                  class="w-full"
                  :disabled="savingPost"
                  label="Post link"
                  v-debounce:300="getMeta"
                  @keyup.enter="addPost"
                  placeholder="Enter post link"
              />
              <base-button @action="addPost" :icon="linkAccepted ? 'check' : 'close'" :label="searchingLink ? 'Searching' : 'Add'" :disabled="!linkAccepted"/>
            </div>
            <div class="flex flex-col gap-2" v-if="audience.payload.user_profile.top_posts">
              <div class="flex gap-2" v-for="post of audience.payload.user_profile.top_posts" :key="post.id">
                <div>
                  <img class="object-cover animate-fade-in post-report-modal-img w-7 h-7" width="100%" :src="post.image"/>
                </div>
                <div class="px-3 flex flex-col gap-1">
                  <div class="flex items-center gap-2">
                    <p class="text-xs">{{ post.username }}</p>
                  </div>
                  <div class="text-gray-m-disable caption-wrapper text-xs">
                    {{ post.text }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="cursor-pointer font-bold mt-2 flex items-center gap-3" @click="audience_geo = !audience_geo">
            <base-icon :name="!audience_geo ? 'chevron-down' : 'chevron-up'"/>
            Audience Geo
          </div>
          <div :class="{'hidden': !audience_geo}" class="grid grid-cols-3 gap-5">
            <div>
              <div class="font-bold flex gap-2 cursor-pointer" @click="addProperty('City', 'payload.audience_followers.data.audience_geo.cities', ['name', 'weight'])">
                <base-icon name="plus-circle"/>
                <span>Cities</span>
              </div>
              <div v-if="audience.payload.audience_followers && audience.payload.audience_followers.data && audience.payload.audience_followers.data.audience_geo && audience.payload.audience_followers.data.audience_geo.cities">
                <div class="text-xs flex justify-between" :key="c_key" v-for="(city, c_key) in audience.payload.audience_followers.data.audience_geo.cities">
                  <div class="cursor-pointer" @click="removeProperty(audience.payload.audience_followers.data.audience_geo.cities, c_key)"><base-icon :size="3" name="trash"/></div>
                  <div>{{ city.name || city.code }}</div>
                  <div>{{ (city.weight * 100).toFixed(2) }}%</div>
                  <div class="cursor-pointer" @click="editProperty('City', 'payload.audience_followers.data.audience_geo.cities', audience.payload.audience_followers.data.audience_geo.cities, ['name', 'weight'], c_key)"><base-icon :size="3" name="pencil"/></div>
                </div>
              </div>
            </div>
            <div>
              <div class="font-bold flex gap-2 cursor-pointer" @click="addProperty('State', 'payload.audience_followers.data.audience_geo.states', ['name', 'weight'])">
                <base-icon name="plus-circle"/>
                <span>States</span>
              </div>
              <div v-if="audience.payload.audience_followers && audience.payload.audience_followers.data && audience.payload.audience_followers.data.audience_geo && audience.payload.audience_followers.data.audience_geo.states">
                <div class="text-xs flex justify-between" :key="s_key" v-for="(state, s_key) in audience.payload.audience_followers.data.audience_geo.states">
                  <div class="cursor-pointer" @click="removeProperty(audience.payload.audience_followers.data.audience_geo.states, s_key)"><base-icon :size="3" name="trash"/></div>
                  <div>{{ state.name || state.code }}</div>
                  <div>{{ (state.weight * 100).toFixed(2) }}%</div>
                  <div class="cursor-pointer" @click="editProperty('State', audience.payload.audience_followers.data.audience_geo.states, ['name', 'weight'], s_key)"><base-icon :size="3" name="pencil"/></div>
                </div>
              </div>
            </div>
            <div>
              <div class="font-bold flex gap-2 cursor-pointer" @click="addProperty('Country', 'payload.audience_followers.data.audience_geo.countries', ['name', 'weight'])">
                <base-icon name="plus-circle"/>
                <span>Countries</span>
              </div>
              <div v-if="audience.payload.audience_followers && audience.payload.audience_followers.data && audience.payload.audience_followers.data.audience_geo && audience.payload.audience_followers.data.audience_geo.countries">
                <div class="text-xs flex justify-between" :key="co_key" v-for="(country, co_key) in audience.payload.audience_followers.data.audience_geo.countries">
                  <div class="cursor-pointer" @click="removeProperty(audience.payload.audience_followers.data.audience_geo.countries, co_key)"><base-icon :size="3" name="trash"/></div>
                  <div>{{ country.name || country.code }}</div>
                  <div>{{ (country.weight * 100).toFixed(2) }}%</div>
                  <div class="cursor-pointer" @click="editProperty('Country', audience.payload.audience_followers.data.audience_geo.countries, ['name', 'weight'], co_key)"><base-icon :size="3" name="pencil"/></div>
                </div>
              </div>
            </div>
          </div>
          <div class="cursor-pointer font-bold mt-2 flex items-center gap-3" @click="audience_ages = !audience_ages">
            <base-icon :name="!audience_ages ? 'chevron-down' : 'chevron-up'"/>
            Audience Ages
          </div>
          <div :class="{'hidden': !audience_ages}" class="grid gap-5">
            <div>
              <div class="font-bold flex gap-2 cursor-pointer" @click="addProperty('Audience Age', 'payload.audience_followers.data.audience_ages', ['code', 'weight'])">
                <base-icon name="plus-circle"/>
                <span>Audience Ages</span>
              </div>
              <div v-if="audience.payload.audience_followers && audience.payload.audience_followers.data && audience.payload.audience_followers.data.audience_ages">
                <div class="text-xs flex justify-between" :key="age_key" v-for="(age, age_key) in audience.payload.audience_followers.data.audience_ages">
                  <div class="cursor-pointer" @click="removeProperty(audience.payload.audience_followers.data.audience_ages, age_key)"><base-icon :size="3" name="trash"/></div>
                  <div>{{ age.name || age.code }}</div>
                  <div>{{ (age.weight * 100).toFixed(2) }}%</div>
                  <div class="cursor-pointer" @click="editProperty('Audience Age', audience.payload.audience_followers.data.audience_ages, ['code', 'weight'], age_key)"><base-icon :size="3" name="pencil"/></div>
                </div>
              </div>
            </div>
          </div>
          <div class="cursor-pointer font-bold mt-2 flex items-center gap-3" @click="audience_interests = !audience_interests">
            <base-icon :name="!audience_interests ? 'chevron-down' : 'chevron-up'"/>
            Audience Interest
          </div>
          <div :class="{'hidden': !audience_interests}" class="grid gap-5">
            <div>
              <div class="font-bold flex gap-2 cursor-pointer" @click="addProperty('Interest', 'payload.audience_followers.data.audience_interests', ['name', 'weight'])">
                <base-icon name="plus-circle"/>
                <span>Audience Interest</span>
              </div>
              <div v-if="audience.payload.audience_followers && audience.payload.audience_followers.data && audience.payload.audience_followers.data.audience_interests">
                <div class="text-xs flex justify-between" :key="interest_key" v-for="(interest, interest_key) in audience.payload.audience_followers.data.audience_interests">
                  <div class="cursor-pointer" @click="removeProperty(audience.payload.audience_followers.data.audience_interests, interest_key)"><base-icon :size="3" name="trash"/></div>
                  <div>{{ interest.name || interest.code }}</div>
                  <div>{{ (interest.weight * 100).toFixed(2) }}%</div>
                  <div class="cursor-pointer" @click="editProperty('Interest', audience.payload.audience_followers.data.audience_interests, ['name', 'weight'], interest_key)"><base-icon :size="3" name="pencil"/></div>
                </div>
              </div>
            </div>
          </div>
          <div class="cursor-pointer font-bold mt-2 flex items-center gap-3" @click="audience_languages = !audience_languages">
            <base-icon :name="!audience_languages ? 'chevron-down' : 'chevron-up'"/>
            Audience Languages
          </div>
          <div :class="{'hidden': !audience_languages}" class="grid gap-5">
            <div>
              <div class="font-bold flex gap-2 cursor-pointer" @click="addProperty('Language', 'payload.audience_followers.data.audience_languages', ['name', 'weight'])">
                <base-icon name="plus-circle"/>
                <span>Audience Language</span>
              </div>
              <div v-if="audience.payload.audience_followers && audience.payload.audience_followers.data && audience.payload.audience_followers.data.audience_languages">
                <div class="text-xs flex justify-between" :key="language_key" v-for="(language, language_key) in audience.payload.audience_followers.data.audience_languages">
                  <div class="cursor-pointer" @click="removeProperty(audience.payload.audience_followers.data.audience_languages, language_key)"><base-icon :size="3" name="trash"/></div>
                  <div>{{ language.name || language.code }}</div>
                  <div>{{ (language.weight * 100).toFixed(2) }}%</div>
                  <div class="cursor-pointer" @click="editProperty('Language', audience.payload.audience_followers.data.audience_languages, ['name', 'weight'], language_key)"><base-icon :size="3" name="pencil"/></div>
                </div>
              </div>
            </div>
          </div>
          <div class="cursor-pointer font-bold mt-2 flex items-center gap-3" @click="audience_ethnicities = !audience_ethnicities">
            <base-icon :name="!audience_ethnicities ? 'chevron-down' : 'chevron-up'"/>
            Audience Ethnicities
          </div>
          <div :class="{'hidden': !audience_ethnicities}" class="grid gap-5">
            <div>
              <div class="font-bold flex gap-2 cursor-pointer" @click="addProperty('Ethnicity', 'payload.audience_followers.data.audience_ethnicities', ['name', 'weight'])">
                <base-icon name="plus-circle"/>
                <span>Audience Ethnicity</span>
              </div>
              <div v-if="audience.payload.audience_followers && audience.payload.audience_followers.data && audience.payload.audience_followers.data.audience_ethnicities">
                <div class="text-xs flex justify-between" :key="ethnicity_key" v-for="(ethnicity, ethnicity_key) in audience.payload.audience_followers.data.audience_ethnicities">
                  <div class="cursor-pointer" @click="removeProperty(audience.payload.audience_followers.data.audience_ethnicities, ethnicity_key)"><base-icon :size="3" name="trash"/></div>
                  <div>{{ ethnicity.name || ethnicity.code }}</div>
                  <div>{{ (ethnicity.weight * 100).toFixed(2) }}%</div>
                  <div class="cursor-pointer" @click="editProperty('Ethnicity', audience.payload.audience_followers.data.audience_ethnicities, ['name', 'weight'], ethnicity_key)"><base-icon :size="3" name="pencil"/></div>
                </div>
              </div>
            </div>
          </div>
          <div class="cursor-pointer font-bold mt-2 flex items-center gap-3" @click="audience_reachability = !audience_reachability">
            <base-icon :name="!audience_reachability ? 'chevron-down' : 'chevron-up'"/>
            Audience Reachability
          </div>
          <div :class="{'hidden': !audience_reachability}" class="grid gap-5">
            <div>
              <div class="font-bold flex gap-2 cursor-pointer" @click="addProperty('Reachability', 'payload.audience_followers.data.audience_reachability', ['code', 'weight'])">
                <base-icon name="plus-circle"/>
                <span>Audience Reachability</span>
              </div>
              <div v-if="audience.payload.audience_followers && audience.payload.audience_followers.data && audience.payload.audience_followers.data.audience_reachability">
                <div class="text-xs flex justify-between" :key="reachability_key" v-for="(reachability, reachability_key) in audience.payload.audience_followers.data.audience_reachability">
                  <div class="cursor-pointer" @click="removeProperty(audience.payload.audience_followers.data.audience_reachability, reachability_key)"><base-icon :size="3" name="trash"/></div>
                  <div>{{ reachability.name || reachability.code }}</div>
                  <div>{{ (reachability.weight * 100).toFixed(2) }}%</div>
                  <div class="cursor-pointer" @click="editProperty('Ethnicity', audience.payload.audience_followers.data.audience_reachability, ['code', 'weight'], reachability_key)"><base-icon :size="3" name="pencil"/></div>
                </div>
              </div>
            </div>
          </div>
          <div class="cursor-pointer font-bold mt-2 flex items-center gap-3" @click="audience_brand_affinity = !audience_brand_affinity">
            <base-icon :name="!audience_brand_affinity ? 'chevron-down' : 'chevron-up'"/>
            Audience Brand Affinity
          </div>
          <div :class="{'hidden': !audience_brand_affinity}" class="grid gap-5">
            <div>
              <div class="font-bold flex gap-2 cursor-pointer" @click="addProperty('Brand Affinity', 'payload.audience_followers.data.audience_brand_affinity', ['name', 'weight'])">
                <base-icon name="plus-circle"/>
                <span>Audience Reachability</span>
              </div>
              <div v-if="audience.payload.audience_followers && audience.payload.audience_followers.data && audience.payload.audience_followers.data.audience_brand_affinity">
                <div class="text-xs flex justify-between" :key="brand_affinity_key" v-for="(brand_affinity, brand_affinity_key) in audience.payload.audience_followers.data.audience_brand_affinity">
                  <div class="cursor-pointer" @click="removeProperty(audience.payload.audience_followers.data.audience_brand_affinity, brand_affinity_key)"><base-icon :size="3" name="trash"/></div>
                  <div>{{ brand_affinity.name || brand_affinity.code }}</div>
                  <div>{{ (brand_affinity.weight * 100).toFixed(2) }}%</div>
                  <div class="cursor-pointer" @click="editProperty('Ethnicity', audience.payload.audience_followers.data.audience_brand_affinity, ['name', 'weight'], brand_affinity_key)"><base-icon :size="3" name="pencil"/></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-between gap-3 mt-6 rounded-br-2xl">
          <base-button @action="$emit('close')" :disabled="saving" type="secondary" size="md" label="Cancel"/>
          <base-button @action="updateAudience" :disabled="saving" size="md" :icon-size="4" label="Save" bold/>
        </div>

        <base-modal :title="`${editingProperty ? 'Edit' : 'Add'} ${propertyName}`" :visible="property !== ''" @close="closeProperty">
          <div class="flex flex-col justify-between">
            <div>
              <div class="field">
                <div class="text-h6 font-bold">Name</div>
                <FormulateInput type="text" placeholder="Name" v-model="attributes.name" v-if="propertyAttrs.includes('name')"/>
                <FormulateInput type="text" placeholder="Name" v-model="attributes.code" v-if="propertyAttrs.includes('code')"/>
              </div>
              <div class="field">
                <div class="text-h6 font-bold">Weight</div>
                <FormulateInput type="text" placeholder="Weight" v-model="attributes.weight" v-if="propertyAttrs.includes('weight')"/>
              </div>
            </div>

            <div class="flex justify-between gap-3 mt-6 rounded-br-2xl">
              <base-button @action="closeProperty" type="secondary" size="md" label="Cancel"/>
              <base-button @action="createProperty" size="md" :icon-size="4" :label="editingProperty ? 'Edit' : 'Add'" bold/>
            </div>
          </div>
        </base-modal>
    </base-modal>
</template>

<script>
    // import { validateNumbers } from '@/lib/strings';
    import DefaultProfile from "@/assets/images/default-user.png";

    export default {
      name: 'AdvancedAudience',
      props: {
        open: {
          type: Boolean,
          default: false
        },
        currentAudience: {
          type: Object
        }
      },
      data() {
        return {
          saving: false,
          uploadingFile: false,
          linkAccepted: false,
          audience: {
            user_profile: {}
          },
          link: '',
          searchingLink: false,
          savingPost: false,
          postsOpen: false,
          audience_geo: false,
          audience_ages: false,
          audience_interests: false,
          audience_languages: false,
          audience_ethnicities: false,
          audience_reachability: false,
          audience_brand_affinity: false,
          editingProperty: false,
          propertyName: '',
          property: '',
          propertyAttrs: [],
          attributes: {}
        }
      },
      watch: {
        open: function (val, old) {
          if (val && val !== old) {
            let audience = JSON.parse(JSON.stringify(this.currentAudience));
            if (!audience.payload.user_profile.geo.city) {
              audience.payload.user_profile.geo.city = {name: ''};
            }

            if (!audience.payload.user_profile.geo.state) {
              audience.payload.user_profile.geo.state = {name: ''};
            }

            if (!audience.payload.user_profile.geo.country) {
              audience.payload.user_profile.geo.country = {name: ''};
            }

            if (!audience.payload.user_profile.picture) {
              audience.payload.user_profile.picture = '';
            }

            if (!audience.payload.user_profile.top_posts) {
              audience.payload.user_profile.top_posts = [];
            }

            this.audience = audience;
          }
        }
      },
      computed: {
        disabledManualAudience() {
          return this.saving;
        },
        profileUrl() {
          return this.audience.payload.user_profile && this.audience.payload.user_profile.picture ? this.audience.payload.user_profile.picture : DefaultProfile;
        },
      },
      methods: {
        getMeta() {
          this.searchingLink = true;
          this.linkAccepted = false;
          this.$http.get(`/api/reports/url/details?url=${this.link}`).then(async (response) => {
            if (response.data) {
              this.linkAccepted = true;
            }
          }).catch((err) => {
          }).finally(() => {
            this.searchingLink = false;
          });
        },
        async addPost() {
          if (!this.savingPost) {
            try {
              this.savingPost = true;
              let { data } = await this.$http.post(`/api/reports/0/add-post`, { link: this.link });
              if (data) {
                let post = this.audience.payload.user_profile.top_posts.find(i => i.id === data.id);
                if (!post) {
                  this.audience.payload.user_profile.top_posts.push({
                    link: data.url,
                    stat: {
                      likes: data.likes,
                      comments: data.comments,
                      views: data.views,
                      shares: data.shares,
                      saves: data.saves
                    },
                    text: data.caption,
                    type: 'photo',
                    image: data.display_url,
                    created: data.date,
                    post_id: data.post_identifier,
                    user_id: data.profile_item.social_id,
                    user_url: data.profile_item.uri,
                    username: data.profile_item.username,
                    thumbnail: data.display_url,
                    user_picture: data.profile_item.profile_picture_url
                  });
                }

                this.$notify({ title: 'Success', text: 'Post saved successfully', type: 'success' })

                this.link = '';
              }
            }
            catch(e) {
              this.$notify({ title: 'Error saving post!', text: 'Please try again', type: 'error' })
              console.log(e);
            }
            finally {
              this.savingPost = false;
            }
          }
        },
        uploadAudienceProfilePicture(e) {
          this.uploadingFile = true;
          let fileData = new FormData();
          fileData.append('image', e.target.files[0]);

          this.$http.post(`/api/files/audience/profile`, fileData).then(async ({data}) => {
            this.audience.payload.user_profile.picture = data.url;
          }).catch((err) => {
            console.log(err);
            console.log('upload picture error', err.response);
            this.$notify({ title: 'Something went wrong while uploading picture.', text: 'Try again later', type: 'warn' });
          }).finally(() => {
            this.uploadingFile = false;
          });
        },
        addProperty(name, property, attributes) {
          this.propertyName = name;
          this.property = property;
          this.propertyAttrs = attributes;
          this.attributes = {
            weight: '',
            name: '',
            code: ''
          };
        },
        editProperty(propertyName, property, values, attributes, key) {
          if (property && property.length && property[key] !== undefined) {
            this.editingProperty = true;
            this.propertyName = propertyName;
            this.property = property;
            this.propertyAttrs = attributes;
            let { weight, name, code } = values[key];
            this.attributes = {
              key,
              weight: weight !== '' && weight !== undefined ? weight * 100 : weight,
              code: code !== '' && code !== undefined ? code * 100 : code,
              name
            };
          }
        },
        removeProperty(property, key) {
          if (property && property.length && property[key] !== undefined) {
            property.splice(key, 1);
          }
        },
        closeProperty() {
          this.property = '';
          this.propertyName = '';
          this.attributes = {};
        },
        createProperty() {
          if (this.property !== '') {
            let properties = this.property.split('.');
            let item = this.audience;
            this.attributes.weight = this.attributes.weight / 100;
            for (let i=0; i < properties.length; i++) {
              let isLast = i === properties.length -1;
              if (item[properties[i]] === undefined) {
                if (isLast) {
                  item[properties[i]] = [];
                } else {
                  item[properties[i]] = {};
                }
              }
              let prop = item[properties[i]];
              if (isLast) {
                if (this.attributes.key !== undefined) {
                  let { weight, name, code } = this.attributes;
                  prop[this.attributes.key] = { weight, name, code };
                } else if (Array.isArray(prop)) {
                  prop.push(this.attributes);
                }
              }

              item = prop;
            }
            this.closeProperty();
          }
        },
        processEngagementRate() {
          let engagements = this.audience.payload.user_profile.engagements;
          let followers = this.audience.payload.user_profile.followers;

          if (engagements !== '' && followers !== '') {
            if (followers > 0) {
              this.audience.payload.user_profile.engagement_rate = engagements / followers;
            }
          }
        },
        trimc(string, char = ' ') {
          string = string.charAt(0) === char ? string.substring(1) : string;
          string = string.charAt(string.length - 1) === char ? string.slice(0, -1) : string;
          return string
        },
        updateAudience() {
          this.$emit('save-audience', this.audience);
        },
        convertValue(key, value) {
          if (key !== 'weight') {
            return true;
          }
          console.log(key, value);
        }
      }
    }
</script>
<template>
    <base-modal 
        title="Add featured post" 
        :visible="visible"
        @close="$emit('close')"
        no-padding
        styles="overflow-y: auto; max-height: 80vh; max-width: 80vw; width: 80vw;"
        headerStyles="position: sticky; z-index: 1; top: 0; left: 0; right: 0; background-color: #f8f7f1;"
    >

        <div class="container">
            <div class="content">       
                <div class="title">Search {{this.user.first_name}} {{this.user.last_name}}'s posts by URL</div>
                <div class="searchbar-container">
                    <FormulateInput
                        v-model="term"
                        type="text"
                        :disabled="searching || loading"
                        placeholder="https://www.instagram.com/p/C9h4zVuvLRT/"
                        class="flex-grow flex-shrink"
                        :element-class="['flex items-center gap-x-1']"
                    />
                    <base-button 
                        label="Search" 
                        class="flex-grow-0 flex-shrink-0"
                        :disabled="!termValid || searching || loading"
                        icon="search"
                        @action="getSinglePost()"
                    />
                </div>
                <div v-if="loading">Adding featured post...</div>
                <div v-if="searching">Searching post...</div>
                <base-icon 
                    v-if="searching || loading" 
                    name="loader"
                />
                <template v-else>
                    <div class="empty-state" v-if="!isValidObject(searchResult)">
                        No posts found
                    </div>

                    <add-featured-post-modal-item
                        v-else
                        :value="searchResult"
                        :disabled="searching || loading"
                        @add-post="submit(searchResult)"
                    />
                </template>
            </div>
            
            <div class="footer">
                <base-button 
                    label="Close"  
                    @action="$emit('close')"
                    type="secondary"
                    :disabled="searching || loading"
                    bold
                    size="md" 
                />
            </div>
           
        </div>
    </base-modal>
</template>

<script>
    import { getNetworkFromSocialPostUrl, getParamsFromSocialPostUrl, removeUnnecessaryQueryParams } from '../../lib/network';
    import { isValidArray, isValidObject, notifyCatchError } from '../../common';
    import { validateUrl, validString } from '../../lib/strings';
    import AddFeaturedPostModalItem from './AddFeaturedPostModalItem.vue';
    export default {
        name: 'AddFeaturedPostModal',
        props: ['visible', 'user', 'handle'],
        components: {
            AddFeaturedPostModalItem
        },
        data() {
            return {
                term: '',
                searching: false,
                searchResult: null,

                loading: false,

                isValidObject
            }
        },
        methods: {
            getSinglePost(){
                this.searching = true;
                let term = this.term;
                term = removeUnnecessaryQueryParams(term);
                this.$http.post('/social-api-service/get/posts/preview', {
                    handle: this.handle,
                    urls: [term]
                }).then(({ data }) => {
                    if(isValidArray(data) && isValidObject(data[0])){
                        const post = data[0];
                        const network = getNetworkFromSocialPostUrl(post.url);
                        if(isValidObject(network)){ 
                            // any change in this object should also be changed on getTopPosts method and on Step4CreatorList.vue file
                            this.searchResult = { 
                                url: removeUnnecessaryQueryParams(post.url), 
                                network_id: network.id, 
                                network_name: network.name, 
                                network_display_name: network.display_name,
                                found: true,
                                preview: post.preview,
                            };
                        }
                    }
                }).catch(err => {
                    notifyCatchError(err, this.$notify);
                }).finally(() => {
                    this.searching = false;
                });
            },
            submit(post){
                if(post && post.url){
                    this.loading = true;
                    this.$http.post('/report-user-post', {
                        url: post.url,
                        handle: this.handle,
                        report_user_id: this.reportUserId
                    }).then(({ data }) => {
                        if(data){
                            this.$notify({ title: 'Success', text: 'Featured post added successfully', type: 'success' });
                            this.$emit('close');
                            this.$emit('done');
                        }
                    }).catch(err => {
                        notifyCatchError(err, this.$notify);
                    }).finally(() => {
                        this.loading = false;
                    }); 
                }
            }
        },
        computed: {
            termValid(){
                return validString(this.term) && validateUrl(this.term) && isValidObject(getParamsFromSocialPostUrl(this.term))
            },
            reportUserId(){
                return (this.user && this.user.report_user && this.user.report_user.id) ? this.user.report_user.id : null 
            }
        }
    }
</script>
<style lang="scss" scoped>
    .container{
        @apply flex flex-col justify-between;
        min-height: 260px;

        .content{
            @apply px-7 py-7 flex flex-col gap-y-3;
            .title{
                @apply text-h5 font-bold;
            }
            .searchbar-container{
                @apply flex gap-x-4;
            }
            .empty-state{
                @apply text-h5 font-bold;
            }
        }

        > .footer{
            @apply sticky bottom-0 left-0 right-0 bg-tan-m py-6 px-14 flex justify-between;
            z-index: 80;
        }
    }
</style>
